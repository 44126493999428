/* eslint-disable guard-for-in */
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import some from 'lodash/some';
import memoizeOne from 'memoize-one';
import React, { useMemo } from 'react';
import { setIn } from '.';
import { useAppContext } from '../Context';
import { DEFAULT_LANGUAGES } from '../editor/utils/defaultLanguages';
import i18n from '../i18n/i18n';
import { memoizeWithKey } from './memoize';
import { baseSectionSchema, sectionSchemas, siteSchema } from './translationSchemas';

export function injectTranslationLanguageToPath(key, language) {
  if (!language) return key;
  return key.replace(/([^.]+)$/, (v) => `translations.${language}.${v}`);
}

function hasTranslations(values, defaultLanguage) {
  return some(Object.entries(values), ([lng, value]) => !!value && lng !== defaultLanguage);
}

export function extractTranslationKeys(object, schema, defaultLanguage, keyPrefix = '') {
  if (!object || !schema) return [];

  if (schema.type === 'section') {
    // Find correct schema
    return extractTranslationKeys(
      object,
      sectionSchemas[object.type] || baseSectionSchema,
      defaultLanguage,
      keyPrefix,
    );
  }
  if (schema.type !== 'object') {
    return [];
  }

  const { properties } = schema;
  const lines = [];
  const { translations } = object;
  for (const key in properties) {
    const value = object[key];
    const valueSchema = properties[key];
    if (valueSchema && value) {
      const translationKey = `${keyPrefix}${key}`;
      // Only translate entries that have value ?
      if (valueSchema.translatable) {
        if (valueSchema.type === 'string') {
          let values = { [defaultLanguage]: value };
          if (translations) {
            values = { ...values, ...mapValues(translations, (language) => language[key] || '') };
          }
          const autoHide = valueSchema.deprecated && !hasTranslations(values, defaultLanguage);
          if (!autoHide) {
            lines.push({
              key: translationKey,
              translations: values,
              deprecated: valueSchema.deprecated,
            });
          }
        } else {
          console.warn('Ignoring translatable type', valueSchema.type);
        }
      } else if (valueSchema.type === 'object') {
        // Time to nest...
        const nestedLines = extractTranslationKeys(
          value,
          valueSchema,
          defaultLanguage,
          `${translationKey}.`,
        );
        nestedLines.forEach((line) => lines.push(line));
      } else if (valueSchema.type === 'array') {
        if (Array.isArray(value)) {
          // Only translate if type matches
          value.forEach((item, index) => {
            const idOrIndex = item._id || index;
            const nestedLines = extractTranslationKeys(
              item,
              valueSchema.items,
              defaultLanguage,
              `${translationKey}.${idOrIndex}.`,
            );
            nestedLines.forEach((line) => lines.push(line));
          });
          // Time to nest...
        }
      }
    }
  }
  return lines;
}

export function createSiteTranslationData(site) {
  const defaultLangName = (site?.languages[0] || DEFAULT_LANGUAGES[0])?.name;
  return extractTranslationKeys(site, siteSchema, defaultLangName);
}

function isIndex(value) {
  // eslint-disable-next-line eqeqeq
  return +value == value;
}

function applyTranslationToObject(object, path, translations, defaultLanguage) {
  if (!object || typeof object !== 'object') return object; // Stop now
  const [key, ...rest] = path;
  if (rest.length === 0) {
    // Terminal node, time to patch !
    let localTranslations = object.translations || {};
    for (const t in translations) {
      if (t !== defaultLanguage) {
        localTranslations = setIn(localTranslations, `${t}.${key}`, translations[t]);
      }
    }
    return {
      ...object,
      // [key]: translations[defaultLanguage] // Inject this language ?
      translations: localTranslations,
    };
  }

  // Non terminal node, continue
  if (Array.isArray(object)) {
    // Patch by index of id ?
    if (isIndex(key)) {
      // By index
      const array = [...object];
      const modifiedObject = applyTranslationToObject(
        array[key],
        rest,
        translations,
        defaultLanguage,
      );
      array[key] = modifiedObject;
      return array;
    }
    // By _id
    return object.map((o) =>
      o._id === key ? applyTranslationToObject(o, rest, translations, defaultLanguage) : o,
    );
    // TODO handle array with no key => index
  }

  // Object, time for recursion...
  return {
    ...object,
    [key]: applyTranslationToObject(object[key], rest, translations, defaultLanguage),
  };
}

export function applySiteTranslations(site, translations) {
  if (!translations || translations.length === 0) return site;
  const { languages } = site;
  if (!languages || languages.length <= 1) return site; // Nothing to translate
  const defaultLanguage = languages[0].name;
  return translations.reduce(
    (acc, currentTranslation) =>
      applyTranslationToObject(
        acc,
        currentTranslation.key.split('.'),
        currentTranslation.translations,
        defaultLanguage,
      ),
    site,
  );
}

// TODO: add tests...
function isEmptyValue(v) {
  return v === undefined || v === null || v === '';
}

function translateObject(object, language, keysToOmit) {
  if (!object || typeof object !== 'object') return object;

  if (Array.isArray(object)) {
    return object.map((item) => translateObject(item, language));
  }
  const { translations = {}, ...rest } = object;

  const translatedRest = mapValues(rest, (v, k) => {
    if (keysToOmit && keysToOmit.indexOf(k) !== -1) return v;
    if (typeof v === 'object') {
      return translateObject(v, language);
    }
    return v;
  });

  return {
    ...translatedRest,
    ...omitBy(translations[language] || {}, isEmptyValue),
  };
}

const memoizedTranslateObject = memoizeWithKey(translateObject);

// Don't translate page links in editor or it'll break the router :/
const pageKeysToOmit = ['link'];

// eslint-disable-next-line import/prefer-default-export
export const translateSite = memoizeOne((site, language) => {
  if (!language) return site;

  const { footer, pages, menu, ...rest } = site;

  return {
    ...translateObject(rest, language),
    footer: memoizedTranslateObject('footer', footer, language),
    menu: memoizedTranslateObject('menu', menu, language),
    pages: pages.map((page) =>
      memoizedTranslateObject(`page-${page._id}`, page, language, pageKeysToOmit),
    ),
  };
});

export function useSiteTranslation() {
  const { lang } = useAppContext();
  return useMemo(() => {
    return { t: i18n.getFixedT(lang, null) };
  }, [lang]);
}

export function withSiteTranslation(Component) {
  return (props) => {
    const { t } = useSiteTranslation();
    return <Component {...props} t={t} />;
  };
}
