import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Table = ({ className, bordered, striped, fullWidth, narrow, children, ...props }) => (
  <table
    className={cx('table', className, {
      'is-bordered': bordered,
      'is-striped': striped,
      'is-fullwidth': fullWidth,
      'is-narrow': narrow,
    })}
    {...props}
  >
    {children}
  </table>
);

Table.defaultProps = {
  bordered: false,
  className: undefined,
  fullWidth: false,
  narrow: false,
  striped: false,
};

Table.propTypes = {
  bordered: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  narrow: PropTypes.bool,
  striped: PropTypes.bool,
};

export default Table;
