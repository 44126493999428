import React from 'react';
import PropTypes from 'prop-types';
import { toggleFilter } from './utils';
import { DateFilter, SimpleFilter, SearchFilter } from './filters';
import Modal from '../../editor/Modal';
import Button from '../Button';

const MenuComponents = {
  // 'router-param': RouterParamFilter,
  date: DateFilter,
  simple: SimpleFilter,
  search: SearchFilter,
  // 'select-entity': SelectEntity,
};

class MenuFilter extends React.PureComponent {
  state = {
    isOpen: false,
  };

  handleSelect = (filter, value) => {
    const { filters, onChange } = this.props;
    onChange(toggleFilter(filters, filter.dataKey, value));
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  renderFilterMenu() {
    const { filterList, filters } = this.props;
    return (
      <aside className="menu">
        {filterList.map((filter) => {
          const Component = MenuComponents[filter.type] || SimpleFilter;
          return (
            <Component
              key={filter.dataKey}
              filter={filter}
              value={filters[filter.dataKey]}
              onSelect={this.handleSelect}
            />
          );
        })}
      </aside>
    );
  }

  render() {
    const { isOpen } = this.state;
    return (
      <>
        <div className="filters--desktop">{this.renderFilterMenu()}</div>
        <div className="filters--mobile">
          <Button
            icon="filter"
            content="Filters"
            className="filters--mobile__button"
            onClick={this.handleOpen}
          />

          {isOpen && (
            <Modal
              title="Filters"
              isActive={isOpen}
              onClose={this.handleClose}
              maxWidth="500"
              footer={
                <footer className="modal-card-foot">
                  <Button type="success" onClick={this.handleClose}>
                    Valider
                  </Button>
                  <Button onClick={this.handleClose}>Annuler</Button>
                </footer>
              }
            >
              {this.renderFilterMenu()}
            </Modal>
          )}
        </div>
      </>
    );
  }
}

MenuFilter.propTypes = {
  filterList: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MenuFilter;
