/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { metaType } from '../utils/propTypes';

let isInitialMount = true;

class TcPageTracker extends React.PureComponent {
  componentDidMount() {
    const { meta } = this.props;
    if (isInitialMount) {
      // Don't to anything the first time...
      isInitialMount = false;
      return;
    }
    if (window.tC && meta.length > 0) {
      const tCMetas = meta.filter((m) => m.type === 'tc_vars');
      if (tCMetas.length > 0) {
        // Set global tc_vars
        tCMetas.forEach((m) => {
          window.tc_vars[m.name] = m.content;
        });
        window.tC.container.reload();
      }
    }
  }

  render() {
    return null;
  }
}

TcPageTracker.defaultProps = {
  meta: [],
};

TcPageTracker.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.shape(metaType)),
};

export default TcPageTracker;
