import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const InputText = ({
  label,
  name,
  className,
  disabled,
  placeholder,
  value,
  size,
  onChange,
  hasError,
  type,
  children,
}) => (
  <div className={cx('control', className)}>
    {label && (
      <label className="label" htmlFor={name}>
        {label}
      </label>
    )}
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <input
        className={cx('input', size && `is-${size}`, hasError && 'is-danger')}
        value={value}
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
      />
      {children}
    </div>
  </div>
);

InputText.defaultProps = {
  className: undefined,
  disabled: false,
  size: 'normal',
  placeholder: '',
  label: '',
  name: 'input_default',
  type: 'text',
  hasError: false,
  children: undefined,
};

InputText.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
  value: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  children: PropTypes.node,
  type: PropTypes.string,
};

export default InputText;
