import React from 'react';

const prefix: Record<string, string> = {
  solid: 'fas',
  brands: 'fab',
  regular: 'far',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FAIcon: React.FC<Record<string, any>> = (props) => {
  // Don't inject `isEditing` in DOM
  const { icon, className = '', isEditing, ...rest } = props;

  if (!icon && !isEditing) return null;

  if (!icon) return <i className={`${className} fa fa-fw fa-question`} {...rest} />;
  if (typeof icon === 'string') {
    return <i className={`${className} fa fa-fw fa-${icon}`} {...rest} />;
  }
  return <i className={`${className} ${prefix[icon.type]} fa-fw fa-${icon.name}`} {...rest} />;
};
export default FAIcon;
