/* eslint-disable no-inner-declarations */
/* eslint-disable no-restricted-globals */
import React from 'react';
import ReactDOM from 'react-dom';
import every from 'lodash/every';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AppContext } from './Context';
import './i18n/i18n';

if (window.__DATA__) {
  const { extraModules, hydrate } = window.__DATA__;

  function hasLoadedAllModules(modules) {
    if (!modules || modules.length === 0) return true;

    const { loadedModules = {} } = window.__DATA__;
    return every(modules, (module) => loadedModules[module]);
  }

  function startIfReady() {
    if (hasLoadedAllModules(extraModules)) {
      const render = hydrate ? ReactDOM.hydrate : ReactDOM.render;
      render(
        <BrowserRouter basename={window.__DATA__.basename}>
          <AppContext.Provider value={window.__DATA__}>
            <App {...window.__DATA__} />
          </AppContext.Provider>
        </BrowserRouter>,
        // eslint-disable-next-line no-restricted-globals
        document.getElementById('root'),
      );
    } else {
      // Try again in 100ms :/
      setTimeout(startIfReady, 100);
    }
  }
  startIfReady();
}
