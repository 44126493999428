import React from 'react';
import PropTypes from 'prop-types';

import Curve from './Curve';
import Diagonal from './Diagonal';
import Triangle from './Triangle';
import Zigzag from './Zigzag';
import Wave from './Wave';
import Book from './Book';
import Colorized from './Colorized';

const dividers = {
  curve: Curve,
  zigzag: Zigzag,
  diagonal: Diagonal,
  triangle: Triangle,
  wave: Wave,
  book: Book,
  colorized: Colorized,
};

export default class Divider extends React.PureComponent {
  render() {
    if (process.env.REACT_APP_BUILD_TARGET !== 'server') {
      // eslint-disable-next-line no-restricted-globals
      const { userAgent } = window.navigator;
      const isEdge = userAgent.indexOf('Edge') !== -1;
      const isIE = userAgent.indexOf('Trident') !== -1 && !isEdge;

      // SSR TODO: how to rehydrate depending on browser ? render only on second path ?
      if (isIE) return null; // Don't render...
    }
    const { type } = this.props;
    if (!type) return null;

    const Component = dividers[type];
    if (!Component) {
      console.warn('Invalid divider type', type);
      return null;
    }

    return <Component {...this.props} />;
  }
}

Divider.defaultProps = {
  type: undefined,
};

Divider.propTypes = {
  type: PropTypes.oneOf(Object.keys(dividers)),
};

export { Curve, Diagonal, Triangle, Zigzag, Wave };
