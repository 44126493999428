import {
  Title,
  Menu,
  Speakers,
  Map,
  Image,
  Services,
  Partners,
  Header,
  RichText,
  Timeline,
  Section,
  Columns,
  Column,
  Text,
  ContactForm,
  Social,
  Video,
} from './components';

import sections from './sections';

export { sections };

// eslint-disable-next-line import/prefer-default-export
export const miniSiteBlocks = {
  // Sections
  // ...sections,

  // Components
  Title,
  Menu,
  Speakers,
  Services,
  Header,
  Map,
  Timeline,
  Section,
  Columns,
  Column,
  Image,
  Text,
  RichText,
  ContactForm,
  Social,
  Video,
  Partners,
};
