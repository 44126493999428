import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Menu from '../components/Menu';
import { AppContext } from '../../../Context';

function ensureObject(v) {
  if (typeof v === 'string') return { value: v, label: v };
  return v;
}

const SimpleFilter = ({ filter, value, onSelect }) => {
  const { title, values, showAll } = filter;
  const { lang = 'fr' } = useContext(AppContext);
  if (!values) return null;
  return (
    <Menu.Item className="menu-filter--simple">
      <Menu.Header>{title}</Menu.Header>

      <Menu.Menu>
        {showAll && (
          <Menu.Menu.Item name="" active={!value} onClick={() => onSelect(filter, undefined)}>
            {lang === 'fr' ? 'Tous' : 'All'}
          </Menu.Menu.Item>
        )}
        {values.map(ensureObject).map((v) => (
          <Menu.Menu.Item
            key={v.value}
            name={v.label}
            active={value === v.value}
            onClick={() => onSelect(filter, v.value)}
          />
        ))}
      </Menu.Menu>
    </Menu.Item>
  );
};

SimpleFilter.defaultProps = {
  value: undefined,
};

SimpleFilter.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    values: PropTypes.array,
    showAll: PropTypes.bool,
  }).isRequired,
};

export default SimpleFilter;
