import React from 'react';
import Figure from './Figure';
import { makeContainer } from './utils';

const Card = makeContainer('card');
Card.Content = makeContainer('card-content');

const Image = (props) => (
  <div className="card-image">
    <Figure {...props} />
  </div>
);

Image.propTypes = Figure.propTypes;

Card.Image = Image;

export default Card;
