import React, { FC } from 'react';
import { useSetState } from 'react-use';
import FormInput from '../../../bulma/FormInput';
import i18n from '../../../i18n/i18n';
import authService from '../../../services/auth.service';
import { LoginRes } from '../../../types/connection.types';
import { useSiteTranslation } from '../../../utils/translations';

type PasswordConnectionProps = {
  onLogin: (user: LoginRes) => void;
};

function getErrorMessage(code: string | null): string | null {
  if (!code) return null;
  return i18n.t(`generic.errors.${code}`, { defaultValue: i18n.t(`generic.errors.E_AUTH_ERROR`) });
}

const PasswordConnection: FC<PasswordConnectionProps> = ({ onLogin }) => {
  const [{ email, password, error, errorMsg }, setState] = useSetState<{
    email: string;
    password: string;
    error?: string | null;
    errorMsg?: string | null;
  }>({
    email: '',
    password: '',
    error: null,
    errorMsg: null,
  });
  const { t } = useSiteTranslation();

  const setError = (err: string | null) => setState({ error: err, errorMsg: getErrorMessage(err) });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any): Promise<void> => {
    e.stopPropagation();
    e.preventDefault();
    setError(null);
    try {
      const loginRes = await authService.login(email, password);
      console.log('loginRes', loginRes);
      if (loginRes.success && loginRes.user && loginRes.token) {
        onLogin(loginRes);
      } else {
        console.warn('Login error', loginRes);
        setError('E_INVALID_CREDENTIALS');
      }
    } catch (err: any) {
      console.error('Login error', err);
      setError('E_INVALID_CREDENTIALS');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ [name]: value });
  };

  return (
    <>
      <form action="" onSubmit={handleSubmit}>
        <FormInput
          type="string"
          value={email}
          name="email"
          icon="fa-envelope"
          onChange={handleChange}
          label={t('fields.email') as string}
        />
        <FormInput
          type="password"
          placeholder="****"
          value={password}
          name="password"
          icon="fa-lock"
          onChange={handleChange}
          label={t('fields.password') as string}
        />
        <div className="field">
          <button disabled={!email || !password} className="button is-primary is-fullwidth">
            {t('fields.login')}
          </button>
        </div>
        {error && (
          <article className="message is-danger">
            <div className="message-body">{errorMsg || error}</div>
          </article>
        )}
      </form>
    </>
  );
};

export default PasswordConnection;
