import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import './workshop-details-section.scss';

import {
  WorkshopCategory,
  WorkshopDate,
  WorkshopDescription,
  WorkshopLang,
  WorkshopTags,
  WorkshopTitle,
} from '../workshops/templates/components';
import { Video } from '../components';
import { withAppContext } from '../Context';

class WorkshopDetailsSection extends PureComponent {
  getWorkshopInfo = (workshopId) => {
    const { data } = this.props;
    const { workshops = [] } = data.data || {};
    return workshops.find((workshop) => workshop._id === workshopId);
  };

  render() {
    const { descriptionTitle, replayTitle, workshopId, ...props } = this.props;

    const workshop = this.getWorkshopInfo(workshopId);
    const {
      category,
      description,
      endDate,
      image,
      lang,
      productCategories,
      replayUrl,
      startDate,
      title,
    } = workshop;
    const dateFormat = 'HH:mm';

    return (
      <BaseSection {...props} hasHeader={false} block={this.props}>
        <div className="workshop">
          {image && image.uri && (
            <div className="workshop--image">
              <img src={image.uri} alt="workshop " />
            </div>
          )}
          <div className="workshop--content">
            <div className="header">
              <WorkshopDate startDate={startDate} endDate={endDate} format={dateFormat} />
              <div style={{ display: 'flex', justifyContent: 'space-arround' }}>
                {lang && <WorkshopLang lang={lang} />}
                <WorkshopCategory category={category} />
              </div>
            </div>
            <WorkshopTitle title={title} />
            <WorkshopTags tagList={productCategories} />
          </div>
        </div>
        <div className="workshop workshop--replay">
          <h3>{descriptionTitle}</h3>
          <WorkshopDescription description={description} />
        </div>
        {replayUrl && (
          <div className="workshop workshop--replay">
            <h3>{replayTitle}</h3>
            <Video url={replayUrl} />
          </div>
        )}
      </BaseSection>
    );
  }
}

WorkshopDetailsSection.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      workshops: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          endDate: PropTypes.string,
          image: PropTypes.string,
          lang: PropTypes.string,
          productCategories: PropTypes.string,
          replayUrl: PropTypes.string,
          startDate: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  }).isRequired,
  descriptionTitle: PropTypes.string.isRequired,
  replayTitle: PropTypes.string.isRequired,
  workshopId: PropTypes.string.isRequired,
};

export default withAppContext(WorkshopDetailsSection);
