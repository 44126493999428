/* eslint-disable no-restricted-globals */

import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import startsWith from 'lodash/startsWith';
import trim from 'lodash/trim';
import { stringify } from 'query-string';
import { extractUTM, urlJoinParams } from './url';

function isAppCraftUrl(url: string, urlType: string) {
  if (urlType === 'appcraft') return true;
  return startsWith(url, 'https://static.appcraft.events/live');
}

export function isRegistrationV1Url(url: string, urlType: string): boolean {
  if (urlType === 'registration-v1') return true;
  return startsWith(url, 'https://app.appcraft.events/register');
}

function isRegistrationUrl(url: string, urlType: string) {
  if (urlType === 'registration') return true;
  return startsWith(url, 'https://api.appcraft.events/registrations');
}

function urlJoinUnescaped(url: string, params: Record<string, string>) {
  return urlJoinParams(url, params).replace(/%7B/g, '{').replace(/%7D/g, '}');
}

export const joinUrlParts = (...parts: string[]): string =>
  parts
    .map((part) => trim(part, '/'))
    .filter((part) => !!part)
    .join('/');

const appendUrl = (url: string, suffix: string) => {
  const parts = url.split('?');
  const finalUrl = joinUrlParts(parts[0], suffix);
  if (parts[1]) return `${finalUrl}?${parts[1]}`;
  return finalUrl;
};

export function injectQueryString(url: string, qs?: string | Record<string, any>): string {
  if (!qs) return url;

  if (typeof qs === 'object') {
    // eslint-disable-next-line no-param-reassign
    qs = stringify(omitBy(qs, (v) => isNil(v) || v === ''));
  }
  if (!qs) return url; // Nothing to inject

  const char = url.includes('?') ? '&' : '?';
  return `${url}${char}${qs}`;
}

export function getUrlWithPrivateKey(
  url: string,
  user: Record<string, any>,
  autoInjectPrivateKey?: boolean,
): string {
  if (!url) return url;

  if (autoInjectPrivateKey && user?.privateKey) {
    if (url.includes('/k/{user.privateKey}')) {
      return url;
    }
    return appendUrl(url, '/k/{user.privateKey}');
  }

  if (url.includes('/k/{user.privateKey}')) {
    if (user?.privateKey) {
      return url;
    }
    return url.replace('/k/{user.privateKey}', '');
  }

  return url;
}

export function generateIframeUrl(
  url: string,
  urlType: string,
  autoInjectPrivateKey?: boolean,
): string {
  if (window === undefined) return url;
  const params = extractUTM(window.location.search);

  if (isRegistrationV1Url(url, urlType) && typeof window !== 'undefined') {
    if (!url.includes('u=') && window.__DATA__.user?._id) {
      params.u = '{user._id}';
    }
    return urlJoinUnescaped(url, params);
  }

  if (isRegistrationUrl(url, urlType) && typeof window !== 'undefined') {
    const urlWithPrivateKey = getUrlWithPrivateKey(url, window.__DATA__.user, autoInjectPrivateKey);
    return urlJoinUnescaped(urlWithPrivateKey, params);
  }
  if (isAppCraftUrl(url, urlType)) {
    // Auto-inject params if needed...
    if (!url.includes('userId=')) {
      return urlJoinUnescaped(url, {
        userId: '{user._id}',
        firstName: '{user.firstName}',
        lastName: '{user.lastName}',
        thumbnail: '{user.thumbnail.uri}',
      });
    }
  }
  return url;
}
