import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const BIcon = ({ name, size, className, ...props }) => (
  <span className={cx('icon', size && `is-${size}`, className)} {...props}>
    <i className={`fa fa-${name}`} />
  </span>
);

BIcon.defaultProps = {
  className: undefined,
  size: undefined,
};

BIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small']),
};

const Button = ({ as, children, type, size, icon, content, className, ...rest }) => {
  const Component = as;
  return (
    <Component
      className={cx('button', type && `is-${type}`, size && `is-${size}`, className)}
      {...rest}
    >
      {icon && <BIcon name={icon} size="small" />}
      {content && <span>{content}</span>}
      {children}
    </Component>
  );
};

Button.defaultProps = {
  as: 'div',
  children: undefined,
  className: '',
  content: undefined,
  icon: undefined,
  size: undefined,
  type: undefined,
};

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  children: PropTypes.node,
  content: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(['small']),
};

export default Button;
