/* eslint-disable no-restricted-globals */
import WebStorage from '../../data/WebStorage';
import i18n from '../../i18n/i18n';

export function errorMsg(code: string | null): string | null {
  // eslint-disable-next-line no-console
  console.log('errorMsg', code);
  if (!code) return null;

  return i18n.t(`site.login.errors.${code}`, {
    defaultValue: i18n.t(`site.login.errors.E_AUTH_ERROR`),
  });
}

export function isValidEmail(email: string): boolean {
  if (!email) return false;
  return /^[^@]+@[^@]+\.[^@]+$/.test(email);
}

export function disconnectUser(): void {
  // eslint-disable-next-line no-console
  console.log('disconnnect');
  const webStorage = new WebStorage(window.__DATA__.eventId);
  webStorage.removeItem();
  window.location.reload();
}
