import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import { Column, Columns, Map } from '../components';
import { withComponents } from '../components/Context';
import { iconProps } from '../utils/propTypes';
import BaseSection from './BaseSection';
import './AccessSection.scss';

// eslint-disable-next-line react/prefer-stateless-function
class RawItem extends Component {
  render() {
    const { title, icon, description, components } = this.props;
    const { RichText, FAIcon } = components;
    return (
      <div style={{ marginBottom: 16 }}>
        <div style={{ fontWeight: 'bold', marginBottom: 4, display: 'flex', alignItems: 'center' }}>
          <FAIcon icon={icon} name="icon" style={{ marginRight: 10 }} />
          <RichText html={title} name="title" placeholder="Add title" />
        </div>
        <RichText html={description} name="description" placeholder="Add description" />
      </div>
    );
  }
}
RawItem.defaultProps = {
  description: '',
  icon: undefined,
  title: '',
};

RawItem.propTypes = {
  components: PropTypes.object.isRequired,
  description: PropTypes.string,
  icon: iconProps,
  title: PropTypes.string,
};

const Item = withComponents(RawItem);

class AccessSection extends PureComponent {
  renderTopTemplate(mapBlock) {
    const { template, itemList, components, ...props } = this.props;
    const { RichText, Grid } = components;
    if (template === 'top') {
      return (
        <BaseSection {...props} block={this.props}>
          {mapBlock}
          <RichText
            html={itemList.title}
            name="itemList.title"
            placeholder="Add title"
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              marginTop: 10,
              marginBottom: 10,
              textAlign: 'center',
            }}
          />
          <Grid items={itemList.items} name="itemList.items" rgidSize={2} Component={Item} />
        </BaseSection>
      );
    }
    return undefined;
  }

  render() {
    const {
      template,
      mapCaption,
      mapAddress,
      mapType,
      itemList,
      alignement,
      components,
      ...props
    } = this.props;
    const { RichText, List } = components;
    const mapBlock = (
      <div>
        <RichText html={mapCaption} name="mapCaption" />
        <Map address={mapAddress} height={template === 'top' ? 200 : 350} mapType={mapType} />
      </div>
    );

    if (template === 'top') {
      return this.renderTopTemplate(mapBlock);
    }

    return (
      <BaseSection {...props} block={this.props}>
        <Columns>
          {template === 'left' && (
            <Column className="is-half access-section-flex" style={{ justifyContent: alignement }}>
              {mapBlock}
            </Column>
          )}
          <Column
            className="force-flex-grow access-section-flex"
            style={{ justifyContent: alignement }}
          >
            <RichText
              html={itemList.title}
              name="itemList.title"
              placeholder="Add title"
              style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}
            />
            <List
              items={itemList.items}
              isEditing={props.isEditing}
              name="itemList.items"
              Component={Item}
            />
          </Column>
          {template === 'right' && (
            <Column className="is-half access-section-flex" style={{ justifyContent: alignement }}>
              {mapBlock}
            </Column>
          )}
        </Columns>
      </BaseSection>
    );
  }
}

AccessSection.defaultProps = {
  itemList: { items: [] },
  template: 'left',
  alignement: 'start',
};

AccessSection.propTypes = {
  components: PropTypes.object.isRequired,
  itemList: PropTypes.object,
  mapAddress: PropTypes.string.isRequired,
  mapCaption: PropTypes.string.isRequired,
  template: PropTypes.oneOf(['left', 'top', 'right']),
  alignement: PropTypes.oneOf(['start', 'end', 'center']),
};

export default withComponents(AccessSection);
