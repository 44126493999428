import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { Services } from '../components';
import { iconProps } from '../utils/propTypes';

export default class InformationSection extends PureComponent {
  render() {
    const { itemList, iconShape, gridSize, ...props } = this.props;
    return (
      <BaseSection {...props} block={this.props}>
        <Services services={itemList.items} gridSize={gridSize} iconShape={iconShape} />
      </BaseSection>
    );
  }
}

InformationSection.defaultProps = {
  gridSize: 4,
  iconShape: 'diamond',
  itemList: {},
};

InformationSection.propTypes = {
  gridSize: PropTypes.number,
  iconShape: PropTypes.oneOf(['normal', 'square', 'diamond', 'circle', 'bubble', 'hexa']),
  itemList: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        icon: iconProps,
        title: PropTypes.string,
      }),
    ),
  }),
};
