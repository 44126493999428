import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { replaceDataValues } from '../utils';
import './hexagonal-item.scss';
import { imageProps as bulmaImageProps } from '../bulma/propTypes';
import Images from '../utils/Images';
import { avatarPlaceholder } from '../assets/avatarPlaceholder';

// eslint-disable-next-line import/prefer-default-export
export class HexagonalItem extends PureComponent {
  render() {
    const {
      item,
      imageProps,
      imageField,
      titleTemplate,
      subtitleTemplate,
      textAlign,
      linkField,
    } = this.props;
    const title = replaceDataValues(titleTemplate, item).trim();
    const subtitle = replaceDataValues(subtitleTemplate, item).trim();
    const image = imageField && item[imageField];
    const link = linkField && item[linkField];
    const { colorOverlay } = imageProps;
    const Container = link ? 'a' : 'div';
    return (
      <div className="hexIn">
        <Container className="hexLink" href={link} style={{ textAlign }}>
          <div
            className={cx('image', { 'image--color-overlay': colorOverlay })}
            style={{ backgroundImage: `url(${Images.maxWidth(image || avatarPlaceholder, 500)})` }}
          />
          {title && <p className="title is-5">{title}</p>}
          <p className="subtitle is-6">{subtitle}</p>
        </Container>
      </div>
    );
  }
}

HexagonalItem.defaultProps = {
  imageField: undefined,
  imageProps: undefined,
  textAlign: 'center',
  titleTemplate: '',
  subtitleTemplate: '',
  linkField: '',
};

HexagonalItem.propTypes = {
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  item: PropTypes.object.isRequired,
  imageField: PropTypes.string,
  imageProps: bulmaImageProps,
  titleTemplate: PropTypes.string,
  subtitleTemplate: PropTypes.string,
  linkField: PropTypes.string,
};
