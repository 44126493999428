import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BaseSection from './BaseSection';
import { Grid, RichText } from '../components';
import { replaceValues } from '../utils';

async function fetchJson(url, options = {}) {
  const res = await fetch(url, {
    ...options,
    mode: 'cors',
    headers: {
      ...(options.headers || {}),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res.json();
}

class Item extends Component {
  handleChange = (e) => {
    const { name, value } = e.target;
    const { namePrefix, onChange } = this.props;
    if (namePrefix) {
      onChange(`${namePrefix}.${name}`, value);
    } else {
      onChange(name, value);
    }
  };

  render() {
    const { template = 'text', title, description } = this.props;
    return (
      <div className={cx(`item--${template}`, template === 'card' ? 'box' : '')}>
        <div style={{ fontWeight: 'bold', marginBottom: 4 }}>
          <RichText html={title} name="title" placeholder="Add title" />
        </div>
        <RichText html={description} name="description" placeholder="Add description" />
      </div>
    );
  }
}

class RemoteTextSection extends PureComponent {
  state = {
    items: [],
  };

  componentDidMount() {
    const { url } = this.props;
    this._isMounted = true;
    this.refresh(url);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { url } = this.props;
    if (nextProps.url !== url) {
      this.refresh(nextProps.url);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  refresh = async (url) => {
    if (!url) return;
    try {
      const items = await fetchJson(replaceValues(url));
      if (!this._isMounted) return;
      this.setState({ items: items || [] });
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  renderItem = (item) => <Item {...item} template={this.props.template} />;

  render() {
    const { itemList, gridSize = 1, template, ...props } = this.props;
    const { items } = this.state;

    // Send templat to grid to force item refresh.
    return (
      <BaseSection {...props} block={this.props}>
        <Grid items={items} gridSize={gridSize} render={this.renderItem} template={template} />
      </BaseSection>
    );
  }
}

RemoteTextSection.defaultProps = {
  imagePosition: 'left',
  url: '',
};

RemoteTextSection.propTypes = {
  imagePosition: PropTypes.string,
  url: PropTypes.string,
};

export default RemoteTextSection;
