import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { Partners } from '../components';

export default class PartnersSection extends PureComponent {
  state = {
    filter: '',
  };

  handleSearch = (e) => this.setState({ filter: e.target.value });

  renderHeader(items) {
    const { showSearch, showCount, countLabel } = this.props;
    const { filter } = this.state;
    if (!showSearch && !showCount) return null;

    return (
      <div className="c-searchbar">
        {showSearch && (
          <div className="c-searchbar__input" style={{ flexGrow: 0 }}>
            <div className="control has-icons-left">
              <input
                className="input"
                type="text"
                name="filter"
                placeholder="Rechercher"
                value={filter}
                onChange={this.handleSearch}
              />
              <span className="icon is-left">
                <i className="fas fa-search" />
              </span>
            </div>
          </div>
        )}
        <div style={{ flex: 1 }} />
        {showCount && (
          <div className="c-searchbar__label" style={{ flexGrow: 0 }}>
            {items.length} {countLabel}
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      itemList,
      gridSize,
      isEditing,
      template,
      showSearch,
      carouselOptions,
      ...props
    } = this.props;

    const { filter } = this.state;

    let items = itemList.items || [];

    if (showSearch && filter) {
      const v = filter.toLowerCase();
      // TODO : improve search...
      items = items.filter((i) => (i.title || '').toLowerCase().indexOf(v) !== -1);
    }
    return (
      <BaseSection {...props} block={this.props} isEditing={isEditing}>
        {this.renderHeader(items)}
        <Partners
          template={template}
          carouselOptions={carouselOptions}
          partners={items}
          gridSize={gridSize}
          isEditing={isEditing}
        />
      </BaseSection>
    );
  }
}

PartnersSection.defaultProps = {
  countLabel: '',
  gridSize: 4,
  isEditing: false,
  itemList: {},
  template: 'normal',
  showCount: false,
  showSearch: false,
};

PartnersSection.propTypes = {
  countLabel: PropTypes.string,
  gridSize: PropTypes.number,
  isEditing: PropTypes.bool,
  itemList: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.object,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
  showCount: PropTypes.bool,
  showSearch: PropTypes.bool,
  template: PropTypes.oneOf(['normal', 'card', 'carousel']),
  carouselOptions: PropTypes.shape({
    auto: PropTypes.bool,
  }),
};
