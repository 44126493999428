import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from 'classnames';
import './item-list.scss';
import { withComponents } from './Context';
import { iconProps } from '../utils/propTypes';

class RawItem extends PureComponent {
  render() {
    const { title, description, icon, align, components } = this.props;
    const { RichText, FAIcon } = components;
    return (
      <div className="c-item-list__item">
        <div className={align === 'left' ? 'direction-r' : 'direction-l'}>
          <div className="c-item__icon-wrapper">
            <span className="c-item-list__hexa">
              <FAIcon icon={icon} />
            </span>
            <span className="title-wrapper">
              <span className="title">
                <RichText html={title} name="title" placeholder="Add title" />
              </span>
            </span>
          </div>
          <RichText
            className="description"
            html={description}
            name="description"
            placeholder="Add description"
          />
        </div>
      </div>
    );
  }
}

RawItem.defaultProps = {
  align: '',
  description: '',
  icon: undefined,
  title: '',
};

RawItem.propTypes = {
  align: PropTypes.string,
  components: PropTypes.object.isRequired,
  description: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  icon: iconProps,
  title: PropTypes.string,
};

const Item = withComponents(RawItem);

class ProgramItemList extends PureComponent {
  renderItem = (item, index) => {
    const { align } = this.props;
    return <Item key={item._id || index} {...item} align={align} />;
  };

  render() {
    const { items, align, components } = this.props;
    const { List } = components;
    return (
      <List
        className={cx('c-item-list is-clearfix', {
          'c-item-list--left': align === 'left',
          'c-item-list--right': align === 'right',
        })}
        name="items"
        items={items}
        render={this.renderItem}
      />
    );
  }
}

ProgramItemList.defaultProps = {
  align: 'left',
  items: [],
};

ProgramItemList.propTypes = {
  align: PropTypes.string,
  components: PropTypes.object.isRequired,
  items: PropTypes.array,
};

export default withComponents(ProgramItemList);
