/**
 * @author palbaret
 * description : pooling propTypes definition
 */

import PropTypes from 'prop-types';

export const imageProps = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    alt: PropTypes.string,
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    uri: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
]);

export const imageType = PropTypes.oneOf(['original', 'png', 'jpeg', 'auto']);

export const loadingType = PropTypes.oneOf(['eager', 'lazy', 'auto']);

export const figureImageProps = PropTypes.shape({
  type: imageType,
});

export const iconProps = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
  }),
]);

export const routerLocationProps = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
});

export const routerMatchProps = PropTypes.shape({
  params: PropTypes.object,
});

export const metaType = { name: PropTypes.string, content: PropTypes.string };

export const languageProps = PropTypes.shape({
  name: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
});

export const appDataProps = PropTypes.shape({
  disableRouter: PropTypes.bool,
  rooturl: PropTypes.string,
  hotels: PropTypes.array,
  participants: PropTypes.array,
  site: PropTypes.object.isRequired,
  user: PropTypes.object,
});
