import moment from 'moment';
import 'moment/locale/fr';
// import 'moment/locale/en';
import momentTz from 'moment-timezone';

import { format, parseISO } from 'date-fns';
import get from 'lodash/get';

import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';

const locales = { en, fr };
// TODO: adapt locale to website language...

// ! TODO: PUT inside assets folder ?
export const languages = {
  fr: 'https://app.appcraft.events/images/Rvwp7c0UvXZYhU/assets/jSxKBA45x+nuU-dLcZgt7FWL-Ko_.png',
  en: 'https://app.appcraft.events/images/Rvwp7c0UvXZYhU/assets/4Ef6oFtstxWqdyn03HLTPIbOXUQ_.png',
};

const dateFormats = {
  fr: 'EEEE, d LLLL yyyy',
  // en: 'dddd, MMMM D YYYY',
  en: 'EEEE, LLLL do, yyyy',
};

const momentTimeFormats = {
  fr: 'HH:mm',
  en: 'h:mm a',
};

/**
 * date-fns doesn't like YYYY-MM-DD, convert to yyyy-MM-dd
 */
export function convertToDateFNSFormat(dateFormat) {
  if (!dateFormat) return dateFormat;
  return dateFormat.replace(/Y/g, 'y').replace(/DD/g, 'dd');
}

export function formatForLang(date, dateFormat, options = {}) {
  if (!date) return '';
  const { lang = 'fr' } = options;
  const d = parseISO(date.replace(' ', 'T'));
  return format(d, dateFormat, { locale: get(locales, lang, fr) });
}

export function formatDate(date, options = {}) {
  if (!date) return '';
  const { lang = 'fr' } = options;
  const d = parseISO(date.replace(' ', 'T'));
  return format(d, get(dateFormats, lang, dateFormats.fr), { locale: get(locales, lang, fr) });
}

function momentParse(date) {
  // eslint-disable-next-line no-restricted-globals
  const { site } = window.__DATA__;
  // eslint-disable-next-line no-restricted-globals
  window.moment = momentTz;
  if (site?.timezone?.eventTimezone && site?.timezone?.forceForEveryone) {
    const tz = site?.timezone?.eventTimezone || 'Europe/Paris';
    return momentTz.tz(date, [momentTz.ISO_8601, 'YYYY-MM-DD HH:mm'], tz);
  }
  return moment(date, [moment.ISO_8601, 'YYYY-MM-DD HH:mm']);
}

export function formatDatetime(date, dateFormat, options = {}) {
  if (!date) return '';
  const { lang = 'fr' } = options;
  const d = momentParse(date.replace(' ', 'T'));
  const locale = lang === 'fr' ? 'fr' : 'en';
  // console.log('format', get(momentTimeFormats, lang, momentTimeFormats.fr));
  return d.locale(locale).format(get(momentTimeFormats, lang, momentTimeFormats.fr));
}

export function getValue(workshop, key) {
  if (!key) return '';
  if (key === 'startEndTime') {
    const { startDate, endDate } = workshop;
    if (!startDate || !endDate) return '';
    return `${formatDatetime(startDate, 'HH:mm')} - ${formatDatetime(endDate, 'HH:mm')}`;
  }
  return get(workshop, key);
}
