import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../Context';
import { noop } from '../utils';

// eslint-disable-next-line jsx-a11y/anchor-has-content, react/prop-types
export const DisabledRouterLink = ({ onClick, ...props }) => <a {...props} />;

const NavLinkWithParams = (props) => {
  const { disabled, to, ...rest } = props;
  const { disableRouter } = useContext(AppContext);
  if (disabled) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href="#" {...props} onClick={noop} />;
  }
  const component = disableRouter ? DisabledRouterLink : undefined;

  // SSR TODO: handle search ?
  // eslint-disable-next-line no-restricted-globals
  if (typeof to === 'string' && typeof window !== 'undefined' && window.location.search) {
    return (
      <NavLink
        {...rest}
        // eslint-disable-next-line no-restricted-globals
        to={{ pathname: to, search: window.location.search }}
        component={component}
      />
    );
  }
  return <NavLink {...props} component={component} />;
};

NavLinkWithParams.defaultProps = {
  disabled: false,
  to: '#',
};

NavLinkWithParams.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.string,
};

export default NavLinkWithParams;
