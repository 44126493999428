import React, { PureComponent } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import './multi-image-header-section.scss';
import { withEdit } from '../Context';
import { LinkButton } from '../components';
import { figureImageProps } from '../utils/propTypes';

class MultiImageHeaderSection extends PureComponent {
  render() {
    const {
      components,
      buttonColor,
      leftImage,
      textColor,
      text,
      text2,
      title,
      subtitle,
      url,
      rightButtonUrl,
      buttonText,
      rightButtonText,
      isEditing,
      imageProps,
      buttonAlignment,
      imagePosition,
      imageRatio,
      ...props
    } = this.props;
    const { Image, RichText } = components;

    const imageSize = `${100 * imageRatio}%`;

    return (
      <BaseSection
        className={cx(
          'section',
          'multi-image-header hero is-medium',
          imagePosition === 'right' && 'multi-image-header__reverse',
        )}
        block={this.props}
        {...props}
        hasHeader={false}
      >
        <div className="multi-image-header__left" style={{ width: imageSize }}>
          <Image maxWidth={800} src={leftImage} name="leftImage" imageProps={imageProps} />
        </div>
        <div className="multi-image-header__right">
          <RichText html={text} name="text" placeholder="Add text" />
          <h1 key="title" className="title is-1">
            <RichText html={title} name="title" placeholder="Add title" />
          </h1>
          <h3 key="subtitle" className="subtitle is-3">
            <RichText html={subtitle} name="subtitle" placeholder="Add subtitle" />
          </h3>
          <RichText html={text2} name="text" placeholder="Add text" />
          <div className="buttons-row-wrapper" style={{ justifyContent: buttonAlignment }}>
            {url && (
              <LinkButton
                name="buttonText"
                url={url}
                text={buttonText}
                buttonColor={buttonColor}
                isEditing={isEditing}
              />
            )}
            {rightButtonUrl && (
              <LinkButton
                name="rightButtonText"
                url={rightButtonUrl}
                text={rightButtonText}
                buttonColor="white"
                textColor="@primaryColor"
                borderColor="@primaryColor"
                isEditing={isEditing}
              />
            )}
          </div>
        </div>
      </BaseSection>
    );
  }
}

MultiImageHeaderSection.defaultProps = {
  buttonColor: undefined,
  textColor: undefined,
  buttonText: "S'inscrire",
  rightButtonText: 'Se connecter',
  imageProps: undefined,
  isEditing: false,
  leftImage: undefined,
  text: undefined,
  text2: undefined,
  title: undefined,
  subtitle: undefined,
  url: undefined,
  rightButtonUrl: undefined,
  buttonAlignment: 'start',
  imagePosition: 'left',
  imageRatio: 1 / 2,
};

MultiImageHeaderSection.propTypes = {
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonText: PropTypes.string,
  rightButtonText: PropTypes.string,
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  leftImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageProps: figureImageProps,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  rightButtonUrl: PropTypes.string,
  buttonAlignment: PropTypes.string,
  imagePosition: PropTypes.oneOfType(['left', 'right']),
  imageRatio: PropTypes.oneOf([1 / 4, 1 / 3, 1 / 2, 2 / 3, 3 / 4]),
};

export default withEdit(withComponents(MultiImageHeaderSection));
