import PropTypes from 'prop-types';
import cx from 'classnames';
import React, { PureComponent } from 'react';
import { Icon } from '.';
import { withComponents } from './Context';
import { iconProps } from '../utils/propTypes';

class RawService extends PureComponent {
  render() {
    const { icon, iconShape, title, description, layout, components } = this.props;
    const { RichText } = components;
    const classname = 'ms-services c-icon-hover';
    return (
      <div className={cx(classname, `${classname}__${layout}`)}>
        <Icon icon={icon} shape={iconShape} />
        <div style={{ fontWeight: 'bold', fontSize: '1.25em' }}>
          <RichText html={title} name="title" placeholder="Add title" />
        </div>

        <RichText html={description} name="description" placeholder="Add description" />
      </div>
    );
  }
}

RawService.defaultProps = {
  description: '',
  icon: undefined,
  layout: '',
  title: '',
};

RawService.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: iconProps,
  // eslint-disable-next-line react/forbid-prop-types
  iconShape: PropTypes.any.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  layout: PropTypes.string,
  components: PropTypes.object.isRequired,
};

const Service = withComponents(RawService);

class Services extends PureComponent {
  renderItem = (item) => {
    const { layout, iconShape } = this.props;
    return <Service layout={layout} iconShape={iconShape} {...item} />;
  };

  render() {
    const { services, gridSize, components } = this.props;
    const { Grid } = components;
    return (
      <Grid
        items={services}
        isCentered
        name="itemList.items"
        gridSize={gridSize}
        tabletGridSize={Math.floor(gridSize / 2)}
        render={this.renderItem}
      />
    );
  }
}

Services.defaultProps = {
  gridSize: 4,
  layout: '',
  services: [],
};

Services.propTypes = {
  components: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  iconShape: PropTypes.any.isRequired,
  gridSize: PropTypes.number,
  layout: PropTypes.string,
  services: PropTypes.array,
};

export default withComponents(Services);
