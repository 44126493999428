import cookieBanner from './cookie-banner.json';
import fields from './fields.json';
import generic from './generic.json';
import site from './site.json';

export default {
  cookieBanner,
  generic,
  fields,
  site,
};
