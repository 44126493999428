import compact from 'lodash/compact';
import merge from 'lodash/merge';
import i18n from '../../i18n/i18n';
import { setIn } from '../../utils';
import { CookieCategories } from './cookies.types';

export function getCookieConsentName(eventId: string): string {
  return `cc_cookie_site_${eventId}`;
}

export function getCookieConsent(eventId: string): { categories: CookieCategories[] } {
  const name = getCookieConsentName(eventId);
  try {
    const consent = localStorage.getItem(name);
    if (!consent) return { categories: [] };
    return JSON.parse(consent);
  } catch (e) {
    return { categories: [] };
  }
}

// export type CookieBannerOptions = {
//   hasGoogleMapsIframe?: boolean;
//   hasGoogleAnalytics?: boolean;
// };

function injectPrivacyPolicyLink(
  moreInfoSection: Record<string, string>,
  customPrivacyPolicyLink: string,
) {
  const { defaultPrivacyPolicyLink, ...moreInfo } = moreInfoSection;
  return {
    ...moreInfo,
    description: i18n.t('cookieBanner.cookieSections.more-info.description', {
      privacyPolicyLink: customPrivacyPolicyLink ?? defaultPrivacyPolicyLink,
    }),
  };
}

function getCookieBannerTexts(): any {
  // eslint-disable-next-line no-restricted-globals
  const { cookieBanner } = window.__DATA__;
  // const { hasGoogleMapsIframe = true, hasGoogleAnalytics = false } = options ?? {};
  const rawTranslations = i18n.t('cookieBanner', {
    returnObjects: true,
  }) as any;
  const { texts, services, options } = cookieBanner ?? {};
  const siteTranslations = merge({}, rawTranslations, texts);
  const { cookieSections, ...translations } = siteTranslations;
  const hasGoogleMapsIframe = services?.includes('google-maps');

  const moreInfoSection = injectPrivacyPolicyLink(
    cookieSections['more-info'],
    options?.privacyPolicyLink,
  );

  return setIn(
    translations,
    ['preferencesModal', 'sections'],
    compact([
      cookieSections.usage,
      cookieSections.necessary,
      // cookieSections['cookie-consent'],
      // cookieSections.function,
      hasGoogleMapsIframe && cookieSections['google-maps'],
      // hasGoogleAnalytics && cookieSections['google-analytics'],
      // ...window.__DATA__.cookies.extraSections,
      moreInfoSection,
    ]),
  );
}

export function getCookieConsentConfig(
  eventId: string,
  lang: string,
): Pick<CookieConsent.CookieConsentConfig, 'cookie' | 'guiOptions' | 'categories' | 'language'> {
  return {
    cookie: {
      name: `cc_cookie_site_${eventId}`,
      // domain: window.location.hostname,
      // path: '/',
      expiresAfterDays: 182,
      sameSite: 'Lax',
      useLocalStorage: true,
    },
    guiOptions: {
      consentModal: {
        layout: 'box wide',
        position: 'bottom left',
        equalWeightButtons: true,
        flipButtons: true,
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        equalWeightButtons: true,
        flipButtons: true,
      },
    },
    categories: {
      necessary: {
        enabled: true, // this category is enabled by default
        readOnly: true, // this category cannot be disabled
      },
      functionality: {},
      'google-maps': {},
      'google-analytics': {},
    },
    language: {
      default: lang,
      translations: { [lang]: getCookieBannerTexts() },
    },
  };
}
