import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { Route, Switch, withRouter } from 'react-router-dom';

import MiniSiteViewer from './MiniSiteViewer';
import { BackToTopArrow, Menu, MenuCentered, MenuLeft, MenuTopLogo } from './components';
import TcPageTracker from './plugins/TcPageTracker';
import { metaType } from './utils/propTypes';
import { AppContext } from './Context';
import { getCurrentPath } from './utils/pages';

// eslint-disable-next-line react/prop-types
function outputMetadata({ _id, type = 'meta', name, content }) {
  if (!content) return null;
  switch (type) {
    case 'meta':
      if (!name) return undefined;
      if (name === 'title') return <title key={_id}>{content}</title>;
      // eslint-disable-next-line react/prop-types
      if (name.startsWith('og')) return <meta key={_id} property={name} content={content} />;
      return <meta key={_id} name={name} content={content} />;
    case 'style':
      return (
        <style key={_id} type="text/css">
          {content}
        </style>
      );
    case 'link':
      return <link key={_id} rel="stylesheet" href={content} />;
    case 'script':
      return (
        <script key={_id} type={name || 'text/javascript'}>
          {content}
        </script>
      );
    default:
      return null;
  }
}

/**
 * Site-level metadata
 */
const SiteMeta = ({ meta }) => (
  <Helmet>
    <meta property="og:type" content="website" />
    {/* TODO <meta property="og:locale" content="en_GB" /> */}
    {meta.map(outputMetadata)}
  </Helmet>
);

SiteMeta.defaultProps = {
  meta: [],
};

SiteMeta.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.shape(metaType)),
};

function hasTitle(meta) {
  if (!meta) return false;
  return meta.find((m) => m.type === 'meta' && m.name === 'title' && m.content);
}

function renderAlternateLink(rooturl, page, lang) {
  const currentPath = getCurrentPath(rooturl, page, lang);
  return (
    <link key={`alternate-${lang.name}`} rel="alternate" hrefLang={lang.name} href={currentPath} />
  );
}

/**
 * Page-level matadata
 */
const PageMeta = ({ page, languages }) => {
  const { rooturl } = useContext(AppContext);
  const { meta = [], title } = page;
  const defaultLang = (languages[0] || {}).name || 'fr';
  const currentPath = getCurrentPath(rooturl, page, defaultLang);
  return (
    <Helmet>
      <meta property="og:url" content={currentPath} />
      {title && !hasTitle(meta) ? <title>{title}</title> : undefined}
      {languages.length > 0 && <link rel="canonical" href={currentPath} />}
      {languages.map((lang) => renderAlternateLink(rooturl, page, lang))}
      {meta.map((m) => outputMetadata(m, languages))}
    </Helmet>
  );
};

PageMeta.defaultProps = {
  languages: [],
};

PageMeta.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  page: PropTypes.shape({
    link: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.shape(metaType)),
    title: PropTypes.string,
  }).isRequired,
};

class Site extends React.PureComponent {
  renderPages() {
    const { editor, site, disableRouter, PageComponent } = this.props;
    const { languages = [] } = site;
    return (
      <React.Fragment>
        {disableRouter ? null : <SiteMeta {...this.props} meta={site.meta} />}
        <main>
          <Switch>
            {site.pages.map((page) => {
              return (
                <Route
                  key={page._id}
                  exact
                  path={page.link}
                  render={(props) => (
                    <React.Fragment>
                      {disableRouter ? null : <PageMeta page={page} languages={languages} />}
                      <TcPageTracker meta={page.meta} />
                      <PageComponent
                        {...props}
                        blocks={page.sections}
                        editor={editor}
                        key={page._id}
                        showAddSectionButton={!!editor}
                      />
                    </React.Fragment>
                  )}
                />
              );
            })}
          </Switch>
        </main>
        <PageComponent blocks={site.footer} editor={editor} />
        <BackToTopArrow />
      </React.Fragment>
    );
  }

  render() {
    const { site, language, onLanguageChange } = this.props;
    const { menu = {} } = site;
    const { showMobileFooterButton } = menu;
    const mobileFooterShown = showMobileFooterButton ? 'container--has-footer-button' : '';

    switch (site.template) {
      case 'left-menu':
        return (
          <div className={cx('container--left-menu', mobileFooterShown)}>
            <Menu
              {...site}
              logo={site.logoLeft}
              logoProps={site.logoLeftProps}
              language={language}
              onLanguageChange={onLanguageChange}
            />
            <MenuLeft {...site} />
            {this.renderPages()}
          </div>
        );
      case 'centered':
        return (
          <div className={cx('container--centered', mobileFooterShown)}>
            <Menu {...site} language={language} onLanguageChange={onLanguageChange} />
            <MenuCentered {...site} language={language} onLanguageChange={onLanguageChange} />
            {this.renderPages()}
          </div>
        );
      case 'topLogo':
        return (
          <div className={cx('container--topLogo', mobileFooterShown)}>
            <Menu
              {...site}
              forceShowPages={site.pages.length > 1}
              language={language}
              onLanguageChange={onLanguageChange}
            />
            <MenuTopLogo {...site} language={language} onLanguageChange={onLanguageChange} />
            {this.renderPages()}
          </div>
        );
      case 'no-menu':
        return (
          <div className={cx('container--no-menu', mobileFooterShown)}>{this.renderPages()}</div>
        );
      default: {
        const { showHeaderMenu } = menu;
        return (
          <div
            className={cx(
              'container--classic',
              mobileFooterShown,
              showHeaderMenu && 'container--has-header-menu',
            )}
          >
            <Menu {...site} language={language} onLanguageChange={onLanguageChange} />
            {this.renderPages()}
          </div>
        );
      }
    }
  }
}

Site.defaultProps = {
  disableRouter: false,
  editor: undefined,
  language: 'fr',
  PageComponent: MiniSiteViewer,
};

Site.propTypes = {
  disableRouter: PropTypes.bool,
  editor: PropTypes.object,
  language: PropTypes.string,
  onLanguageChange: PropTypes.func.isRequired,
  site: PropTypes.shape({
    languages: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
    logoLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    logoLeftProps: PropTypes.shape({ maxWidth: PropTypes.number, maxHeight: PropTypes.number }),
    meta: PropTypes.array,
    menu: PropTypes.object,
    pages: PropTypes.array,
    footer: PropTypes.array,
    template: PropTypes.oneOf(['classic', 'left-menu', 'centered', 'topLogo', 'no-menu']),
  }).isRequired,
  PageComponent: PropTypes.elementType,
};

export default withRouter(Site);
