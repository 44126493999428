import { createContext, useContext } from 'react';
import { CookieCategories } from './cookies.types';

export type CookieContextType = {
  hasCookieBanner?: boolean;
  categories: CookieCategories[];
};

export const CookieContext = createContext<CookieContextType>({
  hasCookieBanner: false,
  categories: [],
});

export function useCookieContext(): CookieContextType {
  return useContext(CookieContext);
}
