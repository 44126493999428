import React from 'react';
import PropTypes from 'prop-types';
import { withDesign } from '../Context';

const allSize = {
  thin: 2,
  small: 5,
  medium: 10,
  big: 20,
};

const Colorized = (props) => {
  const { size, color1, color2, design } = props;
  return (
    <div
      className="divider--colorized"
      style={{
        height: allSize[size],
        background: `linear-gradient(90deg, ${color1 || design.primaryColor} 0%, ${
          color2 || 'transparent'
        }  100%)`,
      }}
    />
  );
};

Colorized.propTypes = {
  size: PropTypes.string,
  color1: PropTypes.string,
  color2: PropTypes.string,
};

Colorized.defaultProps = {
  size: 'thin',
  color1: undefined,
  color2: 'transparent',
};

export default withDesign(Colorized);
