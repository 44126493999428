import deburr from 'lodash/deburr';
import memoize from 'memoizee';
import { decode } from 'he';

export const decodeHtmlEntities = memoize(decode);

// eslint-disable-next-line import/prefer-default-export
export function stripHtml(html) {
  return html?.replace(/<[^>]*>/g, '');
}

export function titleFromHtml(html) {
  return decodeHtmlEntities(stripHtml(html));
}

export function slugify(str) {
  return deburr(str)
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}
