/* eslint-disable no-restricted-globals */
import { FC } from 'react';
import { useEvent, useMount } from 'react-use';
import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './CookieBanner.scss';
import { getCookieConsentConfig } from './cookie.utils';
import { useCookieContext } from './CookieContext';

function removeHash() {
  const loc = window.location;
  if (window.history?.pushState) {
    window.history.pushState('', document.title, loc.pathname + loc.search);
  }
}

function useShowOnHash(hash: string, fun: () => void) {
  useMount(() => {
    if (window.location.href.includes(hash)) {
      fun();
    }
  });

  useEvent('hashchange', (e) => {
    if (e.newURL.includes(hash)) {
      fun();
    }
  });
}

const CookieBanner: FC<{ lang: string; eventId: string }> = ({ lang = 'fr', eventId }) => {
  const { hasCookieBanner } = useCookieContext();
  useMount(() => {
    if (hasCookieBanner) {
      CookieConsent.run(getCookieConsentConfig(eventId, lang));
    }
  });
  useShowOnHash('#cookie-banner', () => CookieConsent.showPreferences());

  // Remove #cookie-banner from url if needed
  useEvent('cc:onModalHide', removeHash);

  return null;
};

export default CookieBanner;
