/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types';
import React from 'react';
import { withAppContext } from '../Context';
import { withComponents } from '../components/Context';
import ResizableIframe from '../components/ResizableIframe';
import BaseSection from './BaseSection';
import { generateIframeUrl, injectQueryString, isRegistrationV1Url } from '../utils/urlUtils';
import { getParameterByName, replaceUrlValues } from '../utils';

const RegistrationIFrame = React.memo((props) => {
  const {
    source = '',
    hasAutoResize,
    autoInjectPrivateKey,
    injectSiteLanguage,
    ...otherProps
  } = props;
  const { lang } = props.data;

  const urlType = isRegistrationV1Url(source) ? undefined : 'registration';
  let url = replaceUrlValues(generateIframeUrl(source, urlType, autoInjectPrivateKey));
  if (injectSiteLanguage && lang) {
    url = injectQueryString(url, { lang });
  }
  if (!url.includes('registered=')) {
    url = injectQueryString(url, { registered: getParameterByName('registered') || undefined });
  }
  return (
    <BaseSection
      {...otherProps}
      block={props}
      style={{ paddingLeft: 0, paddingRight: 0 }}
      autoInjectPrivateKey={autoInjectPrivateKey}
    >
      {!source && (
        <div style={{ padding: 32 }}>Paramétrez l'url du formulaire dans les options de droite</div>
      )}
      <ResizableIframe src={url} autoResize={hasAutoResize} />
    </BaseSection>
  );
});

RegistrationIFrame.defaultProps = {
  isPreview: false,
  source: '',
  hasAutoResize: true,
  autoInjectPrivateKey: false,
};

RegistrationIFrame.propTypes = {
  hasAutoResize: PropTypes.bool,
  isPreview: PropTypes.bool,
  source: PropTypes.string,
  autoInjectPrivateKey: PropTypes.bool,
};

export default withAppContext(withComponents(RegistrationIFrame));
