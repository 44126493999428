import React from 'react';
import PropTypes from 'prop-types';

const DivOrHtml = ({ content, ...props }) => {
  if (!content) return null;

  if (content.indexOf('<p') === -1) {
    // Normal text mode
    return <div {...props}>{content}</div>;
  }

  // Html mode
  // eslint-disable-next-line react/no-danger
  return <div {...props} dangerouslySetInnerHTML={{ __html: content }} />;
};

DivOrHtml.defaultProps = {
  content: undefined,
};

DivOrHtml.propTypes = {
  content: PropTypes.string,
};

export default DivOrHtml;
