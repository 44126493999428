import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { formFields } from '../components/ContactForm';
import { Columns, Column, ContactForm } from '../components';
import { withComponents } from '../components/Context';

class ContactSection extends PureComponent {
  render() {
    const { fields, strings, image, imagePosition, components, ...props } = this.props;
    const { Image } = components;
    // TODO : use fields to filter formFields
    return (
      <BaseSection {...props} block={this.props}>
        <Columns isCentered centerVertically>
          {imagePosition === 'left' && (
            <Column size={6}>
              <Image src={image} maxWidth={700} />
            </Column>
          )}
          <Column size={imagePosition && imagePosition !== 'none' ? 6 : 9}>
            <ContactForm fields={Array.isArray(fields) ? fields : formFields} strings={strings} />
          </Column>
          {imagePosition === 'right' && (
            <Column size={6}>
              <Image src={image} maxWidth={700} />
            </Column>
          )}
        </Columns>
      </BaseSection>
    );
  }
}

ContactSection.defaultProps = {
  fields: undefined,
  strings: {},
  image: undefined,
  imagePosition: 'none',
};

ContactSection.propTypes = {
  components: PropTypes.object.isRequired,
  fields: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({ name: PropTypes.string, type: PropTypes.string }),
        ),
      }),
    ),
  ]),
  strings: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imagePosition: PropTypes.oneOf(['none', 'left', 'right']),
};

export default withComponents(ContactSection);
