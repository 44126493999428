import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importing translation files
import fr from './locales/fr';
import en from './locales/en';
import es from './locales/es/index.js';
import de from './locales/de';

// Creating object with the variables of imported translation files
const resources = {
  fr: {
    translation: fr,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  de: {
    translation: de,
  },
};

// i18n Initialization
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
export const translateWithPrefix = (t, prefix) => (key) => t(`${prefix}.${key}`);
