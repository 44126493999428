import React, { PureComponent } from 'react';
import get from 'lodash/get';
import { replaceDataValues } from '../../utils';
import './modern.scss';
import Speakers from '../Speakers';
import {
  WorkshopUserCount,
  WorkshopDetailsButton,
  WorkshopDate,
  WorkshopLang,
  WorkshopDescription,
  WorkshopTitle,
  WorkshopCategory,
} from './components';
import WorkshopDetailsModal from '../WorkshopDetailsModal';
import {
  workshopDefaultPropTypes,
  workshopsPropTypes,
  workshopsDefaultPropTypes,
  workshopPropTypes,
} from './workshop-proptypes';
import Images from '../../utils/Images';

export default class Workshop extends PureComponent {
  state = { isActive: false };

  render() {
    const {
      labelMapping,
      workshop,
      titleTemplate,
      descriptionTemplate,
      dateFormat,
      showUserCount,
      showDetails,
      showSpeakers,
      detailsLabel,
      userCountLabel,
      speakersTemplate,
    } = this.props;
    const { startDate, endDate, userCount, category, lang, image, speakers } = workshop;
    const { isActive } = this.state;
    const title = replaceDataValues(titleTemplate, workshop).trim();
    const description = replaceDataValues(descriptionTemplate, workshop);
    const categoryLabel = get(labelMapping, ['category', category], category);
    return (
      <div className="workshop">
        {image && image.uri && (
          <div className="workshop--image">
            <img {...Images.srcSet.maxWidth(image, 500)} alt="" />
          </div>
        )}
        <div className="workshop--content">
          <div className="header">
            <WorkshopDate startDate={startDate} endDate={endDate} format={dateFormat} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <WorkshopLang lang={lang} />
              <WorkshopCategory category={categoryLabel} />
            </div>
          </div>
          <WorkshopTitle title={title} />
          <WorkshopDescription description={description} />
          <Speakers speakers={speakers} showSpeakers={showSpeakers} template={speakersTemplate} />
          <div className="footer">
            <WorkshopUserCount
              showUserCount={showUserCount}
              userCount={userCount}
              userCountLabel={userCountLabel}
            />
            <WorkshopDetailsButton
              showDetails={showDetails}
              detailsLabel={detailsLabel}
              onClick={() => this.setState({ isActive: true })}
            />
          </div>
          <WorkshopDetailsModal
            isActive={isActive}
            onClose={() => this.setState({ isActive: false })}
            workshop={workshop}
            title={title}
            description={description}
          />
        </div>
      </div>
    );
  }
}

Workshop.defaultProps = workshopDefaultPropTypes;
Workshop.propTypes = workshopPropTypes;

export class Modern extends PureComponent {
  renderWorkshop(workshop) {
    const { workshopConfig } = this.props;
    return (
      <Workshop
        key={workshop._id}
        {...workshopConfig}
        titleTemplate={workshopConfig.title}
        descriptionTemplate={workshopConfig.description}
        workshop={workshop}
      />
    );
  }

  render() {
    const { workshops } = this.props;
    return workshops.map((workshop) => this.renderWorkshop(workshop));
  }
}

Modern.defaultProps = workshopsDefaultPropTypes;
Modern.propTypes = workshopsPropTypes;
