import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withComponents } from '../components/Context';
import BaseSection from './BaseSection';
import { Columns, Column, Video } from '../components';

class VideoSection extends PureComponent {
  renderVideoColumn() {
    const { columnsSize, url, video } = this.props;

    return (
      <Column className={columnsSize}>
        <Video url={url} {...video} />
      </Column>
    );
  }

  render() {
    const {
      verticalAlign,
      url,
      video,
      isFullWidth,
      videoPosition,
      components,
      text,
      ...props
    } = this.props;
    const { RichText } = components;

    return (
      <BaseSection
        {...props}
        isFullWidth={isFullWidth || videoPosition === 'fullpage'}
        block={this.props}
      >
        {(videoPosition === 'left' || videoPosition === 'right') && (
          <Columns verticalAlign={verticalAlign}>
            {videoPosition === 'left' && this.renderVideoColumn()}
            <Column className="is-half">
              <RichText html={text} name="text" placeholder="Texte" />
            </Column>
            {videoPosition === 'right' && this.renderVideoColumn()}
          </Columns>
        )}
        {(videoPosition === 'center' || videoPosition === 'fullpage') && (
          <Video url={url} {...video} />
        )}
      </BaseSection>
    );
  }
}

VideoSection.defaultProps = {
  columnsSize: 'is-half',
  isFullWidth: undefined, // Deprecated
  text: '',
  verticalAlign: 'center',
  video: undefined,
  videoPosition: 'center',
};

VideoSection.propTypes = {
  columnsSize: PropTypes.string,
  components: PropTypes.shape({
    RichText: PropTypes.elementType,
  }).isRequired,
  isFullWidth: PropTypes.bool,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  videoPosition: PropTypes.oneOf(['left', 'center', 'right', 'fullpage']),
  video: PropTypes.shape({
    format: PropTypes.oneOf(['is-16by9', 'is-4by3', 'is-3by1']),
    disableUrlConversion: PropTypes.bool,
  }),
};

export default withComponents(VideoSection);
