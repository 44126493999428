import PropTypes from 'prop-types';

export const workshopDefaultPropTypes = {
  ctaText: undefined,
  ctaUrl: undefined,
  dateFormat: 'HH:mm',
  descriptionTemplate: '',
  detailsLabel: 'En savoir + >',
  showDetails: false,
  showSpeakers: true,
  showUserCount: false,
  speakersTemplate: 'bubble',
  titleTemplate: '',
  userCountLabel: 'inscrits',
};

export const workshopPropTypes = {
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  dateFormat: PropTypes.string,
  descriptionTemplate: PropTypes.string,
  detailsLabel: PropTypes.string,
  showDetails: PropTypes.bool,
  showSpeakers: PropTypes.bool,
  showUserCount: PropTypes.bool,
  titleTemplate: PropTypes.string,
  userCountLabel: PropTypes.string,
  workshop: PropTypes.object.isRequired,
};

export const workshopsDefaultPropTypes = {
  workshops: [],
  workshopConfig: {},
};

export const workshopsPropTypes = {
  workshops: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
  workshopConfig: PropTypes.object,
};
