import { ConditionContext } from '@appcraft/common-utils';

export interface Operator {
  text: string;
  value: string;
  valueType: string;
}

const isChecked = {
  text: 'EST COCHÉ',
  value: 'isChecked',
  valueType: 'boolean',
};

const isNotChecked = {
  text: 'EST DÉCOCHÉ',
  value: 'isNotChecked',
  valueType: 'boolean',
};

const isEqual = {
  text: 'EST EGAL A',
  value: 'isEqual',
  valueType: 'string',
};

const isNotEqual = {
  text: "N'EST PAS EGAL A",
  value: 'isNotEqual',
  valueType: 'string',
};
const isAnyOf = {
  text: "EST L'UN DE...",
  value: 'isAnyOf',
  valueType: 'array',
};
const isNoneOf = {
  text: "N'EST AUCUN DE...",
  value: 'isNoneOf',
  valueType: 'array',
};
const contains = {
  text: 'CONTIENT',
  value: 'contains',
  valueType: 'string',
};
const doesntContain = {
  text: 'NE CONTIENT PAS',
  value: 'doesntContain',
  valueType: 'string',
};
const isEmpty = {
  text: 'EST VIDE',
  value: 'isEmpty',
  valueType: 'null',
};
const isNotEmpty = {
  text: "N'EST PAS VIDE",
  value: 'isNotEmpty',
  valueType: 'null',
};

const hasAnyOf = {
  text: "A L'UN DE...",
  value: 'hasAnyOf',
  valueType: 'array',
};
const hasAllOf = {
  text: 'A TOUT...',
  value: 'hasAllOf',
  valueType: 'array',
};
const isExactly = {
  text: 'EST EXACTEMENT',
  value: 'isExactly',
  valueType: 'array',
};
const hasNoneOf = {
  text: "N'A AUCUN DE...",
  value: 'hasNoneOf',
  valueType: 'array',
};
const has = {
  text: 'A',
  value: 'has',
  valueType: 'string',
};
const doesntHave = {
  text: "N'A PAS",
  value: 'doesntHave',
  valueType: 'string',
};
export const operationValues = [
  isEqual,
  isNotEqual,
  isAnyOf,
  isNoneOf,
  contains,
  doesntContain,
  isEmpty,
  isNotEmpty,
];
export const booleanValues = [isChecked, isNotChecked];
export const selectOperationValues = [isEqual, isNotEqual, isAnyOf, isNoneOf, isEmpty, isNotEmpty];
export const multiselectOperationValues = [
  has,
  doesntHave,
  hasAnyOf,
  hasAllOf,
  isExactly,
  hasNoneOf,
  isEmpty,
  isNotEmpty,
];

export const numericOperators = [
  {
    text: '>',
    value: '>',
  },
  {
    text: '≥',
    value: '>=',
  },
  {
    text: '<',
    value: '<',
  },
  {
    text: '≤',
    value: '<=',
  },
].map((v) => ({ ...v, valueType: 'number' }));

export const andOrValues = [
  {
    label: 'TOUTES LES CONDITIONS SONT VERIFIEES',
    value: 'and',
  },
  {
    label: 'AU MOINS UNE CONDITION EST VERIFIEE',
    value: 'or',
  },
];

export const labels = {
  and: 'ET',
  or: 'OU',
};

function ensureArray(v: string | string[]) {
  if (!v) return [];
  if (Array.isArray(v)) return v;
  return [v];
}

const ops: Record<string, any> = {
  isEqual: { type: 'binop', printLabel: '=' },
  isNotEqual: { type: 'binop', printLabel: '≠' },
  isExactly: { type: 'arrayop', printLabel: 'IS EXACTLY' },
  isAnyOf: { type: 'arrayop', printLabel: 'IS ANY OF' },
  isNoneOf: { type: 'arrayop', printLabel: 'IS NONE OF' },
  hasAllOf: { type: 'arrayop', printLabel: 'HAS ALL OF' },
  hasAnyOf: { type: 'arrayop', printLabel: 'HAS ANY OF' },
  hasNoneOf: { type: 'arrayop', printLabel: 'HAS NONE OF' },
  contains: { type: 'binop', printLabel: 'CONTAINS' },
  doesntContain: { type: 'binop', printLabel: "DOESN'T CONTAIN" },
  has: { type: 'binop', printLabel: 'HAS' },
  doesntHave: { type: 'binop', printLabel: "DOESN'T HAVE" },
  isEmpty: { type: 'unop', printLabel: 'IS EMPTY' },
  isNotEmpty: { type: 'unop', printLabel: 'IS NOT EMPTY' },
  '>': { type: 'binop' },
  '<': { type: 'binop' },
  '>=': { type: 'binop' },
  '<=': { type: 'binop' },
  and: { type: 'boolop', printLabel: 'AND' },
  or: { type: 'boolop', printLabel: 'OR' },
};

function convertExpressionToText(expression: any, indentSize = 0): string {
  const { op, key, value, expressions } = expression;
  const { type, printLabel = op } = ops[op] || {};
  const valueLabel = Array.isArray(value) ? `[${value.join(', ')}]` : value;
  switch (type) {
    case 'binop': {
      return `${key} ${printLabel} ${valueLabel}`;
    }
    case 'unop': {
      return `${key} ${printLabel}`;
    }
    case 'arrayop': {
      return `${key} ${printLabel} [${ensureArray(value).join(', ')}]`;
    }
    case 'boolop': {
      return `(${expressions
        .map((e: any) => convertExpressionToText(e, indentSize + 2))
        .join(` \n${printLabel} `)})`;
    }
    default:
      return 'unknown';
  }
}

function removeOuterParentheses(text: string) {
  if (!text) return text;
  let str = text;
  if (str[0] === '(') str = str.slice(1);
  if (str[str.length - 1] === ')') str = str.slice(0, str.length - 1);
  return str;
}

export function convertToText(condition: ConditionContext, mustIndent = false): string {
  if (!condition) return '';
  let expString = convertExpressionToText(condition, 0);
  if (!mustIndent) expString = expString.replace(/\n/g, '').replace(/[ ]{2}/g, '');
  return removeOuterParentheses(expString);
}
