import React, { PureComponent } from 'react';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import cx from 'classnames';
import get from 'lodash/get';
import { withComponents } from '../components/Context';
import Dropdown from '../editor/components/Dropdown';
import BaseSection from './BaseSection';
import { withAppContext } from '../Context';
import { NavLinkWithParams } from '../components';

function globalSearch(fields, collection, text = '') {
  const searchableFields = fields
    .filter((f) => f.type === 'text' && f.searchable)
    .map((s) => s.key);
  return collection.filter((object) => {
    for (const key of searchableFields) {
      if (object[key] && object[key].toLowerCase().includes(text.toLowerCase())) {
        return true;
      }
    }
    return false;
  });
}

function ensureString(v) {
  if (typeof v === 'number') return v.toString();
  return v || '';
}

export function replaceValues(txt, obj) {
  if (!txt) return '';
  return txt.replace(/{(.*?)}/g, (m, key) => ensureString(get(obj, key)));
}

class RawItem extends PureComponent {
  handleChange = (e) => {
    const { name, value } = e.target;
    const { namePrefix, onChange } = this.props;
    if (namePrefix) {
      onChange(`${namePrefix}.${name}`, value);
    } else {
      onChange(name, value);
    }
  };

  render() {
    const {
      template,
      showIcons = true,
      components,
      _id,
      goto = '/',
      fields = [],
      ...props
    } = this.props;
    const { RichText, Image } = components;
    const image = fields.find((f) => f.type === 'image');
    const imageStyle = { maxWidth: 120, margin: 'auto', maxHeight: 120, ...(image.style || {}) };
    return (
      <NavLinkWithParams
        to={{ pathname: `${goto}/${_id}` }}
        className={cx('box', 'c-opportunity', template)}
        style={{ position: 'relative' }}
      >
        <div className="border" />
        {image && (
          <Image
            loading="lazy"
            src={props[image.key]}
            maxWidth={imageStyle.maxWidth || 120}
            maxHeight={imageStyle.maxHeight}
            style={imageStyle}
          />
        )}
        <div style={{ flex: 1, marginLeft: 10 }} className="fields-container">
          {fields
            .filter((f) => f.type === 'text')
            .map((f) => {
              // eslint-disable-next-line no-shadow
              const { icon, visible = true, style = {}, template = false } = f;
              if (!visible) return null;
              const text = template ? replaceValues(f.key, props) : props[f.key];
              return (
                <div style={{ display: 'flex', ...style }}>
                  {icon && showIcons && (
                    <i className={`fa fa-fw fa-${icon}`} style={{ minWidth: 32, marginTop: 2 }} />
                  )}
                  <RichText key={f.key} html={text} name={f.key} placeholder={`Add ${f.key}`} />
                </div>
              );
            })}
        </div>
      </NavLinkWithParams>
    );
  }
}

const Item = withComponents(RawItem);

class OpportunitiesSection extends PureComponent {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    gridSize: 3,
    showGroup: false,
    showSearch: false,
    showCount: false,
    countLabe: '',
  };

  state = {
    groupListBy: undefined,
    filter: '',
  };

  handleGroup = (key) => {
    this.setState({ groupListBy: key === 'none' ? undefined : key });
  };

  handleSearch = (e) => this.setState({ filter: e.target.value });

  renderGroupByDropdown = (groupField, groupableFields) => {
    const { showGroup } = this.props;
    if (!showGroup) return null;
    return (
      <Dropdown
        style={{ marginRight: 10 }}
        title="Grouper par : "
        size="small"
        icon="filter"
        hoverable
        extraTitle={`${(groupField && groupField.name) || ''}`}
        right
      >
        {groupableFields.map((f) => {
          const { key, name, icon } = f;
          return (
            <Dropdown.Item
              style={{ cursor: 'pointer' }}
              key={key}
              onClick={() => this.handleGroup(key)}
            >
              <i className={`fa fa-fw fa-${icon}`} />
              {` ${name}`}
            </Dropdown.Item>
          );
        })}
      </Dropdown>
    );
  };

  renderSearchBar = () => {
    const { showSearch } = this.props;
    const { filter } = this.state;

    if (!showSearch) return null;
    return (
      <div className="c-searchbar__input" style={{ flexGrow: 0 }}>
        <div className="control has-icons-left">
          <input
            className="input"
            type="text"
            name="filter"
            placeholder="Rechercher"
            value={filter}
            onChange={this.handleSearch}
          />
          <span className="icon is-left">
            <i className="fas fa-search" />
          </span>
        </div>
      </div>
    );
  };

  renderCountLabel = (filteredItems) => {
    const { showCount, countLabel } = this.props;
    if (!showCount) return null;
    return (
      <div className="c-searchbar__label" style={{ flexGrow: 0 }}>
        {`${filteredItems.length} ${filteredItems.length === 1 ? countLabel : `${countLabel}s`}`}
      </div>
    );
  };

  render() {
    const {
      showSearch,
      showCount,
      showGroup,
      fields = [],
      gridSize,
      components,
      template,
      showIcons,
      dataLabel,
      goto,
      order,
      data,
      countLabel,
      itemList,
      ...props
    } = this.props;
    const items = data[dataLabel] || [];
    const { filter, groupListBy } = this.state;
    const orderedItems = order ? orderBy(items, order, ['asc']) : items;
    const filteredItems = globalSearch(fields, orderedItems, filter);
    const groupableFields = [
      { key: 'none', name: 'Aucun', icon: 'ban' },
      ...fields.filter((f) => f.groupable),
    ];
    const groupedItems = groupListBy && groupBy(filteredItems, groupListBy);
    const groupField = fields.find((g) => g.key === groupListBy);
    const { Grid } = components;
    return (
      <BaseSection {...props} block={this.props}>
        {(showSearch || showCount || showGroup) && (
          <div className="c-searchbar">
            {this.renderSearchBar()}
            {this.renderGroupByDropdown(groupField, groupableFields)}
            <div style={{ flex: 1 }} />
            {this.renderCountLabel(filteredItems)}
          </div>
        )}
        {groupListBy && (
          <div>
            {Object.keys(groupedItems).map((group) => {
              return (
                <div>
                  <h2
                    className="subtitle c-opportunity__group-label"
                    style={{ fontWeight: 'bold', margin: '15px 0px' }}
                  >
                    {group}
                  </h2>
                  <Grid
                    equalHeight
                    isCentered={false}
                    items={groupedItems[group]}
                    name="itemList.items"
                    gridSize={gridSize}
                    tabletGridSize={Math.floor(gridSize / 2)}
                    render={(item) => (
                      <Item
                        {...item}
                        fields={fields}
                        template={template}
                        showIcons={showIcons}
                        goto={goto}
                      />
                    )}
                  />
                </div>
              );
            })}
          </div>
        )}
        {!groupListBy && (
          <Grid
            equalHeight
            isCentered={false}
            items={filteredItems}
            name="itemList.items"
            gridSize={gridSize}
            tabletGridSize={Math.floor(gridSize / 2)}
            render={(item) => (
              <Item
                {...item}
                fields={fields}
                template={template}
                showIcons={showIcons}
                goto={goto}
              />
            )}
          />
        )}
      </BaseSection>
    );
  }
}

export default withAppContext(withComponents(OpportunitiesSection));
