import React from 'react';
import PropTypes from 'prop-types';

export function makeContainer(defaultClassName) {
  const Component = ({ as, className, children, ...props }) => {
    const Comp = as;
    return (
      <Comp className={`${defaultClassName} ${className}`} {...props}>
        {children}
      </Comp>
    );
  };
  Component.defaultProps = {
    as: 'div',
    className: '',
    children: undefined,
  };

  Component.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
    className: PropTypes.string,
    children: PropTypes.node,
  };
  return Component;
}

export function splitLines(string) {
  if (!string) return string;
  return string.split(/[\n]|\\n/).map((line, key) => {
    return (
      // eslint-disable-next-line react/no-array-index-key
      <span key={key}>
        {line}
        <br />
      </span>
    );
  });
}
