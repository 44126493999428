import React from 'react';

export default class BackToTopArrow extends React.PureComponent {
  state = {
    isTop: true,
  };

  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('scroll', this.onScroll);
  }

  scrollToTop = () => {
    // eslint-disable-next-line no-restricted-globals
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  onScroll = () => {
    // eslint-disable-next-line no-restricted-globals
    const isTop = window.scrollY < 32;
    const { isTop: stateIsTop } = this.state;
    if (isTop !== stateIsTop) {
      this.setState({ isTop }); // Lazy refresh
    }
  };

  render() {
    const { isTop } = this.state;
    return (
      <div
        className="c-arrow--scroll-to-top"
        onClick={this.scrollToTop}
        id="scroll-to-top"
        style={{
          transition: 'transform 250ms ease',
          transform: isTop ? 'translateY(80px)' : 'translateY(0)',
        }}
      >
        <i className="fa fa-arrow-up" style={{ color: 'white' }} />
      </div>
    );
  }
}
