import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TimelineItem extends PureComponent {
  render() {
    const { time, description, icon } = this.props;
    return (
      <li>
        <div className="direction-r">
          <div className="flag-wrapper">
            <span className="hexa">
              <i className={`fa fa-${icon}`} style={{ fontSize: 16, color: 'white' }} />
            </span>
            <span className="time-wrapper">
              <span className="time">{time}</span>
            </span>
          </div>
          <div className="desc">{description}</div>
        </div>
      </li>
    );
  }
}

TimelineItem.defaultProps = {
  description: '',
  icon: '',
  time: '',
};

TimelineItem.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  time: PropTypes.string,
};

export default class Timeline extends PureComponent {
  render() {
    const { items } = this.props;
    return (
      <ul className="timeline">
        {items.map((item, index) => {
          // eslint-disable-next-line react/no-array-index-key
          return <TimelineItem key={index} {...item} />;
        })}
      </ul>
    );
  }
}

Timeline.defaultProps = {
  items: [],
};

Timeline.propTypes = {
  items: PropTypes.array,
};
