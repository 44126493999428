import memoizeOne from 'memoize-one';

// eslint-disable-next-line import/prefer-default-export
export function memoizeWithKey(fn) {
  const cache = {};
  return (key, ...args) => {
    let memoized = cache[key];
    if (!memoized) {
      memoized = memoizeOne(fn);
      cache[key] = memoized;
    }
    return memoized(...args);
  };
}
