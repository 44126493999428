import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import iFrameResize from 'iframe-resizer/js/iframeResizer';

class ResizableIframe extends PureComponent {
  resizeIfNeeded = (e) => {
    const { autoResize, src } = this.props;
    if (autoResize && src) {
      iFrameResize({ log: false }, e.target);
    }
  };

  render() {
    const { src, autoResize, width, height, style, ...props } = this.props;
    // iframe key used to force refresh iframe/reload when url or hasAutoResize changes
    const iframeKey = `${src}-${autoResize ? '?resize' : '?no-resize'}`;
    return (
      <iframe
        title="frame"
        key={iframeKey}
        src={src}
        onLoad={this.resizeIfNeeded}
        style={{
          display: 'block',
          width: width || 1,
          minWidth: width ? undefined : '100%',
          maxWidth: width ? '100%' : undefined,
          height: height || 'calc(100vh - 65px - 82px)', // TODO : remove ?
          overflow: 'auto',
          ...style,
        }}
        {...props}
      />
    );
  }
}

ResizableIframe.defaultProps = {
  autoResize: true,
  height: undefined,
  src: '',
  style: undefined,
  width: undefined,
};

ResizableIframe.propTypes = {
  autoResize: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  src: PropTypes.string,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ResizableIframe;
