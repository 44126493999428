import React, { PureComponent } from 'react';
import { Menu, Hero } from '.';

export default class Header extends PureComponent {
  render() {
    return (
      <Hero {...this.props}>
        <Menu {...this.props} />
      </Hero>
    );
  }
}
