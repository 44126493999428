const translatableString = { type: 'string', translatable: true };

export const baseSectionSchema = {
  type: 'object',
  properties: {
    title: translatableString,
    subtitle: translatableString,
  },
};

export const sectionSchemas = {
  MultiImageHeaderSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      text: translatableString,
      buttonText: translatableString,
      rightButtonText: translatableString,
      rightButtonUrl: translatableString,
      url: translatableString,
    },
  },
  HeroImage: {
    type: 'object',
    properties: {},
  },
  HeroTextSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      buttonText: translatableString,
      rightButtonText: translatableString,
      rightButtonUrl: translatableString,
      url: translatableString,
    },
  },
  TextButtonSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      text: translatableString,
      buttonText: translatableString,
      url: translatableString,
    },
  },
  TextImageShapeSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      text: translatableString,
    },
  },
  ImageAndTextSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      text: translatableString,
    },
  },
  VideoSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      text: translatableString,
    },
  },
  ProgramSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      items: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            title: translatableString,
            items: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  title: translatableString,
                  description: translatableString,
                },
              },
            },
          },
        },
      },
    },
  },
  GallerySection: baseSectionSchema,
  CarouselSection: baseSectionSchema,
  VerticalTimelineSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      items: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            iconTitle: translatableString,
            title: translatableString,
            description: translatableString,
            link: translatableString,
            button: {
              type: 'object',
              properties: {
                title: translatableString,
              },
            },
          },
        },
      },
    },
  },
  ListSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      text: translatableString,
      itemList: {
        type: 'object',
        properties: {
          items: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                text: translatableString,
              },
            },
          },
        },
      },
    },
  },
  PartnersSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      itemList: {
        type: 'object',
        properties: {
          items: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: translatableString,
              },
            },
          },
        },
      },
    },
  },
  InformationSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      itemList: {
        type: 'object',
        properties: {
          items: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: translatableString,
                description: translatableString,
              },
            },
          },
        },
      },
    },
  },
  AccessSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      itemList: {
        type: 'object',
        properties: {
          title: translatableString,
          items: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: translatableString,
                description: translatableString,
              },
            },
          },
        },
      },
    },
  },
  MobileAppsSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      description: translatableString,
    },
  },
  TextSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      itemList: {
        type: 'object',
        properties: {
          items: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                title: translatableString,
                description: translatableString,
                url: translatableString,
                buttonText: translatableString,
              },
            },
          },
        },
      },
    },
  },
  RemoteTextSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      url: translatableString,
    },
  },
  DataItemSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
    },
  },
  RegistrationIFrame: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      source: translatableString,
    },
  },
  IFrameSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      text: translatableString,
      source: translatableString,
    },
  },
  CountdownSection: {
    type: 'object',
    properties: {
      title: translatableString,
      subtitle: translatableString,
      date: translatableString,
      strings: {
        type: 'object',
        properties: {
          days: translatableString,
          hours: translatableString,
          minutes: translatableString,
          seconds: translatableString,
        },
      },
    },
  },
  // TODO
  // ContactSection:
  // TrombinoscopeSection:
  // WorkshopsSection:
  // TabsSection:
  // OpportunitiesSection:
};

const sectionSchema = {
  type: 'section',
};

const metaSchema = {
  type: 'object',
  properties: {
    content: translatableString,
  },
};

const pageSchema = {
  type: 'object',
  properties: {
    title: translatableString,
    link: { ...translatableString, deprecated: true },
    meta: {
      type: 'array',
      items: metaSchema,
    },
    sections: {
      type: 'array',
      items: sectionSchema,
    },
  },
};

export const siteSchema = {
  type: 'object',
  properties: {
    title: translatableString,
    footer: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          blocks: {
            type: 'array',
            items: {
              type: 'object',
              properties: {
                itemList: {
                  type: 'object',
                  properties: {
                    items: {
                      type: 'array',
                      items: {
                        type: 'object',
                        properties: {
                          socialmedia: {
                            type: 'array',
                            items: {
                              type: 'object',
                              properties: {
                                url: translatableString,
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    menu: {
      type: 'object',
      properties: {
        menuTitle: translatableString,
        mobileFooterButtonSettings: {
          type: 'object',
          properties: {
            menuTitle: translatableString,
            btn: {
              type: 'object',
              properties: {
                label: translatableString,
              },
            },
          },
        },
        headerMenuSettings: {
          type: 'object',
          properties: {
            socialMedia: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  url: translatableString,
                },
              },
            },
          },
        },
      },
      translations: {
        en: { menuTitle: 'Menu title' },
      },
    },
    pages: {
      type: 'array',
      items: pageSchema,
    },
  },
};
