/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const SmallIcon = ({ icon, className }) => (
  <span className={cx(className, 'icon is-small')}>
    <i className={`fas fa-${icon}`} aria-hidden="true" />
  </span>
);

SmallIcon.defaultProps = {
  className: undefined,
};

SmallIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export class OutsideClick extends PureComponent {
  handleClick = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <div
        style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 210 }}
        onClick={this.handleClick}
      />
    );
  }
}

OutsideClick.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class Dropdown extends Component {
  state = {
    open: false,
  };

  toggleOpen = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  close = () => {
    this.setState({ open: false });
  };

  handleSelect = (item) => {
    const { onSelect } = this.props;
    this.setState({ open: false });
    if (onSelect) {
      onSelect(item);
    }
  };

  renderItems() {
    const { value, items, scrollable, children } = this.props;
    return (
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className={cx('dropdown-content', { 'is-scrollable': scrollable })}>
          {
            // prettier-ignore
            items ?
            items.map((item, index) => (
              <Dropdown.Item
                key={index}
                {...item}
                active={value && item.value && item.value === value}
                onClick={() => this.handleSelect(item)}
              />
              )) :
            children
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      title,
      buttonStyle,
      size,
      hoverable,
      right,
      icon,
      leftIcon,
      items,
      className,
      value,
      extraTitle,
      ...props
    } = this.props;
    const { open } = this.state;
    const selected = items?.find((i) => i.value === value);

    return (
      <div
        className={cx('dropdown', className, {
          'is-active': open,
          'is-hoverable': hoverable,
          'is-right': right,
        })}
        {...props}
      >
        <div className="dropdown-trigger">
          <button
            className={`button ${size ? `is-${size}` : ''}`}
            style={{ justifyContent: 'flex-end', ...buttonStyle }}
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={hoverable ? undefined : this.toggleOpen}
          >
            {leftIcon && <SmallIcon icon={leftIcon} />}
            {title && (
              <span style={{ flex: 1 }}>
                {title}
                {!!extraTitle && <span style={{ fontWeight: 'bold' }}>{extraTitle}</span>}
              </span>
            )}
            {!title && <span>{selected?.label}</span>}
            <SmallIcon icon={icon} />
          </button>
        </div>
        {open && !hoverable && (
          <React.Fragment>
            <OutsideClick onClick={this.close} />
            {this.renderItems()}
          </React.Fragment>
        )}
        {hoverable && this.renderItems()}
      </div>
    );
  }
}

Dropdown.defaultProps = {
  icon: 'angle-down',
};

Dropdown.propTypes = {
  icon: PropTypes.string,
};

// is-active
Dropdown.Item = ({ active, children, label, ...props }) => (
  <a className={cx('dropdown-item', { 'is-active': active })} {...props}>
    {children || label}
  </a>
);

Dropdown.Separator = () => <hr className="dropdown-divider" />;

export default Dropdown;
