import { upperCase } from 'lodash';
import React from 'react';
import i18n from '../i18n/i18n';

const opNames = {
  contains: i18n.t('generic.style-dropdown.conditions.contain'),
  doesntContain: i18n.t('generic.style-dropdown.conditions.doesnt-contain'),
  isEmpty: i18n.t('generic.style-dropdown.conditions.is-empty'),
  isNotEmpty: i18n.t('generic.style-dropdown.conditions.is-not-empty'),
  has: i18n.t('generic.style-dropdown.conditions.has'),
  doesntHave: i18n.t('generic.style-dropdown.conditions.doesnt-have'),
  isEqual: '=',
  isNotEqual: '≠',
};

function generateConditionLabel(node) {
  const { op, key, value } = node || {};
  switch (op) {
    case 'and':
      return (
        <>
          <>{generateConditionLabel(node.left)}</>
          <span> {upperCase(i18n.t('generic.common.and'))} </span>
          <>{generateConditionLabel(node.right)}</>
        </>
      );
    case 'or':
      return (
        <>
          <>{generateConditionLabel(node.left)}</>
          <span> {upperCase(i18n.t('generic.common.or'))} </span>
          <>{generateConditionLabel(node.right)}</>
        </>
      );
    default:
      return (
        <>
          <code>{key}</code> <span>{opNames[op]}</span> <code>{value}</code>
        </>
      );
  }
}

export default generateConditionLabel;
