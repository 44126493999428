import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default class Modal extends PureComponent {
  render() {
    const {
      isActive,
      title,
      subtitle,
      children,
      styles,
      onClose,
      onConfirm,
      confirmBtn,
      cancelBtn,
    } = this.props;
    const { label: confirmLabel, icon: confirmIcon, style: confirmStyle } = confirmBtn;
    const { label: cancelLabel, icon: cancelIcon, style: cancelStyle } = cancelBtn;
    const { cardStyle, headStyle, footerStyle, titleStyle, subtitleStyle } = styles;
    return (
      <div className={cx('modal', { 'is-active': isActive })}>
        <div className="modal-background" />
        <div className="modal-card" style={cardStyle}>
          <header className="modal-card-head" style={headStyle}>
            <div style={{ flex: 1 }}>
              {title && (
                <p className="modal-card-title" style={titleStyle}>
                  {title}
                </p>
              )}
              {subtitle && (
                <p className="modal-card-subtitle" style={subtitleStyle}>
                  {subtitle}
                </p>
              )}
            </div>
            <button className="delete" aria-label="close" onClick={onClose} />
          </header>
          <section className="modal-card-body">{children}</section>
          <footer
            className="modal-card-foot"
            style={{ justifyContent: 'flex-end', ...footerStyle }}
          >
            <button className="button is-rounded" onClick={onClose} style={cancelStyle}>
              <span className="icon is-small">
                <i className={`fas fa-${cancelIcon}`} />
              </span>
              <span>{cancelLabel}</span>
            </button>
            {onConfirm && (
              <button className="button is-rounded" onClick={onConfirm} style={confirmStyle}>
                {confirmIcon && (
                  <span className="icon is-small">
                    <i className={`fas fa-${confirmIcon}`} />
                  </span>
                )}
                <span>{confirmLabel}</span>
              </button>
            )}
          </footer>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  cancelBtn: {
    label: 'Fermer',
    icon: 'undo',
    style: {},
  },
  confirmBtn: {
    label: 'Confirmer',
    icon: 'check',
    style: {},
  },
  isActive: false,
  styles: {},
  subtitle: undefined,
  title: undefined,
  onConfirm: undefined,
};

Modal.propTypes = {
  cancelBtn: PropTypes.object,
  children: PropTypes.node.isRequired,
  confirmBtn: PropTypes.object,
  isActive: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  styles: PropTypes.object,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
