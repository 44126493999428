import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSection from './BaseSection';
import { Speakers } from '../components';
import { withDesign, withAppContext } from '../Context';
import { appDataProps } from '../utils/propTypes';

class TrombinoscopeSection extends PureComponent {
  render() {
    const { showAlphabet, design, alphabetColor, maxGridSize, data, ...props } = this.props;
    const color = alphabetColor === '@primaryColor' ? design.primaryColor : alphabetColor;
    return (
      <BaseSection {...props} block={this.props}>
        <Speakers
          alphabetColor={color}
          maxGridSize={maxGridSize}
          showAlphabet={showAlphabet}
          speakers={data.participants || []}
        />
      </BaseSection>
    );
  }
}

TrombinoscopeSection.defaultProps = {
  alphabetColor: '@primaryColor',
  maxGridSize: 10,
  participants: [],
  showAlphabet: true,
};

TrombinoscopeSection.propTypes = {
  data: appDataProps.isRequired,
  alphabetColor: PropTypes.string,
  design: PropTypes.object.isRequired,
  participants: PropTypes.arrayOf(
    PropTypes.shape({ firstName: PropTypes.string, lastName: PropTypes.string }),
  ),
  maxGridSize: PropTypes.oneOf([8, 10]),
  showAlphabet: PropTypes.bool,
};

export default withAppContext(withDesign(TrombinoscopeSection));
