import React from 'react';
import PropTypes from 'prop-types';

const MobileFooterButton = (props) => {
  const { btn } = props;

  return (
    <div className="mobile-footer-button">
      <a className="button is-medium" href={btn.url}>
        {btn.label}
      </a>
    </div>
  );
};

MobileFooterButton.defaultProps = {
  btn: {},
};

MobileFooterButton.propTypes = {
  btn: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default MobileFooterButton;
