import React from 'react';
import PropTypes from 'prop-types';
import { replaceDataValues } from '../utils';
import { Card } from '../bulma';
import { imageProps as bulmaImageProps } from '../bulma/propTypes';
import Images from '../utils/Images';
import { avatarPlaceholder } from '../assets/avatarPlaceholder';

// eslint-disable-next-line import/prefer-default-export
export class ButtonCardItem extends React.PureComponent {
  render() {
    const { item, imageField, imageProps, titleTemplate, linkField } = this.props;
    const title = replaceDataValues(titleTemplate, item).trim();
    const image = imageField && item[imageField];
    const link = linkField && item[linkField];
    const Container = link ? 'a' : 'div';
    return (
      <Container className="cardBtnLink" href={link} target="_blank" rel="noopener noreferrer">
        <Card>
          <Card.Image
            {...Images.srcSet.maxWidth(image || avatarPlaceholder, 500, { type: 'auto' })}
            alt={title}
            loading="lazy"
            {...imageProps}
          />
        </Card>
      </Container>
    );
  }
}

ButtonCardItem.defaultProps = {
  imageField: undefined,
  imageProps: undefined,
  linkField: '',
  titleTemplate: '',
};

ButtonCardItem.propTypes = {
  imageField: PropTypes.string,
  imageProps: bulmaImageProps,
  item: PropTypes.object.isRequired,
  linkField: PropTypes.string,
  titleTemplate: PropTypes.string,
};
