import cx from 'classnames';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { EditContext, withEdit } from '../Context';
import { smoothScroll } from '../utils/domUtils';
import { languageProps } from '../utils/propTypes';
import MenuDisconnect from './Connection/MenuDisconnect';
import { withComponents } from './Context';
import FAIcon from './FAIcon';
import HeaderMenu from './HeaderMenu';
import { MenuItem, extractPageMenuSections } from './Menu';
import NavLinkWithParams from './NavLinkWithParams';

const CustomNavLink = ({ children, ...linkProps }) => (
  <Route
    path={linkProps.to}
    exact={linkProps.exact}
    // eslint-disable-next-line react/no-children-prop
    children={({ match }) => children({ active: !!match, linkProps, match })}
  />
);

CustomNavLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};

const renderCustomNavLink = ({ icon, index, link, onClick, showIcons, title, visibleMenu }) => {
  // SSR TODO: handle location.search
  // eslint-disable-next-line no-restricted-globals
  const to = link + (typeof window === 'undefined' ? '' : window.location.search);

  return (
    <CustomNavLink to={link} exact>
      {({ active }) => (
        <div
          key={index}
          className={cx('navbar-item has-dropdown is-hoverable', active && 'is-active')}
          onClick={onClick}
        >
          <Link class="navbar-link" to={to}>
            {showIcons && (
              <span className="icon">
                <FAIcon icon={icon} />
              </span>
            )}
            <span>{title}</span>
          </Link>

          <div className="navbar-dropdown">
            {visibleMenu.map((item) => (
              <Link
                key={item._id}
                className="navbar-item"
                scroll={smoothScroll}
                to={`${to}#anchor-${item.id}`}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
      )}
    </CustomNavLink>
  );
};

const NavItem = (props) => {
  const { page, index, menu, onClick } = props;
  const { title, icon, link, subMenu, sections } = page;
  const { showIcons } = menu;
  const visibleMenu =
    subMenu && subMenu.filter((item) => !!sections.find((s) => s.name === item.id));
  if (visibleMenu && visibleMenu.length > 0) {
    return renderCustomNavLink({ icon, index, link, onClick, showIcons, title, visibleMenu });
  }
  return <MenuItem key={index} item={page} menu={menu} onCloseBurgerMenu={onClick} />;
};

NavItem.propTypes = {
  index: PropTypes.number.isRequired,
  menu: PropTypes.shape({
    showIcons: PropTypes.bool,
    buttonType: PropTypes.oneOf(['classic', 'tab', 'button']),
  }).isRequired,
  page: PropTypes.shape({
    icon: PropTypes.string,
    link: PropTypes.string,
    menuButton: PropTypes.shape({
      type: PropTypes.oneOf(['classic', 'tab', 'button']),
    }),
    title: PropTypes.string,
    type: PropTypes.oneOf(['link', 'page']),
    sections: PropTypes.arrayOf(PropTypes.object),
    subMenu: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

class MenuCentered extends PureComponent {
  state = {
    isActive: false,
    isTop: true,
  };

  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleToggleBurgerMenu = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  closeBurgerMenu = () => {
    this.setState({ isActive: false });
  };

  handleLogoChange = (key, value) => {
    const { onChange } = this.props;
    onChange('logo', key, value ? value.uri : '');
  };

  handleScroll = () => {
    // eslint-disable-next-line no-restricted-globals
    const isTopWindow = window.scrollY === 0;
    const { isTop } = this.state;
    if (isTopWindow !== isTop) {
      this.setState({ isTop });
    }
  };

  render() {
    const {
      logo,
      pages,
      components,
      menu = {},
      isEditing,
      languages,
      language,
      onLanguageChange,
    } = this.props;
    const { invertColors, showHeaderMenu = false, headerMenuSettings = {} } = menu;
    const { isActive, isTop } = this.state;
    const active = isActive ? 'is-active' : '';
    const mainPages = flatten(
      pages
        .filter((p) => p.type !== 'subpage' && p.type !== 'hidden')
        .map((page) => [page, ...extractPageMenuSections(page)]),
    );
    const count = Math.round(mainPages.length / 2);
    const menuGroups = mainPages.length > 1 ? chunk(mainPages, count) : [];
    const { Image } = components;
    return (
      <EditContext.Provider value={{ language, onChange: this.handleLogoChange }}>
        <nav
          id="navbar"
          className={cx('navbar', 'is-centered-menu', { invert: invertColors })}
          style={{ borderBottom: isTop ? 'none' : '1px solid rgba(0, 0, 0, 0.2)' }}
        >
          <div className="container">
            <div className={cx('navbar-menu', active)}>
              {(menuGroups[0] || []).map((submenu, index) => (
                <NavItem
                  key={index}
                  page={submenu}
                  index={index}
                  menu={menu}
                  onClick={this.closeBurgerMenu}
                />
              ))}
            </div>
            <div className="navbar-brand">
              <NavLinkWithParams to="/" disabled={isEditing}>
                <Image src={logo} name="logo" alt="Logo" isUnsplash={false} />
              </NavLinkWithParams>
              <span
                aria-label="menu"
                aria-expanded="false"
                className={cx('navbar-burger', active)}
                onClick={this.handleToggleBurgerMenu}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </span>
            </div>
            <div className={cx('navbar-menu', active)}>
              {(menuGroups[1] || []).map((submenu, index) => (
                <NavItem
                  key={index}
                  page={submenu}
                  index={index}
                  menu={menu}
                  onClick={this.closeBurgerMenu}
                />
              ))}
              <MenuDisconnect />
            </div>
          </div>
          {showHeaderMenu && (
            <div className="header--menu" style={{ position: 'absolute', top: 10, right: 20 }}>
              <HeaderMenu
                language={language}
                languages={languages}
                invertColors={invertColors}
                settings={headerMenuSettings}
                onLanguageChange={onLanguageChange}
              />
            </div>
          )}
        </nav>
      </EditContext.Provider>
    );
  }
}

MenuCentered.defaultProps = {
  isEditing: false,
  language: undefined,
  languages: [],
  logo: '',
  menu: {},
  onChange: noop,
  pages: [],
};

MenuCentered.propTypes = {
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  language: PropTypes.string,
  languages: PropTypes.arrayOf(languageProps),
  logo: PropTypes.string,
  menu: PropTypes.object,
  onChange: PropTypes.func,
  onLanguageChange: PropTypes.func.isRequired,
  pages: PropTypes.array,
};

export default withEdit(withRouter(withComponents(MenuCentered)));
