import React from 'react';
import BulmaTable from '../../bulma/Table';
import { getValue } from '../utils';
import { workshopsPropTypes, workshopsDefaultPropTypes } from './workshop-proptypes';

const defaultFields = [
  { key: 'startEndTime', label: 'Horaire', style: { width: 150 } },
  { key: 'title', label: 'Session' },
];

// eslint-disable-next-line import/prefer-default-export
export const Table = (props) => {
  const { workshops, workshopConfig } = props;
  const { fields = defaultFields, tableConfig = {} } = workshopConfig;
  return (
    <BulmaTable fullWidth narrow {...tableConfig}>
      <thead>
        <tr style={{ fontSize: '0.9em' }}>
          {fields.map(({ key, label, style }) => (
            <th style={style} key={key}>
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {workshops.map((workshop) => (
          <tr key={workshop._id}>
            {fields.map(({ key }) => (
              <td key={key}>{getValue(workshop, key)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </BulmaTable>
  );
};

Table.defaultProps = workshopsDefaultPropTypes;
Table.propTypes = workshopsPropTypes;
