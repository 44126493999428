import React, { FC } from 'react';
import { SSOConnectionMethod } from '../../../types/connection.types';
import { useSiteTranslation } from '../../../utils/translations';

type SsoConnectionProps = {
  config: SSOConnectionMethod;
};

const SsoConnection: FC<SsoConnectionProps> = ({ config }) => {
  const providers = config.providers || [];
  const { t } = useSiteTranslation();

  return (
    <div className="sso-connections">
      {providers.map((provider, index) => {
        return (
          <a key={index} href={provider.url} className="button is-primary is-fullwidth">
            {t('site.login.connect-with')} {provider.label}
          </a>
        );
      })}
    </div>
  );
};

export default SsoConnection;
