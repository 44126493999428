/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

class Wave extends React.PureComponent {
  render() {
    const { color, style, ...props } = this.props;
    return (
      <svg {...props} viewBox="0 0 1000 99" style={{ pointerEvents: 'none', ...style }}>
        <path
          opacity="0.33"
          d="M527,32.7c203.9-88.3,263.1,34,320.3,0C934-19.1,1000,40.3,1000,40.3V100H0l0-59.7c0,0,62.1-26.1,94.9-29.3  c32.8-3.3,62.8,12.3,75.8,22.1c23.3,17.3,84,58.2,134.4,62.2S507.6,41,527,32.7z"
          fill="#f7f7f7"
        />
        <path
          opacity="0.66"
          d="M266,32.7c45.5,0,77.2,23.2,129.1,39.1c28.6,8.7,150.3,10.1,254-39.1s91.7,34.4,149.2,0  c86-51,201.7,27.5,201.7,27.5V100H0l0-36.5C0,63.5,28.2,82,92.1,82C189.8,81.9,224.3,32.7,266,32.7z"
          fill="#f7f7f7"
        />
        <path
          d="M233.9,71.1c200,57.5,266-65.5,395.1-19.5c129,46.6,129,43,186.2,27.8c56.8-15.2,52.5-24.3,94.9-31.9  C971.4,36.3,1000,100,1000,100H0c0,0,9.9-40.9,83.6-48.1S170.4,53,233.9,71.1z"
          style={{ fill: color }}
        />
      </svg>
    );
  }
}

Wave.defaultProps = {
  color: '#f7f7f7',
  style: undefined,
};

Wave.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Wave;
