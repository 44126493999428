import PropTypes from 'prop-types';

export const imageFitPropType = PropTypes.oneOf(['cover', 'contain', 'contain-padding']);

export const imageBorderRadiusPropTypes = PropTypes.oneOf(['none', 'light', 'rounded']);

export const imageFormatPropType = PropTypes.oneOf([
  'is-square',
  'is-4by3',
  'is-3by2',
  'is-16by9',
  'is-2by1',
  'is-3by1',
  'is-3by4',
  'is-2by3',
  'is-9by16',
  'is-1by2',
  'is-1by3',
  'is-16x16',
  'is-24x24',
  'is-32x32',
  'is-48x48',
  'is-64x64',
  'is-96x96',
  'is-128x128',
]);

export const imageProps = PropTypes.shape({
  fit: imageFitPropType,
  format: imageFormatPropType,
  borderRadius: imageBorderRadiusPropTypes,
  colorOverlay: PropTypes.bool,
});
