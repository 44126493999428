import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ShareButton from './ShareButton';
import { AppContext } from '../../Context';
import { useCurrentPath, useCurrentSitePage } from '../../utils/pages';

const ShareCurrentPageButton = ({ socialMedias, iconClassName, language, invertColors }) => {
  const { site } = useContext(AppContext);
  const { share = {} } = site.menu?.headerMenuSettings || {};

  const page = useCurrentSitePage();
  const url = useCurrentPath(page, language);
  if (!page || !share.enabled) return null;

  const descriptionMeta = page.meta?.find(({ name }) => name === 'description');
  const description = share.description || (descriptionMeta?.content ?? '');
  const title = share.title || (page.title ?? site.title);

  return (
    <ShareButton
      title={title}
      description={description}
      url={share.url || url}
      socialMedias={socialMedias}
      iconClassName={iconClassName}
      language={language}
      invertColors={invertColors}
    />
  );
};
ShareCurrentPageButton.defaultProps = {
  iconClassName: '',
  language: 'fr',
  invertColors: false,
};
ShareCurrentPageButton.propTypes = {
  socialMedias: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  iconClassName: PropTypes.string,
  language: PropTypes.string,
  invertColors: PropTypes.bool,
};

export default ShareCurrentPageButton;
