/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../Context';
import { disconnectUser } from '../../pages/LoginPage/login.utils';
import { useConnectedUserInfo } from '../../utils/hooks';
import { useIsInEditor } from '../Context';
import FAIcon from '../FAIcon';

type MenuDisconnectProps = {
  showLabel?: boolean;
};

const MenuDisconnect: FC<MenuDisconnectProps> = ({ showLabel }) => {
  const { t } = useTranslation();
  const userInfo = useConnectedUserInfo();
  const isInEditor = useIsInEditor();
  const { site } = useAppContext() as any;
  if (!site.login?.enabled || !userInfo) {
    return null;
  }

  const handleDisconnect = (e: any) => {
    if (isInEditor) return;

    e.preventDefault();
    disconnectUser();
    e.stopPropagation();
  };
  return (
    <button
      className="navbar-item navbar-item--sign-out"
      title="logout"
      onClick={handleDisconnect}
      type="button"
    >
      <span className="icon">
        <FAIcon icon="sign-out-alt" />
      </span>
      {showLabel && <>&nbsp;{t('site.login.sign-out')}</>}
    </button>
  );
};

export default MenuDisconnect;
