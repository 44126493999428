import wretch, { Wretcher } from 'wretch';

// eslint-disable-next-line no-restricted-globals
const { endpoints } = window.__DATA__;
const hostApi = endpoints?.site || '/siteApi';

class ApiService {
  api = (): Wretcher => wretch(hostApi);
}

export default ApiService;
