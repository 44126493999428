import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import { withEdit } from '../Context';
import { LinkButton } from '../components';
import './textButtonSection.scss';

class TextButtonSection extends PureComponent {
  render() {
    const { buttonColor, buttonText, components, isEditing, text, url, ...props } = this.props;
    const { RichText } = components;

    return (
      <BaseSection isEditing={isEditing} {...props} block={this.props}>
        <RichText html={text} name="text" placeholder="Add text" />
        <div className="button-container">
          <LinkButton
            buttonColor={buttonColor}
            name="buttonText"
            isEditing={isEditing}
            text={buttonText}
            url={url}
          />
        </div>
      </BaseSection>
    );
  }
}

TextButtonSection.defaultProps = {
  buttonColor: '@primaryColor',
  buttonText: "S'inscrire",
  isEditing: false,
  text: '',
  url: '/registration',
};

TextButtonSection.propTypes = {
  buttonColor: PropTypes.string,
  buttonText: PropTypes.string,
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  text: PropTypes.string,
  url: PropTypes.string,
};

export default withEdit(withComponents(TextButtonSection));
