/* eslint-disable no-restricted-globals */
import '@fortawesome/fontawesome-free/css/all.css';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import './App.scss';
import { BackToTopArrow, FAIcon, FigureImage, Grid, Image, List, RichText } from './components';

import { AppContext, DesignContext, withAppContext } from './Context';

import { ComponentContext } from './components/Context';

import Site from './Site';
import Styles from './Styles';
import MobileFooterButton from './components/MobileFooterButton';
import WebStorage from './data/WebStorage';
import LoginPage from './pages/LoginPage/LoginPage';
import authService from './services/auth.service';
import { getCurrentPath } from './utils/pages';
import { appDataProps, routerLocationProps } from './utils/propTypes';
import CookieBanner from './components/CookieBanner/CookieBanner';
import CookieContextProvider from './components/CookieBanner/CookieContextProvider';
import i18n from './i18n/i18n';

export const viewerComponents = {
  isInEditor: false,
  RichText,
  List,
  Grid,
  FAIcon,
  Image,
  FigureImage,
};

const webStorage = new WebStorage(window.__DATA__.eventId);

class App extends Component {
  state = webStorage.getItem() || {};

  constructor(props) {
    super(props);
    if (props.lang) {
      i18n.changeLanguage(props.lang);
    }
  }

  componentDidMount = () => {
    if (this.loginRequired() && this.isLoggedIn()) {
      this.loadUserData();
    }
  };

  componentDidUpdate(prevProps) {
    // Scroll to top on page change
    const { location } = this.props;
    if (location !== prevProps.location) {
      // Compare has
      if (!location.hash) {
        // eslint-disable-next-line no-restricted-globals
        window.scrollTo(0, 0);
      }
    }
  }

  getActivePage() {
    const { site, location } = this.props;
    return site.pages.find((p) => p.link === location.pathname) || site.pages[0];
  }

  handleLanguageChange = (lang) => {
    const { data } = this.props;
    // eslint-disable-next-line no-restricted-globals
    window.location = getCurrentPath(data.rooturl, this.getActivePage(), { name: lang });
  };

  loginRequired = () => {
    const { site } = this.props;
    return site.login?.enabled && site.login?.required;
  };

  isLoggedIn = () => {
    const { user, token } = this.state;
    return !!user && !!token;
  };

  onDisconnect = () => {
    this.setState({ user: null, token: null });
    webStorage.removeItem();
  };

  onLogin = (loginInfo) => {
    const { user, token, sessionId } = loginInfo;
    const userConfig = {
      user,
      token,
      sessionId,
    };
    webStorage.setItem(userConfig);

    if (window.location.search) {
      // Redirect to same page with url parameters
      // eslint-disable-next-line prefer-destructuring
      window.location.href = window.location.href.split('?')[0].replace('/login', '');
    } else {
      this.setState(userConfig, () => {
        this.loadUserData();
      });
    }
  };

  loadUserData = async () => {
    const { token } = this.state;
    this.setState({
      loading: true,
    });
    try {
      const { data } = await authService.fetchSiteData(token);
      if (!data) {
        throw new Error('Invalid data');
      }

      window.__DATA__ = { ...window.__DATA__, ...data };
      this.setState({ loading: false });
    } catch (e) {
      // Disconnect
      console.error('e');
      this.onDisconnect();
    }
  };

  render = () => {
    const { site: propsSite } = this.props;
    const { site: stateSite } = this.state;
    const site = stateSite || propsSite;

    const { menu = {} } = site;
    const { showMobileFooterButton, mobileFooterButtonSettings } = menu || {};

    return (
      // Re-insert AppContext, re-renders when data is changed
      <AppContext.Provider value={window.__DATA__}>
        <DesignContext.Provider value={site.design}>
          <React.Fragment>
            <Styles {...site.design} />
            <ComponentContext.Provider value={viewerComponents}>
              {this.renderSite()}
            </ComponentContext.Provider>
            <BackToTopArrow />
            {showMobileFooterButton && <MobileFooterButton {...mobileFooterButtonSettings} />}
          </React.Fragment>
        </DesignContext.Provider>
      </AppContext.Provider>
    );
  };

  renderSite = () => {
    const { lang, disableRouter } = this.props;
    const { site, eventId } = window.__DATA__;
    const { loading } = this.state;

    if (loading) {
      return null; // Loading, when should we quit ? :/
    }
    if (this.loginRequired() && !this.isLoggedIn()) {
      return <LoginPage site={site} onLogin={this.onLogin} />;
    }
    if (!site.pages?.length) {
      console.log('Still loading ?');
      return null;
    }
    return (
      <CookieContextProvider eventId={eventId} lang={lang}>
        <Site
          disableRouter={disableRouter}
          site={{ ...site, menu: site.menu || {} }}
          language={lang}
          onLanguageChange={this.handleLanguageChange}
        />
        <CookieBanner lang={lang} eventId={eventId} />
      </CookieContextProvider>
    );
  };
}

App.defaultProps = {
  disableRouter: false,
  lang: undefined,
};

App.propTypes = {
  data: appDataProps.isRequired,
  disableRouter: PropTypes.bool,
  lang: PropTypes.string,
  site: PropTypes.object.isRequired,
  location: routerLocationProps.isRequired,
};

export default withAppContext(withRouter(App));
