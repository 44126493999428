import React from 'react';
import PropTypes from 'prop-types';
import { SocialIcon } from './Social';

const SocialNetworks = ({ socialNetworks }) => {
  if (!socialNetworks?.length) return null;
  return (
    <div className="social-networks">
      {socialNetworks.map((social, index) => (
        <SocialIcon key={social._id || index} icon={social.icon} url={social.url} />
      ))}
    </div>
  );
};

SocialNetworks.defaultProps = {
  socialNetworks: undefined,
};

SocialNetworks.propTypes = {
  socialNetworks: PropTypes.arrayOf({
    _id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default SocialNetworks;
