/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDesignSystem } from './utils/design-system/useDesignSystem';
import { contrast } from './styles/colors';

const Styles = (props) => {
  const {
    customCSS,
    customFont,
    primaryColor,
    bodyFont,
    bgImage,
    backgroundColor,
    customDocument,
  } = props;

  // const parentPrefix = scope ? `.${scope} ` : '';
  const primaryContrast = contrast(primaryColor, '#ffffff');
  const contrastIsEnough = primaryContrast >= 2;
  const contrastColor = contrastIsEnough ? '#ffffff' : '#363636';
  const primaryContrastColor = contrastIsEnough ? primaryColor : contrastColor;

  const { generateGlobalStyle } = useDesignSystem(customDocument);

  const globalStyle = useMemo(
    () =>
      generateGlobalStyle({
        additionalVariables: {
          color: {
            primary: primaryColor,
            'primary-contrast': primaryContrastColor,
            background: backgroundColor,
            contrast: contrastColor,
          },
          background: {
            image: bgImage ? `url(${bgImage})` : '',
          },
        },
        customFont,
        bodyFont,
      }),
    [
      primaryColor,
      bgImage,
      customFont,
      bodyFont,
      generateGlobalStyle,
      primaryContrastColor,
      contrastColor,
    ],
  );

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
        ${globalStyle}
          ${customCSS}
          `,
      }}
    />
  );
};

const fontProps = PropTypes.shape({
  category: PropTypes.string,
  kind: PropTypes.string,
  family: PropTypes.string,
});

Styles.defaultProps = {
  customCSS: '',
  primaryColor: undefined,
  scope: undefined,
  bodyFont: undefined,
  customFont: undefined,
};

Styles.propTypes = {
  customCSS: PropTypes.string,
  primaryColor: PropTypes.string,
  scope: PropTypes.string,
  bodyFont: fontProps,
  customFont: fontProps,
  customDocument: PropTypes.any,
};

export default Styles;
