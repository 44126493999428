import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from 'classnames';

export default class Title extends PureComponent {
  render() {
    const { text, size, color } = this.props;
    return (
      <h1 className={cx('title', `is-${size || 3}`, 'has-text-centered')} style={{ color }}>
        {text}
      </h1>
    );
  }
}

Title.defaultProps = {
  text: '',
  size: 0,
};

Title.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string,
  size: PropTypes.number,
};
