import { useEffect, useState } from 'react';
import WebStorage from '../data/WebStorage';

// eslint-disable-next-line import/prefer-default-export
export const useHasClientSideChecks = () => {
  // TODO: disable if not doing SSR (ie check if window.__DATA__ and no ssr enabled ?)
  const [, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return false;
};

/**
 * useConnectedUser currently doesn't refresh
 * @returns
 */
export const useConnectedUserInfo = () => {
  const [connectedUser] = useState(() => {
    // eslint-disable-next-line no-restricted-globals
    const webStorage = new WebStorage(window.__DATA__.eventId);
    return webStorage.getItem();
  });
  return connectedUser;
};
