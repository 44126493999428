import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './ShareModal.scss';

const ShareModal = ({ visible, onClose, children }) => {
  if (!visible) return null;
  // Using portal to allow it to be in front of all content.
  return ReactDOM.createPortal(
    <section className="ShareModal--Backdrop" onClick={onClose}>
      <div className="ShareModal--Card">
        <i className="fa fa-times" role="button" tabIndex={0} aria-label="close" />
        <div className="ShareModal--Card--Content">{children}</div>
      </div>
    </section>,
    // eslint-disable-next-line no-restricted-globals
    document.body,
  );
};
ShareModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
export default ShareModal;
