/* eslint-disable no-restricted-globals */
import wretch from 'wretch';

const { endpoints } = window.__DATA__;
const hostApi = endpoints?.site || '/siteApi';
class AuthService {
  api = () => wretch(hostApi);

  login = async (email: string, password: string): Promise<any> => {
    return this.api().url('/auth/login').post({ login: email, password }).json();
  };

  sendMagicLink = async (email: string): Promise<{ success?: boolean; error?: string }> => {
    return this.api().url('/auth/sendEmailLink').post({ email }).json();
  };

  autoLogin = async (token: string): Promise<Record<string, any>> => {
    try {
      const res = await this.api()
        .url('/auth/autoLogin')
        .post({ token, collection: 'participants' })
        .json();
      return res;
    } catch (e) {
      console.error(e);
      return { error: true };
    }
  };

  fetchSiteData = async (token: string) => {
    try {
      return await this.api().url('/auth/site/data').auth(`JWT ${token}`).get().json();
    } catch (e) {
      console.error(e);
      return null;
    }
  };
}

export default new AuthService();
