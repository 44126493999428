import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EditContext, withConfigs, withDesign, withEdit } from '../Context';
import { Button } from '../components';
import { ComponentContext } from '../components/Context';
import Divider from '../dividers';
import generateConditionLabel from '../editor/GenerateConditionLabel';
import { extractColorLuma } from '../styles/colors';
import Images from '../utils/Images';
import { convertToText } from '../utils/conditionUtils';
import { slugify, stripHtml } from '../utils/stringUtils';

const globalStyles = {
  bottom: { position: 'absolute', left: 0, bottom: 0, right: 0, width: 'inherit' },
};

/**
 * RichTextHeader optionnaly injects a header if required (ie non specified in html block)
 * If not editing and if html is empty, it also completely removes the node
 */
const RichTextHeader = ({ as: Header, html, isEditing, ...props }) => {
  // Nothing to show ?
  if (!isEditing && !html) return null;

  // Check if html contains a header or not
  if (html.indexOf('<h') !== -1) {
    // Found header, don't output this one as header
    return <div {...props} />;
  }
  return <Header {...props} />;
};

RichTextHeader.defaultProps = {
  html: '',
  isEditing: false,
};

RichTextHeader.propTypes = {
  as: PropTypes.string.isRequired,
  html: PropTypes.string,
  isEditing: PropTypes.bool,
};

const SectionHeader = ({ title, subtitle, defaultStyle, isEditing }) => {
  const { RichText } = useContext(ComponentContext);
  return (
    <React.Fragment>
      <RichTextHeader
        as="h2"
        className="title"
        style={defaultStyle}
        html={title}
        isEditing={isEditing}
      >
        <RichText html={title} name="title" placeholder="Add title" />
      </RichTextHeader>
      <RichTextHeader
        as="h3"
        className="subtitle"
        style={defaultStyle}
        html={subtitle}
        isEditing={isEditing}
      >
        <RichText html={subtitle} name="subtitle" placeholder="Add subtitle" />
      </RichTextHeader>
    </React.Fragment>
  );
};

SectionHeader.defaultProps = {
  defaultStyle: undefined,
  title: '',
  subtitle: '',
  isEditing: false,
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  defaultStyle: PropTypes.object,
  isEditing: PropTypes.bool,
};

function isBackgroundInsteadOfColor(bgColor) {
  return bgColor && bgColor.indexOf('gradient') !== -1;
}

const BaseSection = (props) => {
  const {
    hasHeader,
    className,
    _id,
    isEditing,
    divider,
    type,
    name,
    configs,
    backgroundColor,
    nextColor,
    language,
    languages,
    opacity,
    children,
    title,
    showInMenu,
    subtitle,
    block,
    containerStyle,
    isPreview,
    isFullWidth,
    StyleDropdown,
    styles,
    data,
    autoInjectPrivateKey,
    backgroundImage,
    backgroundImageMaxWidth = 1600,
    design,
    onChange,
    editor,
    hidden,
    condition,
  } = props;
  const { t } = useTranslation();
  const editConfig = configs[type];
  const { fields, dataFields, stringFields } = editConfig || {};

  const handleChange = (key, value) => {
    onChange(_id, key, value);
  };

  const getBackground = () => {
    // TODO: use styles on section id (or StyledComponents) and media queries ?
    if (!backgroundImage) return null;
    return {
      backgroundImage:
        backgroundImage &&
        `url(${Images.maxWidth(backgroundImage, backgroundImageMaxWidth, { type: 'auto' })})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    };
  };

  const resolveColor = (color) => {
    return color === '@primaryColor' ? design.primaryColor : color;
  };

  const bgColor = resolveColor(backgroundColor);
  const shapeColor = resolveColor(nextColor) || 'rgb(250, 250, 250)';
  const hasBackground = isBackgroundInsteadOfColor(bgColor);
  const pageBackgroundColor = design.backgroundColor;
  const visibleBackgroundColor = bgColor === '#00000000' ? pageBackgroundColor : bgColor;
  const isDarkColor = visibleBackgroundColor && extractColorLuma(visibleBackgroundColor) < 140;
  const backgroundStyle = getBackground();

  const handleAdd = (duplicatedBlock) => {
    // eslint-disable-next-line no-restricted-globals
    window.debugTemplate = duplicatedBlock;
    editor.addSection(duplicatedBlock, 'duplicate');
  };

  return (
    <EditContext.Provider value={{ language, languages, onChange: handleChange }}>
      <section
        className={cx(className, `section--${type}`, {
          'is-full-width': isFullWidth,
          'section--dark': isDarkColor || !!backgroundStyle,
          'pt-5': styles?.spacingTop === 'half',
          'pt-0': styles?.spacingTop === 'none',
          'pb-5': styles?.spacingBottom === 'half',
          'pb-0': styles?.spacingBottom === 'none',
        })}
        id={isPreview ? `preview-${_id}` : `section-${_id}`}
        style={{
          position: 'relative',
          width: '100%',
          // overflow: "hidden",
          background: hasBackground ? bgColor : undefined,
          backgroundColor: hasBackground ? undefined : bgColor,
          ...backgroundStyle,
          ...containerStyle,
        }}
      >
        {!isPreview && (
          <div id={`anchor-${slugify(name)}`} style={{ position: 'absolute', top: -50 }} />
        )}
        {!isPreview && showInMenu?.enabled && title && (
          <div id={slugify(stripHtml(title))} style={{ position: 'absolute', top: -50 }} />
        )}
        {!!opacity && <div className="translucent" style={{ opacity }} />}
        <div style={{ position: 'absolute', top: 8, right: 8 }}>
          {isEditing && editConfig && StyleDropdown && (
            <div className="options-container">
              {condition && (
                <div className="condition">
                  {condition.version === '2' ? (
                    <code>
                      <i className="fa fa-lock" style={{ marginRight: '0.5em' }} />
                      {convertToText(condition)}
                    </code>
                  ) : (
                    generateConditionLabel(condition)
                  )}
                </div>
              )}
              <Button
                title={t('generic.settings.duplicate-section')}
                className={cx('hide-button', hidden ? 'hidden' : '')}
                icon={!hidden ? 'eye' : 'eye-slash'}
                onClick={() => handleChange('hidden', !hidden)}
              />
              <Button
                title={t('generic.settings.duplicate-section')}
                className="duplicate-button"
                icon="clone"
                onClick={() => handleAdd(block)}
              />
              {dataFields && (
                <StyleDropdown
                  id={_id}
                  fields={dataFields}
                  value={block}
                  language={language}
                  languages={languages}
                  data={{ ...(data || {}), autoInjectPrivateKey }}
                  icon="table"
                  title={t('generic.style-dropdown.title.data')}
                />
              )}
              {fields && (
                <StyleDropdown
                  id={_id}
                  fields={fields}
                  value={block}
                  language={language}
                  languages={languages}
                  data={{ ...(data || {}), autoInjectPrivateKey }}
                  icon="palette"
                  title={t('generic.style-dropdown.title.styles')}
                />
              )}
              {stringFields && (
                <StyleDropdown
                  id={_id}
                  fields={stringFields}
                  value={block}
                  language={language}
                  languages={languages}
                  data={{ ...(data || {}), autoInjectPrivateKey }}
                  icon="font"
                  title={t('generic.style-dropdown.title.text')}
                />
              )}
            </div>
          )}
        </div>
        {hasHeader && (
          <div className="container">
            <SectionHeader
              title={title}
              subtitle={subtitle}
              defaultStyle={{ textAlign: 'center' }}
              isEditing={isEditing}
            />
            {children}
          </div>
        )}
        {!hasHeader && children}
        {divider && <Divider {...divider} color={shapeColor} style={globalStyles.bottom} />}
      </section>
    </EditContext.Provider>
  );
};

BaseSection.defaultProps = {
  hasHeader: true,
  className: 'section',
  name: '',
};

const BaseSectionWithContext = withConfigs(withEdit(withDesign(BaseSection)));
BaseSectionWithContext.Header = SectionHeader;

export default BaseSectionWithContext;
