/* eslint-disable  no-restricted-globals */

import merge from 'lodash/merge';
import { useState } from 'react';
import genericVariables from '../../design-system/variables.generic.json';
import lightVariables from '../../design-system/variables.light.json';
import {
  CSSVariables,
  Fonts,
  generateClass,
  generateFonts,
  generateVariables,
} from './designSystemUtils';

export enum Theme {
  LIGHT = 'light',
  // DARK = 'dark',
  // APPCRAFT = 'appcraft',
}

export interface GlobalStyleOptions extends Fonts {
  additionalVariables?: CSSVariables;
}

export interface DesignSystem {
  theme: Theme;
  generateGlobalStyle: ({
    additionalVariables,
    bodyFont,
    customFont,
  }: GlobalStyleOptions) => string;
  changeTheme: (theme: Theme) => void;
}

const COLORS_BRIGHTNESS = {
  darker: -30,
  dark: -15,
  light: 15,
  lighter: 30,
};

const COLORS_OPACITIES = [0.2, 0.5, 0.7];

const GENERIC_CLASS = {
  // 'margin':
  ma: 'margin: {size};',
  mx: 'margin: 0 {size};',
  my: 'margin: {size} 0;',
  mr: 'margin-right: {size};',
  ml: 'margin-left: {size};',
  mt: 'margin-top: {size};',
  mb: 'margin-bottom: {size};',
  // 'padding':
  pa: 'padding: {size};',
  px: 'padding: 0 {size};',
  py: 'padding: {size} 0;',
  pr: 'padding-right: {size};',
  pl: 'padding-left: {size};',
  pt: 'padding-top: {size};',
  pb: 'padding-bottom: {size};',
  // 'border-radius':
  br: 'border-radius: {size};',
  // 'font-size':
  fs: 'font-size: {size};',
};

const themeVariables: Record<Theme, CSSVariables> = {
  light: lightVariables,
};

const BODY_FONT_RULES = [
  'body',
  'button',
  'input',
  'select',
  'textarea',
  '.section',
  '.footer',
  '.navbar',
];

export const useDesignSystem = (initialTheme?: Theme): DesignSystem => {
  const [theme, setTheme] = useState(initialTheme || Theme.LIGHT);

  const generateGlobalStyle = ({
    additionalVariables,
    bodyFont,
    customFont,
  }: GlobalStyleOptions) => {
    const designVariables = merge(
      {},
      genericVariables,
      themeVariables[theme] ?? lightVariables,
      additionalVariables,
    );

    const generatedVariables = generateVariables(designVariables, {
      opacities: COLORS_OPACITIES,
      brightnesses: COLORS_BRIGHTNESS,
    });
    const generatedClass = generateClass(GENERIC_CLASS, designVariables);
    const generatedFonts = generateFonts({
      classNames: BODY_FONT_RULES.join(', '),
      bodyFont,
      customFont,
      designFont: designVariables.font,
    });

    return `${generatedFonts}
    ${generatedVariables}
    ${generatedClass}`;
  };

  const changeTheme = (selectedTheme: Theme) => {
    if (selectedTheme === theme) return;
    setTheme(selectedTheme);
  };

  return { changeTheme, theme, generateGlobalStyle };
};
