/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component, PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import get from 'lodash/get';
import RichText from '../components/RichText';
import BaseSection from './BaseSection';
import { replaceValues } from './OpportunitiesSection';
import { withAppContext } from '../Context';
import Files from '../components/FilesList';
import { NavLinkWithParams } from '../components';

const Container = ({ name, showLabel, children }) => {
  if (!showLabel) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <div className="field--details">
      <p style={{ margin: 0, fontWeight: 'bold', marginRight: 10, minWidth: 200 }}>{name} :</p>
      {children}
    </div>
  );
};

class Section extends PureComponent {
  renderField = (field, item) => {
    const { pageId, data } = this.props;
    const { type, key, className = '', template = false } = field;
    const value = template ? replaceValues(key, item) : get(item, key);
    if (type !== 'extra-data' && !value) return null;
    switch (type) {
      case 'text':
        return <RichText key={key} html={value} />;
      case 'link':
        return (
          <a href={value} target="_blank" rel="noopener noreferrer">
            <RichText key={key} html={value} />
          </a>
        );
      case 'tags':
        return (
          <React.Fragment>
            <div className="tags" key={key}>
              {(value || []).map((v) => (
                <span
                  className={cx('tag is-medium', className)}
                  style={{ fontWeight: 'bold', maxWidth: '-webkit-fill-available' }}
                >
                  {v}
                </span>
              ))}
            </div>
          </React.Fragment>
        );
      case 'video': {
        const uri = get(value, 'uri');
        if (!uri) return null;
        return (
          <video allowFullScreen controls>
            <source src={uri} type="video/mp4" />
          </video>
        );
      }
      case 'files': {
        return <Files files={value || []} />;
      }
      case 'extra-data': {
        const { extraDataLabel, extraId, extraFields = [] } = field;
        const extraData =
          (data[extraDataLabel] || []).filter((d) => extraId && d[extraId] === pageId) || [];
        return (
          <div style={{ marginTop: 10 }} className="columns is-desktop is-multiline">
            {extraData.map((d) => {
              const { _id } = d;
              return (
                <NavLinkWithParams
                  to={`/opportunites/${_id}`}
                  class="column is-3 box c-opportunity"
                  style={{ margin: 4, cursor: 'pointer' }}
                  key={_id}
                >
                  <div className="border" />
                  {extraFields.map((f) => {
                    // eslint-disable-next-line no-shadow
                    const { key, style, icon, template } = f;
                    const text = template ? replaceValues(key, d) : get(d, key);
                    return (
                      <div key={key} style={style}>
                        {icon && <i className={`fa fa-fw fa-${icon}`} />} {text}
                      </div>
                    );
                  })}
                </NavLinkWithParams>
              );
            })}
          </div>
        );
      }
      default:
        return null;
    }
  };

  getExtraDataValue = (field) => {
    const { data, pageId } = this.props;
    const { extraDataLabel, extraId } = field;
    return (data[extraDataLabel] || []).filter((d) => extraId && d[extraId] === pageId) || [];
  };

  hasValue = (item, fields) => {
    const firstField = fields.length > 0 && fields[0];
    const value = firstField && get(item, firstField.key);
    if (!value && firstField.type !== 'extra-data') return false;
    if (firstField && firstField.type === 'extra-data') {
      const extraData = this.getExtraDataValue(firstField);
      if (extraData.length === 0) return false;
    }
    return true;
  };

  render() {
    const { title, icon, fields = [], item = {}, isLast = false } = this.props;
    const value = this.hasValue(item, fields);
    if (!value) return null;

    return (
      <React.Fragment>
        {!!title && (
          <h2 className="title is-3 section-title">
            <i className={`fa fa-fw fa-${icon}`} />
            {title}
          </h2>
        )}
        {fields.map((f) => (
          <Container {...f}>{this.renderField(f, item)}</Container>
        ))}
        {!isLast && <hr />}
      </React.Fragment>
    );
  }
}

export function getImage(image) {
  if (!image) return null;
  if (typeof image === 'string') return image;
  return image.uri;
}

class OpportunityDetailsSection extends Component {
  renderHeader = (item) => {
    const { sections = [] } = this.props;
    const section = sections.find((s) => s.type === 'header');
    if (!section) return null;
    const { fields = [] } = section;
    const image = fields.find((f) => f.type === 'image');
    const link = fields.find((f) => f.type === 'link');

    return (
      <div className="box header">
        <div className="left-border" />
        {image && item[image.key] && (
          <a
            href={link && item[link.key] && item[link.key]}
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: link ? 'pointer' : 'default' }}
          >
            <img src={getImage(item[image.key])} alt="companyLogo" style={{ maxHeight: 70 }} />
          </a>
        )}
        <div style={{ flex: 1, marginLeft: 10 }}>
          {fields
            .filter((f) => f.type === 'text')
            .map((f) => {
              const { key, className, icon, template } = f;
              const value = template ? replaceValues(key, item) : get(item, key);
              return (
                <h2 key={key} className={cx('title is-3', className)}>
                  {icon && <i className={`fa fa-fw fa-${icon}`} style={{ fontSize: 20 }} />} {value}
                </h2>
              );
            })}
        </div>
      </div>
    );
  };

  render() {
    const { sections = [], back = '/', dataLabel, data, match, ...props } = this.props;
    const items = data[dataLabel] || [];
    const item = items.find((d) => d._id === match.params.id);
    if (!item) return null;
    const detailsSections = sections.filter((s) => s.type !== 'header');

    return (
      <BaseSection {...props} block={this.props}>
        <div className="container c-opportunity-details" style={{ margin: '20px auto' }}>
          <NavLinkWithParams
            to={back}
            className="back-button"
            style={{ fontWeight: 'bold', fontSize: 20 }}
          >
            <i className="fa fa-fw fa-chevron-left" style={{ fontSize: 20 }} /> Retour
          </NavLinkWithParams>
          {this.renderHeader(item)}
          <div className="box">
            {detailsSections.map((s, index) => (
              <Section
                {...s}
                item={item}
                data={data}
                isLast={index === detailsSections.length - 1}
                pageId={match.params.id}
              />
            ))}
          </div>
          <div />
        </div>
      </BaseSection>
    );
  }
}

export default withAppContext(withRouter(OpportunityDetailsSection));
