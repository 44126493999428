import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import get from 'lodash/get';
import flatten from 'lodash/flatten';

export function toggleFilter(filters, key, value) {
  if (filters[key] === value) {
    return omit(filters, key);
  }
  return { ...filters, [key]: value };
}

function extractValues(data, key) {
  const values = uniq(
    flatten(
      data.map((d) => {
        const v = d[key];
        if (!v) return [];
        if (Array.isArray(v)) return v;

        return [v];
      }),
    ),
  );
  return orderBy(values);
}

function dayOfDate(date) {
  if (!date) return date;
  return date.replace(' ', 'T').split('T')[0];
}

function extractDateValues(data, key) {
  const values = uniq(data.map((d) => dayOfDate(d[key])))
    .filter((v) => v)
    .sort();
  return orderBy(values);
}

// eslint-disable-next-line import/prefer-default-export
export function generateFilters(data, filters) {
  return filters.map((filter) => {
    const { type = 'simple' } = filter;
    if (type === 'simple' && !filter.values) {
      return {
        ...filter,
        values: extractValues(data, filter.dataKey),
      };
    }
    if (type === 'date' && !filter.values) {
      return {
        ...filter,
        values: extractDateValues(data, filter.dataKey),
      };
    }
    return filter;
  });
}

function stringValue(value) {
  if (!value) return value;
  if (typeof value !== 'string') return `${value}`;
  return value;
}

function hasSearchValue(item, valueFilter, fields) {
  // eslint-disable-next-line no-restricted-syntax
  for (const col of fields) {
    const value = get(item, col);
    if (value && stringValue(value).toLowerCase().indexOf(valueFilter) !== -1) {
      return true;
    }
  }

  return false;
}

export function applySearchFilter(data, filterValue, fields) {
  if (!filterValue) return data;

  const words = filterValue
    .split(' ')
    .map((v) => v.trim().toLowerCase())
    .filter((v) => !!v);
  if (words.length === 0) return data;

  return data.filter((item) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const word of words) {
      if (!hasSearchValue(item, word, fields)) return false;
    }
    return true;
  });
}

function customMatch(filters, item) {
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in filters) {
    const value = filters[key];
    const itemValue = item[key];
    if (value && !itemValue) return false;
    if (Array.isArray(itemValue)) {
      if (!itemValue.includes(value)) return false;
      // eslint-disable-next-line eqeqeq
    } else if (itemValue != value) {
      return false;
    }
  }
  return true;
}

export function applyFilters(data, filters, filterList) {
  let filteredData = data;
  let remainingFilters = filters;
  const searchField = filterList && filterList.find((filter) => filter.type === 'search');
  if (searchField && filters[searchField.dataKey]) {
    remainingFilters = omit(remainingFilters, searchField.dataKey);
    filteredData = applySearchFilter(
      filteredData,
      filters[searchField.dataKey],
      searchField.fields,
    );
  }
  // Date filters
  const dateFields = (filterList || []).filter((filter) => filter.type === 'date');
  for (const field of dateFields) {
    const { dataKey } = field;
    const value = filters[dataKey];
    remainingFilters = omit(remainingFilters, dataKey);
    if (value) {
      filteredData = filteredData.filter((item) => value === dayOfDate(item[dataKey]));
    }
  }

  return filteredData.filter((item) => customMatch(remainingFilters, item));
}
