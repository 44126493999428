const FONT_EXTENSIONS = {
  '.ttf': 'truetype',
  '.otf': 'opentype',
  '.woff': 'woff',
  '.woff2': 'woff2',
};

export const extension = (filename: string): string => {
  const matches = filename.match(/\.[0-9a-z]+$/i);
  if (matches) return matches[0];
  return '';
};

export const fontFormatFromName = (filename: string): string => {
  const ext = extension(filename) as keyof typeof FONT_EXTENSIONS;
  return FONT_EXTENSIONS[ext];
};
