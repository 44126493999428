import ApiService from './api.service';

class ContactService extends ApiService {
  postContact(contact: { email: string } & Record<string, unknown>) {
    return this.api()
      .url('/contact')
      .post({
        action: 'send-contact',
        contact,
      })
      .json();
  }
}

export default new ContactService();
