import React, { FC, Fragment } from 'react';
import './LoginPage.scss';
// import { useTranslation } from 'react-i18next';
import { useMount, useSetState } from 'react-use';
import { Image } from '../../components';
import Loader from '../../components/Loader';
import authService from '../../services/auth.service';
import { ConnectionMethod, LoginRes } from '../../types/connection.types';
import { Site } from '../../types/site.types';
import { getParameterByName } from '../../utils';
import { useSiteTranslation } from '../../utils/translations';
import MagicLinkConnection from './components/MagicLinkConnection';
import PasswordConnection from './components/PasswordConnection';
import SsoConnection from './components/SsoConnection';
import { errorMsg } from './login.utils';

type LoginPageProps = {
  site: Site;
  onLogin: (loginRes: LoginRes) => void;
};

// login+password
// sso
// magic-link

const loginMethods: Record<
  string,
  FC<{
    onLogin: (loginRes: LoginRes) => void;
    onError: (error: string | null) => void;
    config: ConnectionMethod;
  }>
> = {
  password: PasswordConnection,
  sso: SsoConnection as any,
  'magic-link': MagicLinkConnection as any,
};

const autoLoginToken = getParameterByName('loginToken') || getParameterByName('token');
const errorCode = getParameterByName('code');

const LoginPage: FC<LoginPageProps> = ({ site, onLogin }) => {
  const { t } = useSiteTranslation();
  const { design } = site;
  const [state, setState] = useSetState<{ loading: boolean; error: string | null }>({
    error: errorMsg(errorCode),
    loading: !!autoLoginToken,
  });
  const loginConfig = site.login!;
  const enabledMethods = (loginConfig.connectionMethods || []).filter((m) => m.enabled !== false);

  useMount(async () => {
    if (autoLoginToken) {
      setState({ loading: true });
      console.log('auto-login', autoLoginToken);
      const res = await authService.autoLogin(autoLoginToken);
      console.log('res', res);
      if (res && res.success) {
        await onLogin(res as any);
        setState({
          loading: false,
        });
      } else {
        setState({ loading: false, error: t('site.login.auto-login.error-invalid-token') });
      }
    }
  });

  const setError = (err: string | null) => setState({ error: err });

  const bgImage = loginConfig.design?.bgImage || design?.bgImage;
  const title = (loginConfig.strings?.title || t('site.login.title'))?.trim();

  const { loading, error } = state;
  return (
    <div className="LoginPage" style={{ backgroundImage: bgImage && `url(${bgImage})` }}>
      <div className="container">
        <div className="columns is-centered is-vcentered">
          <div className="column is-5-tablet is-5-desktop is-4-widescreen">
            <div className="box">
              {site.logo && <Image className="logo" src={site.logo} />}
              {title && (
                <h3 className="title is-3" style={{ textAlign: 'center' }}>
                  {title}
                </h3>
              )}
              {loading && <Loader />}
              {!loading && (
                <>
                  <div className="connection-methods">
                    {enabledMethods.map((method, index) => {
                      const Component = loginMethods[method.type];
                      if (!Component) {
                        console.error('Missing method', method.type);
                        return null;
                      }
                      return (
                        <Fragment key={index}>
                          {index !== 0 && (
                            <div className="connection-divider">
                              <span>{t('generic.common.or')}</span>
                            </div>
                          )}
                          <Component
                            key={index}
                            onLogin={onLogin}
                            onError={setError}
                            config={method}
                          />
                        </Fragment>
                      );
                    })}
                  </div>
                  {error && (
                    <article className="message is-danger">
                      <div className="message-body">{error}</div>
                    </article>
                  )}
                </>
              )}
              {/* {loginMethodsByType.password && <PasswordConnection onLogin={onLogin} />}
              {loginMethodsByType.sso && <SsoConnection onLogin={onLogin} />}
              {loginMethodsByType['magic-link'] && <MagicLinkConnection onLogin={onLogin} />} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
