import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { sections, miniSiteBlocks } from './MiniSiteBlocks';
import { Title } from './components';

function getBlockComponent(siteBlocks, type) {
  if (type in sections) return sections[type];
  if (type in siteBlocks) return siteBlocks[type];
  return Title;
}

export default class MiniSiteViewer extends Component {
  renderSiteBlocks = (blocks) => {
    const { isPreview } = this.props;
    if (!blocks) return null;
    return blocks.map((block, index) => {
      const { children, ...props } = block;
      const ComponentToRender = getBlockComponent(miniSiteBlocks, props.type);
      return (
        <ComponentToRender
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          {...props}
          nextColor={(blocks[index + 1] || {}).backgroundColor}
          isPreview={isPreview}
        >
          {this.renderSiteBlocks(children)}
        </ComponentToRender>
      );
    });
  };

  render() {
    const { blocks } = this.props;
    return <div>{this.renderSiteBlocks(blocks)}</div>;
  }
}

MiniSiteViewer.defaultProps = {
  blocks: [],
  isPreview: undefined,
  meta: undefined,
};

MiniSiteViewer.propTypes = {
  blocks: PropTypes.array,
  isPreview: PropTypes.bool,
  meta: PropTypes.object,
};
