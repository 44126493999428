import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Text extends PureComponent {
  render() {
    const { text = '', className, style = {} } = this.props;
    return (
      <div className={className} style={{ ...style }}>
        {text.split('\n').map((t, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={i}>{t}</p>
        ))}
      </div>
    );
  }
}

Text.defaultProps = {
  className: '',
  text: '',
  style: undefined,
};

Text.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
};
