import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { avatarPlaceholder } from '../assets/avatarPlaceholder';
import { Card, Media } from '../bulma';
import { imageProps as bulmaImageProps } from '../bulma/propTypes';
import { replaceDataValues } from '../utils';
import Images from '../utils/Images';
import './HorizontalCardItem.scss';

export class HorizontalCardItem extends React.PureComponent {
  render() {
    const {
      className,
      textAlign,
      item,
      imageField,
      imageProps,
      imagePlaceholder,
      titleTemplate,
      subtitleTemplate,
      descriptionTemplate,
      linkField,
      linkLabelTemplate,
    } = this.props;
    const title = replaceDataValues(titleTemplate, item).trim();
    const subtitle = replaceDataValues(subtitleTemplate, item).trim();
    const description = replaceDataValues(descriptionTemplate, item).trim();
    const image = imageField && item[imageField];
    const link = linkField && item[linkField];
    const linkLabel = linkLabelTemplate && replaceDataValues(linkLabelTemplate, item).trim();
    return (
      <Card className={cx('card--horizontal', className)}>
        <Card.Content>
          <Media
            imageLeft={Images.maxWidth(image || (imagePlaceholder && avatarPlaceholder), 300, {
              type: 'auto',
            })}
            imageProps={imageProps}
            title={title}
            subtitle={subtitle}
            socialNetworks={item.socialNetworks}
            description={description}
            textStyle={{ textAlign }}
            link={link}
            linkLabel={linkLabel}
          />
        </Card.Content>
      </Card>
    );
  }
}

export const MediaItem = (props) => <HorizontalCardItem {...props} className="card--none" />;

HorizontalCardItem.defaultProps = {
  textAlign: 'center',
  className: '',
  imageField: undefined,
  imageProps: undefined,
  titleTemplate: '',
  subtitleTemplate: '',
  descriptionTemplate: '',
  imagePlaceholder: false,
};

HorizontalCardItem.propTypes = {
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  imageField: PropTypes.string,
  imageProps: bulmaImageProps,
  titleTemplate: PropTypes.string,
  subtitleTemplate: PropTypes.string,
  descriptionTemplate: PropTypes.string,
  imagePlaceholder: PropTypes.bool,
};
