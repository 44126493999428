/* eslint-disable no-restricted-globals */
import get from 'lodash/get';
import { useContext, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { AppContext } from '../Context';

export const useCurrentSitePage = () => {
  const location = useLocation();

  const { site } = useContext(AppContext);

  const currentPage = useMemo(
    () =>
      site.pages.find((page) =>
        matchPath(location.pathname, {
          path: page.link,
          exact: true,
        }),
      ) || site.pages[0],
    [location.pathname, site.pages],
  );
  return currentPage;
};

function rootURLWindowFallback() {
  // eslint-disable-next-line no-restricted-globals
  return typeof window !== 'undefined' ? window.location.origin : '';
}

export const useRootURL = () => {
  const { rooturl } = useContext(AppContext);
  return rooturl ?? rootURLWindowFallback();
};

function currentURLWindowFallback() {
  // eslint-disable-next-line no-restricted-globals
  return typeof window !== 'undefined' ? window.location.href : '';
}

export function getCurrentPath(rooturl, page, lang) {
  const pageLink = get(page, `page.translations.${lang.name}.link`, page.link);
  const pagePath = `${lang.name || 'fr'}${pageLink}`;
  let url = `${rooturl}/${pagePath.replace(/\/\//g, '/')}`;
  if (window.location.search) {
    url += `${window.location.search}`;
  }
  return url;
}

export const useCurrentPath = (page, language) => {
  const rootURL = useRootURL();
  const url = useMemo(() => {
    if (!page) return currentURLWindowFallback();
    return getCurrentPath(rootURL, page, { name: language });
  }, [page, rootURL, language]);
  return url;
};
