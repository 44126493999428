/* eslint-disable operator-linebreak */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../../../Context';
import Menu from '../components/Menu';

const SearchFilter = ({ filter, value, onSelect }) => {
  const { lang = 'fr' } = useContext(AppContext);
  return (
    <Menu.Item className="menu-filter--search">
      <input
        className="input"
        placeholder={lang === 'fr' ? 'rechercher' : 'search'}
        value={value || ''}
        // eslint-disable-next-line no-shadow
        onChange={(e) => onSelect(filter, e.target.value)}
        icon={
          value
            ? {
                name: 'close',
                style: { cursor: 'pointer', pointerEvents: 'all' },
                onClick: () => onSelect(filter, undefined),
              }
            : undefined
        }
      />
    </Menu.Item>
  );
};

SearchFilter.defaultProps = {
  value: undefined,
};

SearchFilter.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    placeholder: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default SearchFilter;
