import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Menu from '../components/Menu';
import { AppContext } from '../../../Context';
import { formatForLang } from '../../../workshops/utils';

const DateFilter = ({ filter, value, onSelect }) => {
  const { title, values, showAll, dateFormat = 'PPP' } = filter;
  const { lang = 'fr' } = useContext(AppContext);
  if (!values) return null;
  return (
    <Menu.Item className="menu-filter--date">
      <Menu.Header>{title}</Menu.Header>

      <Menu.Menu>
        {showAll && (
          <Menu.Menu.Item name="" active={!value} onClick={() => onSelect(filter, undefined)}>
            {lang === 'fr' ? 'Tous' : 'All'}
          </Menu.Menu.Item>
        )}
        {values.map((v) => (
          <Menu.Menu.Item
            key={v}
            name={formatForLang(v, dateFormat, { lang })}
            active={value === v}
            onClick={() => onSelect(filter, v)}
          />
        ))}
      </Menu.Menu>
    </Menu.Item>
  );
};

DateFilter.defaultProps = {
  value: undefined,
};

DateFilter.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    dateFormat: PropTypes.string,
    values: PropTypes.array,
    showAll: PropTypes.bool,
  }).isRequired,
};

export default DateFilter;
