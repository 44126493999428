import React, { PureComponent } from 'react';

export default class Hero extends PureComponent {
  render() {
    const { backgroundColor, backgroundImage, opacity, size, children } = this.props;
    return (
      <section
        className={`hero is-${size}`}
        style={{
          position: 'relative',
          width: '100%',
          // overflow: "hidden",
          backgroundColor,
          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="translucent" style={{ opacity }} />
        <div className="hero-body" style={{ paddingBottom: 0 }}>
          <div className="container">{children}</div>
        </div>
      </section>
    );
  }
}
