import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import orderBy from 'lodash/orderBy';
import scrollIntoView from 'scroll-into-view-if-needed';
import Images from '../utils/Images';
import anonymousAvatar from '../assets/anonymous-avatar.jpg';

const alphabet = 'abcdefghijklmnopqrstuvwxyz';

function nameOf(user) {
  return `${user.lastName || ''} ${user.firstName || ''}`.trim().toLowerCase();
}

function firstLetterOf(user) {
  return nameOf(user)[0] || '';
}

class Speaker extends PureComponent {
  getThumbnail(thumbnail) {
    // TODO: use Images.square
    if (!thumbnail) return anonymousAvatar;
    return Images.square(thumbnail, 400);
  }

  render() {
    const {
      firstName,
      lastName,
      thumbnail,
      extraField,
      isFirst,
      [extraField]: extraData,
    } = this.props;
    return (
      <div className="container-speaker">
        {/* place id marker 50px above to fix scroll position issues */}
        {isFirst && (
          <div
            id={`speaker-${firstLetterOf(this.props)}`}
            style={{ position: 'absolute', top: -54 }}
          />
        )}
        <div className="ms-speakers__card">
          <div
            className="ms-speaker__card__image"
            style={{ backgroundImage: `url(${this.getThumbnail(thumbnail)})` }}
          />
          <div className="ms-speaker__card__section">
            <div className="ms-speakers__title">
              {(lastName || '').toUpperCase()}
              <br />
              {firstName}
            </div>
            {extraField && extraData && <div className="ms-speakers__details">{extraData}</div>}
          </div>
        </div>
      </div>
    );
  }
}

Speaker.defaultProps = {
  firstName: '',
  lastName: '',
  thumbnail: undefined,
  extraField: '',
  isFirst: false,
};

Speaker.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  thumbnail: PropTypes.object,
  extraField: PropTypes.string,
  isFirst: PropTypes.bool,
};

class AlphabetScroller extends React.Component {
  scrollTo = (letter) => {
    // eslint-disable-next-line no-restricted-globals
    const node = document.getElementById(`speaker-${letter}`);
    if (node) {
      scrollIntoView(node, {
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  };

  render() {
    const { letterIndex, alphabetColor } = this.props;
    return (
      <div className="alphabet">
        {alphabet.split('').map((l) => (
          <button
            type="button"
            key={l}
            className="alphabet-letter"
            disabled={!(l in letterIndex)}
            onClick={() => this.scrollTo(l)}
            style={{ color: alphabetColor }}
          >
            {l}
          </button>
        ))}
      </div>
    );
  }
}

AlphabetScroller.defaultProps = {
  alphabetColor: '',
  letterIndex: undefined,
};

AlphabetScroller.propTypes = {
  alphabetColor: PropTypes.string,
  letterIndex: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default class Speakers extends PureComponent {
  computeLetterIndex = (speakers) => {
    const letterIndex = {};
    speakers.forEach((speaker, index) => {
      const letter = firstLetterOf(speaker);
      if (!(letter in letterIndex)) {
        letterIndex[letter] = index;
      }
    });
    return letterIndex;
  };

  render() {
    const { speakers, showAlphabet, alphabetColor, maxGridSize, extraField } = this.props;
    const sortedSpeakers = orderBy(speakers, nameOf, 'asc');
    const letterIndex = this.computeLetterIndex(sortedSpeakers);
    return (
      <div>
        {showAlphabet && (
          <AlphabetScroller alphabetColor={alphabetColor} letterIndex={letterIndex} />
        )}
        <div className={`speaker-grid max-${maxGridSize}`}>
          {sortedSpeakers.map((speaker, index) => {
            const isFirst = index === letterIndex[firstLetterOf(speaker)];
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Speaker key={`${index}`} {...speaker} isFirst={isFirst} extraField={extraField} />
            );
          })}
          <div style={{ flex: 1 }} />
        </div>
      </div>
    );
  }
}

Speakers.defaultProps = {
  alphabetColor: '',
  extraField: '',
  maxGridSize: '',
  speakers: [],
  showAlphabet: false,
};

Speakers.propTypes = {
  alphabetColor: PropTypes.string,
  extraField: PropTypes.string,
  maxGridSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  speakers: PropTypes.arrayOf(PropTypes.object),
  showAlphabet: PropTypes.bool,
};
