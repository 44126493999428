/* eslint-disable react/no-danger */
import noop from 'lodash/noop';
import React, { useState } from 'react';
/* eslint-disable import/no-unresolved */
import Collapsible from 'react-collapsible';

import './Accordion.scss';
import { workshopsDefaultPropTypes, workshopsPropTypes } from './workshop-proptypes';

import Speakers from '../Speakers';

import { Icon } from '../../components';
import { getValue } from '../utils';

// eslint-disable-next-line import/prefer-default-export
export const Accordion = (props) => {
  const { workshops, workshopConfig } = props;
  const { showSpeakers = false } = workshopConfig;
  const [selectedId, setSelectedId] = useState(undefined);
  return (
    <div>
      {workshops.map((workshop) => {
        const { _id, title, description, speakers } = workshop;
        const isSelected = selectedId === _id;
        const time = getValue(workshop, 'startEndTime');
        return (
          <Collapsible
            key={_id}
            open={isSelected}
            className="Accordion"
            trigger={
              <div className="Accordion__trigger">
                <div className="info">
                  <div className="time">{time}</div>
                  <div className="title">{title}</div>
                </div>
                <Icon icon={`chevron-${!isSelected ? 'down' : 'up'}`} className="icon" />
              </div>
            }
            onOpening={() => setSelectedId(_id)}
            onClosing={() => (isSelected ? setSelectedId(undefined) : noop)}
          >
            <div className="Accordion__details">
              <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
              <Speakers speakers={speakers} showSpeakers={showSpeakers} template="list" />
            </div>
          </Collapsible>
        );
      })}
    </div>
  );
};

Accordion.defaultProps = workshopsDefaultPropTypes;
Accordion.propTypes = workshopsPropTypes;
