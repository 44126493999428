import PropTypes from 'prop-types';
import React from 'react';
import { avatarPlaceholder } from '../assets/avatarPlaceholder';
import { Card, Media } from '../bulma';
import { imageProps as bulmaImageProps } from '../bulma/propTypes';
import { replaceDataValues } from '../utils';
import Images from '../utils/Images';
import TextOrHtml from '../components/TextOrHtml';

// eslint-disable-next-line import/prefer-default-export
export class CardItem extends React.PureComponent {
  render() {
    const {
      item,
      textAlign,
      imageField,
      imageProps,
      imagePlaceholder,
      titleTemplate,
      subtitleTemplate,
      descriptionTemplate,
      linkField,
      linkLabelTemplate,
    } = this.props;
    const title = replaceDataValues(titleTemplate, item).trim();
    const subtitle = replaceDataValues(subtitleTemplate, item).trim();
    const description = replaceDataValues(descriptionTemplate, item).trim();
    const image = imageField && item[imageField];
    const link = linkField && item[linkField];
    const linkLabel = linkLabelTemplate && replaceDataValues(linkLabelTemplate, item).trim();
    return (
      <Card>
        <Card.Image
          {...Images.srcSet.maxWidth(image || (imagePlaceholder && avatarPlaceholder), 500, {
            type: 'auto',
          })}
          alt={title}
          loading="lazy"
          {...(image ? imageProps : imagePlaceholder && imageProps)}
        />
        <Card.Content style={{ textAlign }}>
          <Media
            title={title}
            subtitle={subtitle}
            socialNetworks={item.socialNetworks}
            textStyle={{ textAlign }}
          />
          {description && (
            <div className="content">
              <TextOrHtml text={description} />
            </div>
          )}
        </Card.Content>
        {link && (
          <Card.Content>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {linkLabel || link}
            </a>
          </Card.Content>
        )}
      </Card>
    );
  }
}

CardItem.defaultProps = {
  imageField: undefined,
  imageProps: undefined,
  textAlign: undefined,
  linkLabelTemplate: undefined,
  titleTemplate: '',
  subtitleTemplate: '',
  descriptionTemplate: '',
  linkField: '',
  imagePlaceholder: false,
};

CardItem.propTypes = {
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  item: PropTypes.object.isRequired,
  imageField: PropTypes.string,
  imageProps: bulmaImageProps,
  titleTemplate: PropTypes.string,
  subtitleTemplate: PropTypes.string,
  descriptionTemplate: PropTypes.string,
  linkField: PropTypes.string,
  linkLabelTemplate: PropTypes.string,
  imagePlaceholder: PropTypes.bool,
};
