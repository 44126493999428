import cx from 'classnames';
import React, { FC } from 'react';

type FormInputProps = {
  label?: string | null;
  name: string;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  value?: string;
  type?: string;
  icon?: string;
  size?: 'small' | 'normal' | 'medium' | 'large';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
};

const FormInput: FC<FormInputProps> = ({
  className,
  value,
  onChange,
  icon,
  type = 'text',
  disabled = false,
  size = 'normal',
  placeholder,
  label,
  name,
  hasError = false,
}) => (
  <div className={cx('field', className)}>
    {label && (
      <label className="label" htmlFor={name}>
        {label}
      </label>
    )}
    <div className={cx('control', icon && 'has-icons-left')}>
      <input
        className={cx('input', size && `is-${size}`, hasError && 'is-danger')}
        value={value || ''}
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
      />
      {icon && (
        <span className="icon is-small is-left">
          <i className={`fa ${icon}`} />
        </span>
      )}
    </div>
  </div>
);

export default FormInput;
