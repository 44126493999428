import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const verticalAlignStyles = { top: 'flex-start', bottom: 'flex-end' };

export default class Columns extends PureComponent {
  render() {
    const {
      style,
      children,
      isDesktop = false,
      isCentered = false,
      isGapless,
      isMultiLine,
      centerVertically,
      verticalAlign,
      className,
    } = this.props;
    return (
      <div
        className={cx(
          'columns',
          {
            'is-multiline': isMultiLine,
            'is-desktop': isDesktop,
            'is-vcentered': centerVertically || verticalAlign === 'center',
            'is-centered': isCentered,
            'is-gapless': isGapless,
          },
          className,
        )}
        style={{ ...style, alignItems: verticalAlignStyles[verticalAlign] }}
      >
        {children}
      </div>
    );
  }
}

Columns.defaultProps = {
  centerVertically: false,
  children: undefined,
  isCentered: false,
  isDesktop: false,
  isGapless: false,
  isMultiLine: false,
  className: undefined,
  verticalAlign: undefined,
  style: {},
};

Columns.propTypes = {
  centerVertically: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  isCentered: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isGapless: PropTypes.bool,
  isMultiLine: PropTypes.bool,
  verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  style: PropTypes.object,
};
