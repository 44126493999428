import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import './icon.scss';
import { withComponents } from './Context';
import { iconProps } from '../utils/propTypes';

const Icon = ({ name, icon, shape, style, onClick, components: { FAIcon } }) => (
  <div className={cx('c-icon', `c-icon--${shape}`)} style={style} onClick={onClick}>
    <FAIcon name={name} icon={icon} />
  </div>
);

Icon.defaultProps = {
  icon: undefined,
  shape: 'normal',
  style: undefined,
  name: undefined,
  onClick: undefined,
};

Icon.propTypes = {
  components: PropTypes.shape({
    FAIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: iconProps,
  name: PropTypes.string,
  onClick: PropTypes.func,
  shape: PropTypes.oneOf(['circle', 'bubble', 'diamond', 'hexa', 'normal', 'square']),
  style: PropTypes.object,
};

export default withComponents(Icon);
