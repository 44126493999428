/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './workshops-section.scss';
import flatten from 'lodash/flatten';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import 'moment/locale/fr';
import cx from 'classnames';
import orderBy from 'lodash/orderBy';
import BaseSection from './BaseSection';
import * as templates from '../workshops/templates';
import { withAppContext } from '../Context';
import MenuFilter from '../components/MenuFilter';
import { Column, Columns } from '../components';
import { applyFilters, generateFilters } from '../components/MenuFilter/utils';

// function parseDateTime(stringDate) {
//   return new Date(stringDate).getTime();
// }

// function filterByRange(a, b) {
//   return (
//     parseDateTime(b.startDate) > parseDateTime(a.startDate) &&
//     parseDateTime(b.endDate) < parseDateTime(a.endDate)
//   );
// }

// function applyFilter(workshop, filter) {
//   const { key, type, value } = filter;
//   if (!value) return true;
//   const dataValue = get(workshop, key);
//   if (type === 'date') {
//     // ! TODO: gérer ça autrement ???
//     const [startDate, endDate] = value.split('|');
//     const filterDate = { startDate, endDate };
//     return filterByRange(filterDate, workshop);
//   }
//   return dataValue === value;
// }

function groupWorkshopsByField(workshops, field, groupConfig = {}) {
  const orderedWorkshops = orderBy(workshops, ['startDate', 'endDate'], ['asc', 'asc']);
  if (field === 'startDay') {
    return groupBy(orderBy(orderedWorkshops.filter((w) => !!w.startDate)), ({ startDate }) =>
      moment(startDate)
        .locale(groupConfig.lang || 'fr')
        .startOf('day')
        .format(groupConfig.format || 'YYYY-MM-DD'),
    );
  }
  return groupBy(orderedWorkshops, field);
}

function orderWorkshop(workshops) {
  return orderBy(workshops, ['startDate', 'endDate'], ['asc', 'asc']);
}

class WorkshopsSection extends PureComponent {
  state = { filterValue: {}, selectedGroup: undefined };

  getWorkshops = (collection, hasSessions = false) => {
    const { data } = this.props;
    const { workshops = [], workshopSessions = [] } = data.data || {};
    const { workshopIdList } = this.props;
    const finalWorkshops = hasSessions ? workshopSessions : workshops;
    const filteredWorkshops = collection
      ? finalWorkshops.filter((w) => w.collection === collection)
      : finalWorkshops;
    if (workshopIdList && workshopIdList.length > 0) {
      // Custom order
      const workshopsById = hasSessions
        ? groupBy(filteredWorkshops, 'workshopId')
        : keyBy(filteredWorkshops, '_id');
      const res = flatten(workshopIdList.map((id) => workshopsById[id])).filter((v) => v);
      if (res.length > 6) {
        return orderWorkshop(res);
      }
      // Short list can be manually ordered...
      return res;
    }
    const orderedWorkshops = orderWorkshop(filteredWorkshops);
    return orderedWorkshops;
  };

  setFilters = (filterValue) => this.setState({ filterValue });

  renderFilters(workshops) {
    const { filterValue } = this.state;
    const { filters, filtersConfig } = this.props;
    const {
      align = 'top',
      showFilters = true,
      // chooseFiltersLabel = 'Choisissez vos filtres',
      // clearFiltersLabel = 'Effacer tous les filtres',
    } = filtersConfig;
    const isLeft = align === 'left';
    // const hasFilters = Object.keys(filterValue).length > 0;
    if (!showFilters) return null;

    return (
      <div className={cx('workshop--filters box', { 'vertical menu': isLeft })}>
        <MenuFilter
          filterList={generateFilters(workshops, filters)}
          filters={filterValue}
          onChange={this.setFilters}
        />
      </div>
    );
  }

  renderWorkshops = (workshops) => {
    const { workshopConfig, template, gridSize } = this.props;
    const Workshops = templates[template] || templates.List;
    return <Workshops workshops={workshops} workshopConfig={workshopConfig} gridSize={gridSize} />;
  };

  renderContent(workshops) {
    const { groupField, groupConfig, template } = this.props;
    const { selectedGroup } = this.state;

    if (groupField) {
      const groupedWorkshops = groupWorkshopsByField(workshops, groupField, groupConfig);
      const groups = Object.keys(groupedWorkshops);
      const firstGroup = groups[0];

      if (groupField === 'startDay') {
        if (template === 'table') {
          return (
            <Columns>
              {Object.keys(groupedWorkshops).map((group) => {
                const groupWorkshops = groupedWorkshops[group];
                return (
                  <Column key={group}>
                    <p className="title is-5">{group}</p>
                    {this.renderWorkshops(groupWorkshops)}
                  </Column>
                );
              })}
            </Columns>
          );
        }
        if (template === 'Accordion') {
          return (
            <>
              <Columns>
                {Object.keys(groupedWorkshops).map((group, index) => {
                  return (
                    <Column
                      key={group}
                      className={cx('Accordion__Group', {
                        'is-selected': selectedGroup === group || (!selectedGroup && index === 0),
                      })}
                      onClick={() => this.setState({ selectedGroup: group })}
                    >
                      <p className="title is-5">{group}</p>
                    </Column>
                  );
                })}
              </Columns>
              {this.renderWorkshops(groupedWorkshops[selectedGroup || firstGroup])}
            </>
          );
        }
      }

      return (
        <div style={{ flex: 1 }}>
          <ul className="timeline" style={{ width: '100%' }}>
            {Object.keys(groupedWorkshops).map((group) => {
              const groupWorkshops = groupedWorkshops[group];
              return (
                <li key={group} className="timeline-event">
                  <label className="timeline-event-icon" />
                  <div className="timeline-event-content">
                    <p className="title is-5 timeline-event-header">{group}</p>
                    {this.renderWorkshops(groupWorkshops)}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return <div style={{ flex: 1 }}>{this.renderWorkshops(workshops)}</div>;
  }

  render() {
    const { filters, filtersConfig, template, collection, hasSessions } = this.props;
    const { filterValue } = this.state;
    const { align } = filtersConfig;
    const isLeft = align === 'left';
    const workshops = this.getWorkshops(collection, hasSessions);
    const allFilters = pickBy(filterValue, (v) => !!v);
    const filteredData = applyFilters(workshops, allFilters, filters || []);

    return (
      <BaseSection {...this.props} block={this.props}>
        <div className={cx('workshops', template, { 'is-left': isLeft })}>
          {this.renderFilters(workshops)}
          {this.renderContent(filteredData)}
        </div>
      </BaseSection>
    );
  }
}

WorkshopsSection.defaultProps = {
  collection: undefined,
  filters: [],
  filtersConfig: { align: 'top', showFilters: true },
  gridSize: 3,
  groupConfig: {},
  groupField: undefined,
  hasSessions: false,
  template: 'List',
  workshopConfig: {},
  workshopIdList: undefined,
};

WorkshopsSection.propTypes = {
  collection: PropTypes.string,
  data: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
      ),
    }),
  ),
  filtersConfig: PropTypes.shape({
    align: PropTypes.oneOf(['left', 'top']),
    showFilters: PropTypes.bool,
  }),
  gridSize: PropTypes.number,
  groupField: PropTypes.string,
  groupConfig: PropTypes.object,
  hasSessions: PropTypes.bool,
  template: PropTypes.oneOf(['List', 'Masonry', 'Collapsible', 'Modern', 'Table', 'Accordion']),
  workshopConfig: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    description: PropTypes.string,
    showDetails: PropTypes.bool,
    showSpeakers: PropTypes.bool,
    showUserCount: PropTypes.bool,
    speakersTemplate: PropTypes.string,
  }),
  workshopIdList: PropTypes.arrayOf(PropTypes.string),
};

export default withAppContext(WorkshopsSection);
