/* eslint-disable jsx-a11y/label-has-associated-control */
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withAppContext } from '../Context';
import contactService from '../services/contact.service';
import { appDataProps } from '../utils/propTypes';
import { withSiteTranslation } from '../utils/translations';
import FormField from './FormField';

export const formFields = [
  {
    label: 'Nom complet',
    labelKey: 'site.contact.field.fullname',
    children: [
      {
        type: 'input',
        icon: 'user',
        fieldType: 'text',
        name: 'firstName',
        placeholder: 'Prénom',
        placeholderKey: 'site.contact.field.firstName',
        required: true,
      },
      {
        type: 'input',
        icon: 'user',
        fieldType: 'text',
        name: 'lastName',
        placeholder: 'Nom',
        placeholderKey: 'site.contact.field.lastName',
        required: true,
      },
    ],
  },
  {
    label: 'Email',
    labelKey: 'site.contact.field.email',
    children: [
      {
        type: 'input',
        icon: 'envelope',
        fieldType: 'email',
        name: 'email',
        placeholder: 'Email',
        placeholderKey: 'site.contact.field.email',
        required: true,
      },
    ],
  },
  {
    label: 'Sujet',
    labelKey: 'site.contact.field.subject',
    children: [
      {
        type: 'input',
        icon: 'edit',
        fieldType: 'text',
        name: 'subject',
        placeholder: 'Sujet',
        placeholderKey: 'site.contact.field.subject',
        required: true,
      },
    ],
  },
  {
    label: 'Message',
    labelKey: 'site.contact.field.message',
    children: [
      {
        type: 'textarea',
        name: 'message',
        placeholder: 'Message',
        placeholderKey: 'site.contact.field.message',
        required: true,
      },
    ],
  },
  { children: [{ type: 'button', name: 'Envoyer', nameKey: 'site.contact.field.send' }] },
];

class ContactForm extends PureComponent {
  constructor(props) {
    super(props);
    const fieldNames = flatten(
      props.fields.map((field) => field.children.map((f) => f.name).filter((v) => v)),
    );
    // TODO: FIX DATA
    const defaultData = pick(get(props.data, 'user'), fieldNames) || {};
    this.state = {
      validate: false,
      state: 'IDLE',
      data: defaultData,
      disabledFields: mapValues(defaultData, (v) => !!v),
    };
  }

  isValid = () => {
    const { data } = this.state;
    const { fields } = this.props;
    // Find error ?
    return !fields.find((line) => line.children.find((f) => f.required && !data[f.name]));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Check if everything is valid
    if (this.isValid()) {
      const { data } = this.state;
      this.setState({ state: 'SENDING' });
      try {
        const res = await contactService.postContact(data);
        this.setState({ state: res.success ? 'DONE' : 'ERROR' });
      } catch (error) {
        // Try to send without post...
        this.setState({ state: 'ERROR' });
      }
    } else {
      this.setState({ validate: true });
    }
  };

  handleChange = (name, value) => {
    const { data } = this.state;
    this.setState({
      data: { ...data, [name]: value },
    });
  };

  render() {
    const { fields, t } = this.props;
    const { validate, data, state, disabledFields } = this.state;
    if (state === 'DONE') {
      return <div style={{ textAlign: 'center' }}>{t('site.contact.label.validate')}</div>;
    }
    return (
      <form onSubmit={this.handleSubmit}>
        {fields.map((field, index) => {
          const { label, labelKey, children } = field;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{labelKey ? t(labelKey) : label}</label>
              </div>
              <div className="field-body">
                {children.map((child, idx) => (
                  <FormField
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    {...child}
                    value={data[child.name]}
                    validate={validate}
                    state={state}
                    disabled={child.disabled || disabledFields[child.name] || false}
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                    requiredLabel={t('site.contact.label.required')}
                  />
                ))}
              </div>
            </div>
          );
        })}
        {state === 'ERROR' && (
          <div className="field is-horizontal">
            <div className="field-label is-normal" />
            <div className="field-body">
              <div className="help is-danger">{t('site.contact.label.error')}</div>
            </div>
          </div>
        )}
      </form>
    );
  }
}

ContactForm.defaultProps = {
  fields: formFields,
  strings: {},
};

ContactForm.propTypes = {
  data: appDataProps.isRequired,
  fields: PropTypes.array,
  strings: PropTypes.object,
};

export default withAppContext(withSiteTranslation(ContactForm));
