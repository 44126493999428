/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

class Book extends React.PureComponent {
  render() {
    const { color, style, ...props } = this.props;
    return (
      <svg {...props} viewBox="0 0 1000 97" style={{ pointerEvents: 'none', ...style }}>
        <path
          d="M806-0.4C619.3-1.1,501,77.9,500,96.8C499,77.9,380.7-1.1,194-0.4C79.7-0.1,0-0.7,0-0.7S0,91,0,99.3l0,0l1000-0.7V-0.7 C1000-0.7,920.3-0.1,806-0.4z"
          fill={color}
        />
      </svg>
    );
  }
}

Book.defaultProps = {
  color: '#f7f7f7',
  style: undefined,
};

Book.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Book;
