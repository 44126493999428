import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import './text-image-shape-section.scss';

class TextImageShapeSection extends PureComponent {
  render() {
    const {
      text,
      shape,
      leftImage,
      rightImage,
      showRightImage,
      isFullWidth,
      components,
      ...props
    } = this.props;
    const { RichText, Image } = components;
    return (
      <BaseSection {...props} block={this.props} isFullWidth={isFullWidth}>
        <div className={cx('image-shape', `image-shape--${shape}`, 'left')}>
          <Image maxWidth={1200} src={leftImage} name="leftImage" />
        </div>
        {showRightImage && (
          <div className={cx('image-shape', `image-shape--${shape}`, 'right')}>
            <Image maxWidth={1200} src={rightImage} name="rightImage" />
          </div>
        )}
        <RichText html={text} name="text" placeholder="Text" className="shape--text" />
      </BaseSection>
    );
  }
}

TextImageShapeSection.defaultProps = {
  isFullWidth: false,
  leftImage: undefined,
  rightImage: undefined,
  shape: 'circle',
  showRightImage: true,
  text: undefined,
};

TextImageShapeSection.propTypes = {
  components: PropTypes.object.isRequired,
  isFullWidth: PropTypes.bool,
  leftImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  shape: PropTypes.oneOf(['circle', 'ellipse', 'diamond', 'diagonal']),
  showRightImage: PropTypes.bool,
  text: PropTypes.string,
};

export default withComponents(TextImageShapeSection);
