import React, { useContext, useState } from 'react';
import useWebShare from 'react-use-web-share';
import PropTypes from 'prop-types';
import './ShareButton.scss';
import supported from './SocialShareButtonsConfig';
import { DesignContext } from '../../Context';
import ShareModal from './ShareModal';

const texts = {
  fr: 'Partager',
  en: 'Share',
};

const ShareButton = ({
  title,
  description,
  url,
  socialMedias,
  iconClassName,
  language,
  invertColors,
}) => {
  const { primaryColor } = useContext(DesignContext);

  const { isSupported, loading, share } = useWebShare();
  const [showShareMenu, setShowShareMenu] = useState(false);
  const medias = socialMedias.filter(({ name }) => !!supported[name]);
  const color = !invertColors ? 'white' : primaryColor;

  const shareMe = () => {
    if (!isSupported || loading) {
      setShowShareMenu(true);
      return;
    }

    share({
      text: description,
      title,
      url,
    });
  };
  if (!socialMedias || !socialMedias.length) return null;
  const shareText = texts[language];
  return (
    <>
      <div role="button" onClick={shareMe} tabIndex={0} className="ShareButton--Trigger">
        <i
          className={`fas fa-share-alt fa-2x ${iconClassName}`}
          style={{ color }}
          aria-label={shareText}
        />
      </div>
      <ShareModal visible={showShareMenu} onClose={() => setShowShareMenu(false)}>
        <div className="ShareButton--Drawer">
          <div className="ShareButton--Drawer--Content">
            {medias.map((media, i) => {
              const Share = supported[media.name];
              return (
                <div key={`share-${media.name || i}`}>
                  <Share description={description} title={title} url={url} />
                </div>
              );
            })}
          </div>
        </div>
      </ShareModal>
    </>
  );
};

ShareButton.defaultProps = {
  title: null,
  description: null,
  language: 'fr',
  invertColors: false,
};
ShareButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string.isRequired,
  socialMedias: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  iconClassName: PropTypes.string.isRequired,
  language: PropTypes.string,
  invertColors: PropTypes.bool,
};

export default ShareButton;
