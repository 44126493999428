/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable max-len */
import React, { FC } from 'react';
import { useCookieContext } from './CookieBanner/CookieContext';
import { CookieCategories } from './CookieBanner/cookies.types';

// TODO: should be in a env config
export const KEY_API = 'AIzaSyBBQKAvuPLxgX7ERfHYMa2NRpP1WCGomiE';

const computeMapUrl = (type: 'img' | 'iframe' | 'url', address: string, mapHeight: number) => {
  const adr = encodeURIComponent(address);
  switch (type) {
    case 'iframe':
      return `https://www.google.com/maps/embed/v1/place?key=${KEY_API}&q=${adr}`;
    case 'url':
      return `https://www.google.com/maps/place/${adr}`;
    case 'img':
    default:
      return `https://maps.googleapis.com/maps/api/staticmap?key=${KEY_API}&center=${adr}&markers=${adr}&size=640x${mapHeight}&scale=2`;
  }
};

type MapProps = {
  mapType: 'static' | 'dynamic';
  address: string;
  height: number;
};

const Map: FC<MapProps> = (props) => {
  const { hasCookieBanner, categories = [] } = useCookieContext();
  const { address, height } = props;
  let { mapType = 'dynamic' } = props;

  // If cookies aren't validated,
  if (hasCookieBanner && !categories.includes(CookieCategories.GOOGLE_MAPS)) {
    mapType = 'static';
  }

  return (
    <div>
      {mapType === 'dynamic' && (
        <iframe
          title="google_maps"
          width="100%"
          height={height}
          src={computeMapUrl('iframe', address, height)}
        />
      )}
      {mapType === 'static' && (
        <a href={computeMapUrl('url', address, height)} target="_blank" rel="noreferrer noopener">
          <img src={computeMapUrl('img', address, height)} alt="map" width="100%" />
        </a>
      )}
    </div>
  );
};

Map.defaultProps = {
  address: 'Paris, France',
  height: 80,
  mapType: 'dynamic',
};

export default Map;
