import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { imageFormatPropType, imageFitPropType, imageBorderRadiusPropTypes } from './propTypes';
import { imageProps, loadingType } from '../utils/propTypes';
import Images from '../utils/Images';

function normalizeFit(fit) {
  switch (fit) {
    case 'contain-padding':
      return 'contain';
    default:
      return fit;
  }
}

const squareFormatSizes = {
  'is-16x16': 16,
  'is-24x24': 24,
  'is-32x32': 32,
  'is-48x48': 48,
  'is-64x64': 64,
  'is-96x96': 96,
  'is-128x128': 128,
};

function generateThumbnailUri(image, srcSet, format) {
  if (!image) return image;

  const uri = typeof image === 'string' ? image : image.uri;
  if (format in squareFormatSizes) {
    const size = squareFormatSizes[format];
    return Images.srcSet.square(uri, size, { type: 'auto' });
  }
  return { src: uri, srcSet };
}

const Figure = ({
  title,
  className,
  format,
  src,
  srcSet,
  colorOverlay,
  fit,
  alt,
  borderRadius,
  imgStyle,
  loading,
}) => {
  const imagePadding = fit === 'contain-padding' ? 16 : undefined;
  return (
    <figure
      className={cx(
        'image',
        format,
        {
          'image--color-overlay': colorOverlay,
        },
        className,
      )}
    >
      <img
        className={cx({
          'is-rounded': borderRadius === 'rounded',
          'is-rounded-corner': borderRadius === 'light',
        })}
        {...generateThumbnailUri(src, srcSet, format)}
        alt={title || alt}
        loading={loading}
        style={{ objectFit: normalizeFit(fit), padding: imagePadding, ...imgStyle }}
      />
    </figure>
  );
};
Figure.defaultProps = {
  alt: '',
  className: '',
  title: undefined,
  format: undefined,
  fit: undefined,
  borderRadius: undefined,
  colorOverlay: false,
  imgStyle: {},
  loading: undefined,
  srcSet: undefined,
};

Figure.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: imageProps.isRequired,
  srcSet: PropTypes.string,
  title: PropTypes.string,
  colorOverlay: PropTypes.bool,
  fit: imageFitPropType,
  format: imageFormatPropType,
  borderRadius: imageBorderRadiusPropTypes,
  imgStyle: PropTypes.object,
  loading: loadingType,
};

export default Figure;
