import PropTypes from 'prop-types';
import React from 'react';
import noop from 'lodash/noop';

const List = ({ className, Component, items, render, style }) => (
  <div className={className} style={style}>
    {items.map((item, index) => (
      <div key={item._id || index}>{Component ? <Component {...item} /> : render(item, index)}</div>
    ))}
  </div>
);

export default List;

List.defaultProps = {
  className: '',
  Component: undefined,
  items: [],
  render: noop,
  style: {},
};

List.propTypes = {
  className: PropTypes.string,
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  items: PropTypes.array,
  render: PropTypes.func,
  style: PropTypes.object,
};
