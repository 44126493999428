import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class Section extends PureComponent {
  render() {
    const { id, children, backgroundColor, style = {} } = this.props;
    return (
      <section id={id} className="section" style={{ backgroundColor, ...style }}>
        <div className="container">{children}</div>
      </section>
    );
  }
}

Section.defaultProps = {
  children: undefined,
  id: '',
  style: {},
};

Section.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  id: PropTypes.string,
  style: PropTypes.object,
};
