import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  // eslint-disable-next-line import/no-unresolved
} from 'react-share';
import PropTypes from 'prop-types';
import React from 'react';

const shareButtonDefaults = {
  description: '',
};
const shareButtonTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  description: PropTypes.string,
};

const LinkedInButton = ({ title, description, url }) => (
  <LinkedinShareButton title={title} summary={description} url={url}>
    <div className="ShareButton--ButtonContent">
      <LinkedinIcon size="2em" /> <span>LinkedIn</span>
    </div>
  </LinkedinShareButton>
);
LinkedInButton.defaultProps = shareButtonDefaults;
LinkedInButton.propTypes = shareButtonTypes;
const FacebookButton = ({ title, url }) => (
  <FacebookShareButton quote={title} url={url}>
    <div className="ShareButton--ButtonContent">
      <FacebookIcon size="2em" /> <span>Facebook</span>
    </div>
  </FacebookShareButton>
);
FacebookButton.defaultProps = shareButtonDefaults;
FacebookButton.propTypes = shareButtonTypes;
const TwitterButton = ({ title, url }) => (
  <TwitterShareButton title={title} url={url}>
    <div className="ShareButton--ButtonContent">
      <TwitterIcon size="2em" /> <span>Twitter</span>
    </div>
  </TwitterShareButton>
);
TwitterButton.defaultProps = shareButtonDefaults;
TwitterButton.propTypes = shareButtonTypes;

const SocialShareButtonsConfig = {
  facebook: FacebookButton,
  twitter: TwitterButton,
  linkedin: LinkedInButton,
};
export default SocialShareButtonsConfig;
