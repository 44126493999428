import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import trim from 'lodash/trim';
import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';
import { parse, stringify } from 'query-string';

const CDN_SANDBOX =
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/sandbox001';
const CDN_PROD =
  'https://storage.gra.cloud.ovh.net/v1/AUTH_1aa44ccb48c74c7f956a0ce09a3527ea/static001';

const CDN_REPLACE = {
  [`${CDN_SANDBOX}/events/`]: 'https://sandbox.cdn.appcraft.events/events/',
  [`${CDN_PROD}/events/`]: 'https://cdn.appcraft.events/events/',
};

/**
 * Simiar to path.join. Ensures middle slashes and prevents double slashes
 * @param {*} paths array of paths
 */
// eslint-disable-next-line import/prefer-default-export
export function urlJoin(paths) {
  if (!paths || paths.length === 0) return '';
  const validPaths = paths.filter((p) => p);
  return validPaths
    .map((p, index) => {
      if (index === 0) return trimEnd(p, '/ ');
      if (index === validPaths.length - 1) return trimStart(p, '/ ');
      return trim(p, '/ ');
    })
    .join('/');
}

export function urlJoinParams(url, params) {
  if (!params) return url;

  if (typeof params === 'object') {
    if (!Object.keys(params).length) return url;
    return urlJoinParams(url, stringify(params));
  }
  const join = url.includes('?') ? '&' : '?';
  return `${url}${join}${params}`;
}

export function extractUTM(queryString) {
  if (!queryString) return {};

  const params = parse(queryString);
  return pickBy(params, (_v, k) => startsWith(k, 'utm_'));
}

export const applyCDN = (str) => {
  if (!str || str.length === 0) return '';
  let replaced = str;
  Object.entries(CDN_REPLACE).forEach(([key, value]) => {
    replaced = replaced?.replace(key, value);
  });
  return replaced;
};
