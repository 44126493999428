/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { splitLines } from '../bulma/utils';

const TextOrHtml = ({ text }) => {
  if (!text) return null;

  const value = text.trim();

  if (value[0] === '<') {
    // Probably HTML...
    return <span dangerouslySetInnerHTML={{ __html: value }} />;
  }
  return <>{splitLines(value)}</>;
};

TextOrHtml.defaultProps = {
  text: undefined,
};

TextOrHtml.propTypes = {
  text: PropTypes.string,
};

export default TextOrHtml;
