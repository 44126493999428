import React, { FC, useState } from 'react';
import FormInput from '../../../bulma/FormInput';
import authService from '../../../services/auth.service';
import { MagicLinkConnectionMethod } from '../../../types/connection.types';
import { useSiteTranslation } from '../../../utils/translations';
import { errorMsg, isValidEmail } from '../login.utils';

type MagicLinkConnectionProps = {
  onError: (error: string | null) => void;
  config: MagicLinkConnectionMethod;
};

const MagicLinkConnection: FC<MagicLinkConnectionProps> = ({ onError, config }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>('');
  const { t } = useSiteTranslation();

  const handleSubmit = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    onError(null);
    setMessage(null);

    try {
      const res = await authService.sendMagicLink(email);
      console.log('res', res);
      if (res.error) {
        onError(errorMsg(res.error));
      } else if (res.success) {
        setMessage(t('site.login.email-link.success'));
      } else {
        console.error(res);
        onError(t('login.error-connection-error'));
      }
    } catch (err: any) {
      console.error(err);
      onError(errorMsg(err.error || 'error'));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  return (
    <>
      <form action="" onSubmit={handleSubmit}>
        <p>{config.strings?.description || t('site.login.email-link.description')}</p>
        <FormInput
          type="string"
          value={email}
          name="email"
          icon="fa-envelope"
          onChange={handleChange}
        />
        <div className="field">
          <button
            disabled={!isValidEmail(email)}
            className="button is-primary is-fullwidth"
            onClick={handleSubmit}
          >
            {t('site.login.email-link.send-link')}
          </button>
        </div>
      </form>
      {message && (
        <article className="message">
          <div className="message-body">{message}</div>
        </article>
      )}
    </>
  );
};

export default MagicLinkConnection;
