import PropTypes from 'prop-types';
import React from 'react';
import Figure from './Figure';
import './media.scss';
import { splitLines } from './utils';

import SocialNetworks from '../components/SocialNetworks';
import { imageProps as ImagePropTypes } from '../utils/propTypes';
import TextOrHtml from '../components/TextOrHtml';

class Media extends React.PureComponent {
  render() {
    const {
      imageLeft,
      imageProps,
      title,
      subtitle,
      description,
      socialNetworks,
      textStyle,
      link,
      linkLabel,
      ...props
    } = this.props;
    return (
      <div className="media" {...props}>
        {imageLeft && (
          <div className="media-left">
            <Figure src={imageLeft} {...imageProps} alt={title} loading="lazy" />
          </div>
        )}
        <div className="media-content" style={textStyle}>
          {title && <p className="title is-5">{splitLines(title)}</p>}
          {subtitle && <p className="subtitle is-6">{splitLines(subtitle)}</p>}
          <SocialNetworks socialNetworks={socialNetworks} />
          {description && (
            <p className="description">
              <TextOrHtml text={description} />
            </p>
          )}
          {link && (
            <p className="link">
              <a href={link} target="_blank" rel="noopener noreferrer">
                {linkLabel || link}
              </a>
            </p>
          )}
        </div>
      </div>
    );
  }
}

Media.defaultProps = {
  description: undefined,
  image: undefined,
  imageLeft: undefined,
  imageProps: undefined,
  link: undefined,
  linkLabel: undefined,
  socialNetworks: undefined,
  subtitle: undefined,
  textStyle: undefined,
  title: undefined,
};

Media.propTypes = {
  description: PropTypes.string,
  image: ImagePropTypes,
  imageLeft: ImagePropTypes,
  imageProps: ImagePropTypes,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  socialNetworks: PropTypes.arrayOf({
    _id: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  subtitle: PropTypes.string,
  textStyle: PropTypes.object,
  title: PropTypes.string,
};

export default Media;
