import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withComponents } from '../components/Context';
import BaseSection from './BaseSection';

class HeroImage extends PureComponent {
  render() {
    const { image, components, parallax, maxWidth, ...props } = this.props;
    const { FigureImage } = components;
    return (
      <BaseSection className="hero" {...props} block={this.props} hasHeader={false}>
        <div className="hero-body">
          <FigureImage src={image} parallax={parallax} maxWidth={maxWidth} />
        </div>
      </BaseSection>
    );
  }
}

HeroImage.defaultProps = {
  parallax: 'none',
  maxWidth: 1920,
};

HeroImage.propTypes = {
  components: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  parallax: PropTypes.oneOf(['', 'none', 'normal', 'fullpage']),
  maxWidth: PropTypes.number,
};

export default withComponents(HeroImage);
