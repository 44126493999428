function matchId(url, reg, fn) {
  const matches = url.match(reg);
  if (matches && matches.length > 1) {
    const id = matches[1];
    return fn(id);
  }
  return undefined;
}

function convertYoutubeUrl(url) {
  return (
    matchId(
      url,
      /.*youtube.*v=([a-zA-Z0-9_-]+).*/i,
      (id) => `https://www.youtube.com/embed/${id}?rel=0&autoplay=0&enablejsapi=0&wmode=opaque`,
    ) ||
    matchId(
      url,
      /.*youtu.be\/[^a-zA-Z0-9_-]*([a-zA-Z0-9_-]+).*/i,
      (id) => `https://www.youtube.com/embed/${id}?rel=0&autoplay=0&enablejsapi=0&wmode=opaque`,
    )
  );
}

function convertVimeoUrl(url) {
  if (url.match(/.*vimeo.*\/embed/) || url.match(/.*player.vimeo.com.*/)) {
    // Already an embed link, don't change anything
    return url;
  }
  return (
    matchId(
      url,
      // vimeo.com/event/id
      /.*vimeo.com\/event\/([^/]+)/i,
      (id) => `https://vimeo.com/event/${id}/embed`,
    ) ||
    matchId(
      url,
      // other vimeo urls
      /.*vimeo.*\/([a-zA-Z0-9_-]+).*/i,
      (id) => `https://player.vimeo.com/video/${id}`,
    )
  );
}

function convertDailyMotionUrl(url) {
  return matchId(
    url,
    /.*dailymotion.*\/video\/([a-zA-Z0-9]+)(_.*)?/i,
    (id) => `https://www.dailymotion.com/embed/video/${id}`,
  );
}

export function videoUrl(url) {
  return convertYoutubeUrl(url) || convertVimeoUrl(url) || convertDailyMotionUrl(url) || url;
}

export async function videoThumbnail(url) {
  return fetch(`https://app.appcraft.events/media/video/thumbnail?url=${url}`).then((res) =>
    res.json(),
  );
}
