import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import React from 'react';
import PropTypes from 'prop-types';
import Images from '../utils/Images';
import './FilesList.scss';
import FAIcon from './FAIcon';

function getFileExtension(filename) {
  if (!filename) return '';
  return filename.split('.').pop().toLowerCase();
}

const imageExtensions = keyBy(['pdf', 'png', 'gif', 'jpeg', 'jpg', 'bmp'], (v) => v);

const fileIconExtensionMap = {
  doc: 'file-word',
  docx: 'file-word',
  xls: 'file-excel',
  xlsx: 'file-excel',
  m4v: 'file-video',
  mp4: 'file-video',
  mkv: 'file-video',
  avi: 'file-video',
  mov: 'file-video',
};

const FileImage = ({ type, uri }) => {
  const ext = getFileExtension(uri);
  if (type === 'file' && imageExtensions[ext]) {
    return (
      <figure className="image is-3by4" style={{ margin: 0 }}>
        <img
          className="FilesList__FilePreview"
          src={Images.generatePdfThumbnail(uri, {
            width: 250,
            bgColor: null,
          })}
          alt=" "
        />
      </figure>
    );
  }

  // File/link icon
  return (
    <figure className="image is-3by4" style={{ margin: 0 }}>
      <div className="icon">
        <FAIcon icon={fileIconExtensionMap[ext] || 'link'} />
      </div>
    </figure>
  );
};

FileImage.propTypes = {
  uri: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['file', 'link']).isRequired,
};

const FileContent = ({ type, uri, name }) => {
  return (
    <article className="card FilesList__FileTile">
      <div className="card-image">
        <FileImage type={type} uri={uri} />
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">{name}</p>
          </div>
        </div>
      </div>
    </article>
  );
};

FileContent.propTypes = {
  uri: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['file', 'link']).isRequired,
};

const Files = ({ files }) => {
  return (
    <section className="content FilesList">
      {(files || []).map((file) => {
        const { _id, title, type: fileType } = file;
        const uri = get(file, 'value.uri');
        const originalFilename = get(file, 'value.originalFilename');
        if (!uri) return null;
        return (
          <a
            key={_id}
            href={uri}
            target="_blank"
            rel="noopener noreferrer"
            className="FilesList__FileLink"
          >
            <FileContent type={fileType} uri={uri} name={title || originalFilename} />
          </a>
        );
      })}
    </section>
  );
};

Files.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.shape({
        uri: PropTypes.string.isRequired,
        originalFilename: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default Files;
