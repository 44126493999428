import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import './item-list.scss';
import { withComponents } from './Context';

class RawItem extends PureComponent {
  render() {
    const { title, description, components } = this.props;
    const { RichText } = components;
    return (
      <div className="c-program-table__item">
        <div className="c-program-table__item__time">
          <RichText html={title} name="title" placeholder="Add time" />
        </div>
        <div className="c-program-table__item__description">
          <RichText html={description} name="description" placeholder="Add time" />
        </div>
      </div>
    );
  }
}

RawItem.defaultProps = {
  description: '',
  title: '',
};

RawItem.propTypes = {
  components: PropTypes.object.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
};

const Item = withComponents(RawItem);

class ProgramItemTable extends PureComponent {
  renderItem = (item, index) => {
    const { align } = this.props;
    return <Item key={item._id || index} {...item} align={align} />;
  };

  render() {
    const { items, components } = this.props;
    const { List } = components;
    return (
      <List
        className={cx('c-program-table is-clearfix')}
        name="items"
        items={items}
        render={this.renderItem}
      />
    );
  }
}

ProgramItemTable.defaultProps = {
  align: 'left',
  items: [],
};

ProgramItemTable.propTypes = {
  align: PropTypes.string,
  components: PropTypes.object.isRequired,
  items: PropTypes.array,
};

export default withComponents(ProgramItemTable);
